import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
// datepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
// switch
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class MarketingCampaignCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Campaign",
      parent: "/marketing/campaign",
      status: "process",
      isLoading: false,
      fields: {
        CampaignName: "",
        Description: "",
        StartDate: moment().format("YYYY-MM-DD"),
        EndDate: moment().add(1, "days").format("YYYY-MM-DD"),
        StoreIdList: [],
        EnableEmail: false,
        EmailSubject: "",
        EmailContent: "",
        EnableSms: false,
        SmsContent: "",
      },
      errors: {
        CampaignName: {
          error: "",
          isReq: "Campaign name is required!",
        },
        StoreIdList: {
          error: "",
          isReq: "Please select store",
        },
      },
      locationList: [],
      locationSelectList: [],
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getAllLocation();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "GET_CAMPAIGN_BY_ID",
      query: "/" + params.id,
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState(
            {
              status: "success",
              fields: {
                CampaignName: result.CampaignName,
                Description: result.Description != null ? result.Description : "",
                StartDate: moment(result.StartDate).format("YYYY-MM-DD"),
                EndDate: moment(result.EndDate).format("YYYY-MM-DD"),
                StoreIdList: result.StoreIdList,
                EnableEmail: result.EnableEmail,
                EmailSubject:
                  result.EmailSubject != null ? result.EmailSubject : "",
                EmailContent:
                  result.EmailContent != null ? result.EmailContent : "",
                EnableSms: result.EnableSms,
                SmsContent: result.SmsContent != null ? result.SmsContent : "",
              },
            },
            () => {
              this.getAllLocation();
            }
          );
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  getAllLocation() {
    const { fields } = this.state;
    var data = {
      url: "GET_ALL_LOCATION",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          response = response.data;
          let locationList = [];
          let locationSelectList = [];
          response.map((data, key) => {
            let obj = {
              label: data.LocationName,
              value: data.LocationId,
            };
            if (fields.StoreIdList.includes(data.LocationId)) {
              locationSelectList.push(obj);
            }
            locationList.push(obj);
          });

          this.setState({
            status: "success",
            locationList,
            locationSelectList,
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => {
        this.setState({
          status: "success",
        });
      }
    );
  }

  // Validation and Handler
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }
    this.setState({ fields: fields });
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    let storeIds = [];
    data.map((item, key) => {
      storeIds.push(item.value);
    });
    fields[name] = storeIds;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  }

  handleSwitchChange = (checked, name) => {
    const { fields } = this.state;
    fields[name] = checked;
    this.setState({ fields: fields });
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var data = {};
      if (params.type == "add") {
        data = {
          url: "CAMPAIGN_ADD",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      } else {
        fields.CampaignId = params.id;
        data = {
          url: "CAMPAIGN_UPDATE",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
    this.setState({
      isLoading: false,
    });
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type == "add" && common.isAccess("CAMPAIGNMANAGEMENT_ADD")) && (allow = true);
    (params.type == "edit" && common.isAccess("CAMPAIGNMANAGEMENT_EDIT")) && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      locationList,
      locationSelectList,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Configuration</h1>
              <ul>
                <li>
                  <a href="/marketing/campaign">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add Campaign" : "Update Campaign"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add Campaign" : "Update Campaign"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Campaign Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="CampaignName"
                          placeholder="Campaign Name"
                          value={fields.CampaignName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.CampaignName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.CampaignName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Start Date
                      </Form.Label>
                      <Col sm="4">
                        <DateRangePicker
                          initialSettings={{
                            timePicker: false,
                            singleDatePicker: true,
                            showDropdowns: true,
                            startDate: fields.StartDate,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onCallback={(start) => {
                            this.handleDateOnChange(start, "StartDate");
                          }}
                        >
                          <Form.Control
                            type="text"
                            name="StartDate"
                            placeholder="Start Date"
                            readOnly={true}
                          />
                        </DateRangePicker>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        End Date
                      </Form.Label>
                      <Col sm="4">
                        <DateRangePicker
                          initialSettings={{
                            timePicker: false,
                            singleDatePicker: true,
                            showDropdowns: true,
                            startDate: fields.EndDate,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onCallback={(start) => {
                            this.handleDateOnChange(start, "EndDate");
                          }}
                        >
                          <Form.Control
                            type="text"
                            name="EndDate"
                            placeholder="End Date"
                            readOnly={true}
                          />
                        </DateRangePicker>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Select Location
                      </Form.Label>
                      <Col sm="4">
                        {locationList.length > 0 ? (
                          <Select
                            isMulti
                            options={locationList}
                            defaultValue={locationSelectList}
                            onChange={(data) =>
                              this.handleSelectChange(data, "StoreIdList")
                            }
                          />
                        ) : (
                            <Form.Control
                              type="text"
                              placeholder="Loading location please wait..."
                              readOnly={true}
                            />
                          )}
                        {errors.StoreIdList.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.StoreIdList.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Enable Email
                      </Form.Label>
                      <Col sm="4">
                        <BootstrapSwitchButton
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          size="sm"
                          name="EnableEmail"
                          checked={fields.EnableEmail}
                          onChange={(e) =>
                            this.handleSwitchChange(e, "EnableEmail")
                          }
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Email Subject
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="EmailSubject"
                          placeholder="Email Subject"
                          value={fields.EmailSubject}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Email content
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="EmailContent"
                          placeholder="EmailContent"
                          value={fields.EmailContent}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Enable SMS
                      </Form.Label>
                      <Col sm="4">
                        <BootstrapSwitchButton
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          size="sm"
                          checked={fields.EnableSms}
                          onChange={(e) =>
                            this.handleSwitchChange(e, "EnableSms")
                          }
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        SMS content
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="SmsContent"
                          placeholder="SmsContent"
                          value={fields.SmsContent}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="1"></Col>
                      <Col sm="2"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading ? "Processing..." : params.type == "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { MarketingCampaignCrud };
