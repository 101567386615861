import React from 'react';
import { Route } from 'react-router-dom';
//Import Layouts
import { AdminLayout, LoginLayout } from '../_components/layouts';

//Admin Layout
export const AdminLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <AdminLayout>
                <Component {...matchProps} />
            </AdminLayout>
        )} />
    )
};

//Login Layout
export const LoginLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <LoginLayout>
                <Component {...matchProps} />
            </LoginLayout>
        )} />
    )
};