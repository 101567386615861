import React from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
// API Services
import { apiServices } from "_api";
// css
import "react-dates/lib/css/_datepicker.css";

export default class FilterVisit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFilter: "none",
      filter: {
        VisitedOnFrom: "",
        VisitedOnTo: "",
        CountryId: "",
        ProvinceId: "",
        ConceptId: "",
        LocationId: "",
      },
      filterData: {
        countryList: [],
        provinceList: [],
        conceptList: [],
        conceptPersistList: [],
        locationList: [],
      },
      dateInfo: {
        startDate: null,
        endDate: null,
      },
      // common
      isModal: false,
      focusedInput: null,
    };
  }

  componentDidMount() {
    this.defaultAPI();
  }

  defaultAPI() {
    this.getAllCountry();
    this.getAllConcept();
  }

  // ### filter functions start here ###
  getAllCountry = async () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_CONFIG_COUNTRY_GET_ALL",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          filterData.countryList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getAllProvince = () => {
    const { filter, filterData } = this.state;
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];
    if (filter.CountryId) {
      this.setState({
        filter,
        filterData,
        isFilter: "province",
      });
      var data = {
        url: "GET_ALL_PROVINCE",
        body: {
          CountryId: filter.CountryId,
        },
      };
      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data
            filterData.provinceList = response;
            this.setState({ filterData });
          }
        },
        (error) => { },
        (final) => {
          this.setState({ isFilter: "none" });
        }
      );
    } else {
      this.setState({ filter, filterData });
    }
  };

  getAllConcept = (type = "load") => {
    const { filter, filterData } = this.state;
    if (type == "filter") {
      if (filter.ProvinceId == "") {
        filter.ConceptId = "";
        filterData.conceptList = [];
        filterData.locationList = [];
      } else {
        filterData.conceptList = filterData.conceptPersistList;
      }
      this.setState({ filter, filterData });
    } else {
      var data = {
        url: "GET_ALL_CONCEPT",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data
            filterData.conceptPersistList = response;
            this.setState({ filterData });
          }
        },
        (error) => { },
        (final) => { }
      );
    }
  };

  getAllLocation = async () => {
    const { filter, filterData } = this.state;
    filterData.locationList = [];
    if (filter.ConceptId == "") {
      this.setState({ filterData });
    } else {
      this.setState({ filterData, isFilter: "location" });
      var data = {
        url: "GET_ALL_LOCATION",
        body: {
          // CountryId:filter.CountryId,
          ProvinceId: filter.ProvinceId,
          ConceptId: filter.ConceptId,
        },
      };

      await apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data
            filterData.locationList = response;
            this.setState({ filterData });
          }
        },
        (error) => { },
        (final) => {
          this.setState({
            isFilter: "none",
          });
        }
      );
    }
  };

  // filter select change
  selectChange = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        if (name == "CountryId") {
          this.getAllProvince();
        } else if (name == "ProvinceId") {
          this.getAllConcept("filter");
        } else if (name == "ConceptId") {
          this.getAllLocation();
        }
      }
    );
  };

  setCustomDate(startDate, endDate) {
    const { filter, dateInfo } = this.state;

    dateInfo.startDate = startDate;
    dateInfo.endDate = endDate;
    filter.VisitedOnFrom = moment(startDate).format("DD-MM-YYYY");
    filter.VisitedOnTo = moment(endDate).format("DD-MM-YYYY");

    this.setState({
      dateInfo,
      filter
    });
  }

  filterForm() {
    const { filter } = this.state;
    this.setState(
      {
        filter,
        isModal: false,
      },
      () => {
        this.props.filterSubmit()
      }
    );
  }

  resetForm() {
    const { filter, filterData, dateInfo } = this.state;

    filter.CountryId = "";
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filter.LocationId = "";
    filter.VisitedOnFrom = "";
    filter.VisitedOnTo = "";

    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];

    dateInfo.startDate = null;
    dateInfo.endDate = null;

    this.setState(
      {
        filter,
        filterData,
        dateInfo,
        isModal: false,
      },
      () => {
        this.props.filterReset();
      }
    );
  }

  filterModal(status) {
    this.setState({
      isModal: status,
    });
  }

  resetDate() {
    const { filter, dateInfo } = this.state;

    filter.VisitedOnFrom = "";
    filter.VisitedOnTo = "";

    dateInfo.startDate = null;
    dateInfo.endDate = null;

    this.setState({
      filter,
      dateInfo,
    });
  }

  render() {
    const { isFilter, isModal, focusedInput, filter, filterData, dateInfo } = this.state;
    return (
      <Modal show={isModal}>
        <Modal.Header>
          <Modal.Title>Filter List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form
              className="poModalFilter"
              onSubmit={(event) => event.preventDefault}
            >
              <div>
                <label className="poModalDateLabel">
                  <span>Start Date - End Date</span>
                  <span className="poc-link poc-cp" onClick={() => this.resetDate()}>Reset Date</span>
                </label>
                <div className="poModalDateInput">
                  <DateRangePicker
                    startDate={dateInfo.startDate}
                    startDateId="DateFrom"
                    endDate={dateInfo.endDate}
                    endDateId="DateTo"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setCustomDate(startDate, endDate)
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    }
                    isOutsideRange={() => false}
                  />
                </div>
              </div>

              <label className="poLabel">Country</label>
              <Form.Control
                as="select"
                name="CountryId"
                value={filter.CountryId}
                onChange={this.selectChange}
              >
                <option value="">Select Country</option>
                {filterData.countryList.map((item, key) => {
                  return (
                    <option key={key} value={item.CountryId}>
                      {item.CountryName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Province</label>
              <Form.Control
                as="select"
                name="ProvinceId"
                value={filter.ProvinceId}
                onChange={this.selectChange}
              >
                <option value="">
                  {isFilter == "province"
                    ? "Province Loading..."
                    : "Select Province"}
                </option>
                {filterData.provinceList.map((item, key) => {
                  return (
                    <option key={key} value={item.ProvinceId}>
                      {item.ProvinceName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Concept</label>
              <Form.Control
                as="select"
                name="ConceptId"
                value={filter.ConceptId}
                onChange={this.selectChange}
              >
                <option value="">Select Concept</option>
                {filterData.conceptList.map((item, key) => {
                  return (
                    <option key={key} value={item.ConceptId}>
                      {item.ConceptName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Location</label>
              <Form.Control
                as="select"
                name="LocationId"
                value={filter.LocationId}
                onChange={this.selectChange}
              >
                <option value="">
                  {isFilter == "location"
                    ? "Location Loading..."
                    : "Select Location"}
                </option>
                {filterData.locationList.map((item, key) => {
                  return (
                    <option key={key} value={item.ConceptId}>
                      {item.ConceptName}
                    </option>
                  );
                })}
              </Form.Control>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterForm()}
          >
            Filter
          </Button>
          <Button variant="primary config-btn" onClick={() => this.resetForm()}>
            Reset
          </Button>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
