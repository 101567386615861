import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class ReportScheduleCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Report Schedule",
      parent: "/report/schedule",
      status: "process",
      isLoading: false,
      fields: {
        ReportScheduleId: "",
        ReportTypeId: "",
        ScheduleTypeId: "",
        EmailAddressList: [""],
      },
      errors: [],
      sData: {
        reportTypeList: [],
        scheduleTypeList: [],
      },
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getAllReportType();
      this.getAllScheduleType();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_GET_BY_ID",
      query: "/" + params.id,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState(
            {
              fields: {
                ReportTypeId: result.ReportTypeId,
                ScheduleTypeId: result.ScheduleTypeId,
                EmailAddressList: result.EmailAddressList,
              },
            },
            () => {
              this.getAllReportType();
              this.getAllScheduleType();
            }
          );
        } else {
          this.setState({
            status: "error",
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => { }
    );
  };

  // validate
  validateCustom() {
    const { fields } = this.state;
    let errors = [];
    let emailFilled = false;
    if (fields.ReportTypeId == "") {
      errors.push("Please select Report Type");
    } else if (fields.ScheduleTypeId == "") {
      errors.push("Please select Schedule Type");
    } else {
      fields.EmailAddressList.map((item, key) => {
        if (item != "") {
          if (!tools.ValidateEmail(item)) {
            errors.push(item + " is not a valid email address");
          } else {
            emailFilled = true;
          }
        }
      });
      if (!emailFilled) {
        errors.push("Please fill atleast one email address");
      }
    }
    if (errors.length > 0) {
      this.setState({
        errors,
      });
      return false;
    } else {
      return true;
    }
  }

  // handler
  selectChange = (event) => {
    const { name, value } = event.target;
    const { fields } = this.state;
    fields[name] = parseInt(value);
    this.setState(
      {
        fields,
        errors: [],
      },
      () => { }
    );
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateCustom();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      fields.EmailAddressList = fields.EmailAddressList.filter(function (el) {
        return el != "";
      });
      var data = {};
      if (params.type == "add") {
        data = {
          url: "REPORT_SCHEDULE_PERSIST",
          query: "?IsAdd=true",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      } else {
        fields.ReportScheduleId = params.id;
        data = {
          url: "REPORT_SCHEDULE_PERSIST",
          query: "?IsAdd=false",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
    this.setState({
      isLoading: false,
    });
  }

  // ### Support data ###
  getAllReportType = () => {
    const { sData } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_REPORT_TYPE_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          sData.reportTypeList = response;
          this.setState({ sData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getAllScheduleType = () => {
    const { sData } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_SCHEDULE_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          sData.scheduleTypeList = response;
          this.setState({
            status: "success",
            sData,
          });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // Others
  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // add and remove
  addItem() {
    const { fields } = this.state;
    fields.EmailAddressList.push("");
    this.setState({
      fields,
    });
  }

  removeItem() {
    const { fields } = this.state;
    let count = fields.EmailAddressList.length - 1;
    let EmailAddressList = [];
    fields.EmailAddressList.map((item, key) => {
      if (key < count) {
        EmailAddressList.push(item);
      }
    });

    fields.EmailAddressList = EmailAddressList;

    this.setState({
      fields,
    });
  }

  handleEmailChange(e, key) {
    const { fields } = this.state;
    const { value } = e.target;
    fields.EmailAddressList[key] = value;
    this.setState({
      fields,
      errors: [],
    });
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type == "add" && common.isAccess("REPORTSCHEDULING_ADD")) && (allow = true);
    (params.type == "edit" && common.isAccess("REPORTSCHEDULING_EDIT")) && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      sData,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Report Schedule</h1>
              <ul>
                <li>
                  <a href="/report/schedule">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add"
                      ? "Add Report Schedule"
                      : "Update Report Schedule"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add"
                    ? "Add Report Schedule"
                    : "Update Report Schedule"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    {formSubmit && errors.length > 0 && (
                      <Form.Group as={Row} className="mb-3">
                        <Col sm="3"></Col>
                        <Col sm="4">
                          <div className="alert alert-danger">
                            <strong>Error!</strong> {errors[0]}
                          </div>
                        </Col>
                        <Col sm="5"></Col>
                      </Form.Group>
                    )}

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Report Type
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          name="ReportTypeId"
                          value={fields.ReportTypeId}
                          onChange={this.selectChange}
                        >
                          <option value="">Select Type</option>
                          {sData.reportTypeList.map((item, key) => {
                            return (
                              <option key={key} value={item.Id}>
                                {item.Value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Schedule Type
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          name="ScheduleTypeId"
                          value={fields.ScheduleTypeId}
                          onChange={this.selectChange}
                        >
                          <option value="">Select Type</option>
                          {sData.scheduleTypeList.map((item, key) => {
                            return (
                              <option key={key} value={item.Id}>
                                {item.Value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Email Address<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        {fields.EmailAddressList.map((cItem, cKey) => {
                          return (
                            <Form.Control
                              key={cKey}
                              type="text"
                              name="emailAddress"
                              placeholder="Email Address"
                              value={cItem}
                              className="poc-mb-10"
                              onChange={(e) => this.handleEmailChange(e, cKey)}
                            />
                          );
                        })}
                        <div>
                          <Form.Label
                            className="poAddLabel"
                            onClick={() => this.addItem()}
                          >
                            Add New
                          </Form.Label>
                          {fields.EmailAddressList.length > 1 && (
                            <>
                              {" - "}
                              <Form.Label
                                className="poRemoveLabel"
                                onClick={() => this.removeItem()}
                              >
                                Remove
                              </Form.Label>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading
                            ? "Processing..."
                            : params.type == "add"
                              ? "Save"
                              : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { ReportScheduleCrud };
