import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import styles from "../css/Action.module.css";
// _includes
import { common, history } from "_helpers";
import { apiServices } from "_api";

class ActionRuleCrud extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Rules",
      parent: "/actionalert",
      status: "process",
      step: 1,
      fields: {
        RuleName: "",
        Description: "",
        Value: "",
        ConditionIdList: [],
        TaskIdList: [],
      },
      // data
      conditionList: [],
      taskList: [],

      // loaders
      isLoading: false,
      flying: {
        condition: true,
        action: true,
        editGet: false,
      },
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push("actionalert");
      } else {
        this.loadData();
      }
    } else {
      this.loadIntialData();
    }
  }

  loadIntialData() {
    this.getConditions();
    this.getTasks();
  }

  loadData = () => {
    const { params } = this.state;
    this.setFlying("editGet",true);
    var data = {
      url: "GET_ACTION_BY_ID",
      query: "/" + params.id,
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          let result = response.data;
          this.setState(
            {
              fields: {
                RuleName: result.RuleName,
                Description: result.Description,
                Value: result.Value,
                ConditionIdList: result.ConditionIdList,
                TaskIdList: result.TaskIdList,
              },
            },
            () => {
              this.loadIntialData();
            }
          );
        }
      },
      (error) => {},
      (final) => {
        this.setFlying("editGet",false);
      }
    );
  };

  getConditions() {
    var data = {
      url: "GET_CONDITION_LIST",
    };
    this.setFlying("condition",true);
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            conditionList: response,
          });
        }
      },
      (error) => {},
      (final) => {
        this.setFlying("condition",false);
      }
    );
  }

  getTasks() {
    var data = {
      url: "GET_TASK_LIST",
    };
    this.setFlying("action",true);
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            taskList: response,
          });
        }
      },
      (error) => {},
      (final) => {
        this.setFlying("action",false);
      }
    );
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    if (fields.hasOwnProperty(name)) {
      if (type == "checkbox") {
        if (checked) {
          fields[name].push(Number(value));
        } else {
          fields[name] = common.removeItem(fields[name], Number(value));
        }
      } else {
        fields[name] = value;
      }
    }

    this.setState({
      fields: fields,
    });
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({ isLoading: true });
    const { params, fields } = this.state;
    var data = {};
    if (params.type == "add") {
      data = {
        url: "CREATE_ACTION_ALERT",
        body: fields,
        contentType: 1,
        method: "POST",
      };
    } else {
      fields.ActionAlertId = params.id;
      data = {
        url: "UPDATE_ACTION_ALERT",
        body: fields,
        contentType: 1,
        method: "POST",
      };
    }

    apiServices.call(
      data,
      (response) => {
        if (this.crudStatus(response.status)) {
          this.crudSuccess();
        } else {
          this.crudError();
        }
      },
      (error) => {
        this.crudError();
      },
      (final) => {
        this.setState({ isLoading: false });
      }
    );
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
    this.setState({
      isLoading: false,
    });
  }

  // Others
  setFlying(field,status){
    const {flying} = this.state;
    flying[field] = status;
    this.setState({
      flying
    })
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  steps(type) {
    const { step, fields } = this.state;
    let allow = true;
    if (type == "next") {
      if (step === 1) {
        if (fields.ConditionIdList.length === 0) {
          common.snack("E", "Select Conditions");
          allow = false;
        }
      } else if (step === 2) {
        if (fields.TaskIdList.length === 0) {
          common.snack("E", "Select Tasks");
          allow = false;
        }
      }

      if (allow) {
        this.setState({
          step: step + 1,
        });
      }
    } else if (type == "prev") {
      this.setState({
        step: step - 1,
      });
    } else {
      if (fields.RuleName === "") {
        common.snack("E", "Please enter rule name");
        allow = false;
      } else if (fields.Description === "") {
        common.snack("E", "Please enter description");
        allow = false;
      } else if (fields.Value === "") {
        common.snack("E", "Please enter rule value");
        allow = false;
      }
      if (allow) {
        this.onSubmit();
      }
    }
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type=="add" && common.isAccess("ACTIONANDALERT_ADD")) && (allow=true);
    (params.type=="edit" && common.isAccess("ACTIONANDALERT_EDIT")) && (allow=true);
    if(!allow){
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      step,
      fields,
      conditionList,
      taskList,
      isLoading,
      flying
    } = this.state;
    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-12 poFlex">
              <h1>Action & Alerts</h1>
              <ul>
                <li>
                  <a href="/actionalert">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Action & Alerts
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    <span className="potableHeadTitle"> {params.type=="add" ? "Add New Rule" : "Edit Rule"}</span>
                  </div>
                </div>
                <div className={`${styles.stepCount}`}>
                  <ul>
                    <li
                      className={`${step >= 1 && styles.activeStepCount} ${
                        styles.commonStepCount
                      }`}
                    >
                      <h4>1</h4>
                      <p>Which condition(s) do you want to check?</p>
                    </li>
                    <li
                      className={`${
                        step >= 2
                          ? styles.activeStepCount
                          : styles.deactiveStepCount
                      } ${styles.commonStepCount}`}
                    >
                      <h4>2</h4>
                      <p>What do you want to do with the message?</p>
                    </li>
                    <li
                      className={`${
                        step >= 3
                          ? styles.activeStepCount
                          : styles.deactiveStepCount
                      } ${styles.commonStepCount}`}
                    >
                      <h4>3</h4>
                      <p>Finish rule setup</p>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.stepLayout} col-sm-12`}>
                  {step === 1 && (
                    <Form>
                      <div className={`${styles.stepBox}`}>
                        <div className={`${styles.stepBoxText}`}>
                          <h2>
                            Step 1: <span>Select Condition(s)</span>
                          </h2>
                          <div
                            className={`${styles.stepBoxOption} ${styles.scrollSet}`}
                          >
                            <p>{flying.condition ? "Loading Conditions..." : conditionList.length==0 && "No data found"}</p>
                            {conditionList.map((item, key) => {
                              return (
                                <Form.Check
                                  key={key}
                                  inline
                                  name="ConditionIdList"
                                  label={item.Value}
                                  value={item.Id}
                                  className={`${styles.stepBoxCheck}`}
                                  checked={fields.ConditionIdList.includes(
                                    item.Id
                                  )}
                                  onChange={(e) => this.handleOnChange(e)}
                                  className={`${styles.stepBoxCheck}`}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}

                  {step === 2 && (
                    <Form>
                      <div className={`${styles.stepBox}`}>
                        <div className={`${styles.stepBoxText}`}>
                          <h2>
                            Step 2: <span>Select Actions(s)</span>
                          </h2>
                          <div
                            className={`${styles.stepBoxOption} ${styles.scrollSet}`}
                          >
                            <p>{flying.action ? "Loading Conditions..." : taskList.length==0 && "No data found"}</p>
                            {taskList.map((item, key) => {
                              return (
                                <Form.Check
                                  key={key}
                                  inline
                                  name="TaskIdList"
                                  label={item.Value}
                                  value={item.Id}
                                  className={`${styles.stepBoxCheck}`}
                                  checked={fields.TaskIdList.includes(item.Id)}
                                  onChange={(e) => this.handleOnChange(e)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}

                  {step === 3 && (
                    <Form>
                      <div className={`${styles.stepBox}`}>
                        <div className={`${styles.stepBoxText}`}>
                          <h2>
                            Step 1: <span>Specify a name for this rule</span>
                          </h2>
                          <Form.Control
                            type="text"
                            name="RuleName"
                            value={fields.RuleName}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                        </div>
                      </div>
                      <div className={`${styles.stepBox}`}>
                        <div className={`${styles.stepBoxText}`}>
                          <h2>
                            Step 2:{" "}
                            <span>Specify a description for this rule</span>
                          </h2>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name="Description"
                            placeholder="Enter description"
                            value={fields.Description}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                        </div>
                      </div>
                      <div className={`${styles.stepBox}`}>
                        <div className={`${styles.stepBoxText}`}>
                          <h2>
                            Step 3: <span>Enter the rule value</span>
                          </h2>
                          <Form.Control
                            type="text"
                            name="Value"
                            value={fields.Value}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                  <div className={styles.stepButton}>
                    <Button
                      variant="primary config-btn"
                      onClick={() => this.redirect()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary config-btn"
                      disabled={step === 1}
                      onClick={() => this.steps("prev")}
                    >
                      {"<"} Previous
                    </Button>
                    {step >= 1 && step < 3 && (
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.steps("next")}
                      >
                        Next {">"}
                      </Button>
                    )}
                    {step === 3 && (
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.steps("finish")}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Finish"}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ActionRuleCrud };
