import React from 'react';
import { InputGroup, FormControl, Row, Col, Dropdown } from 'react-bootstrap';
//Import CSS
import styles from './Header.module.css';
//Import Icons
import Logo from 'asset/img/logo.png';
import SearchIcon from 'asset/img/search_icon.png';
import NotificationIcon from 'asset/img/notification.png';
import UserIcon from 'asset/img/user_icon.png';

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            userName: ''
        }
    }
    componentDidMount() {
        const userName = localStorage.getItem('userName');
        this.setState({ userName: userName });
    }

    render() {
        const { userName } = this.state;
        return(
            <div className={styles.header}>
                <Row>
                    <Col md={4}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="search">
                                    <img src={SearchIcon} alt="Search Icon" className={styles.searchIcon} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="search"
                            />
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <img src={Logo} className={styles.logoImg}/>
                    </Col>
                    <Col md={4}>
                        <ul>
                            <li>
                                <img src={NotificationIcon} />
                            </li>
                            <li>
                                <Dropdown className={styles.dropDown}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {userName} <img src={UserIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
                                        <Dropdown.Item href="/">Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </Col>
                </Row>                
            </div>
        )
    }
}
export { Header };