import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
// switch
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";

class AdminGroupPermission extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      status: "process",
      isLoading: false,
      isFilter: false,
      isUpdating: false,
      columns: 6,
      paging: {
        pageSize: 100000,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      userGroupInfo: ""
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.loadData()
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_USER_GROUP_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            userGroupInfo: result
          }, () => {
            this.getPermissionList();
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  getPermissionList = () => {
    const { paging, params } = this.state;
    var data = {
      url: "ADMIN_USER_GROUP_PERMISSION_GET",
      query: "/" + params.id
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.data.length,
              result: response.data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => {
        this.setState({ isUpdating: false })
      }
    );
  };

  updatePermission = (checked, key, item) => {
    const { data, categoryId } = this.state;
    data.result[key].PermissionStatus = checked;
    item.PermissionStatus = checked;

    this.setState({
      data,
      isUpdating: true,
    });

    var aData = {
      url: "ADMIN_USER_GROUP_PERMISSION_SET",
      body: item,
      method: "POST",
    };

    apiServices.call(
      aData,
      (response) => {
        if (this.crudStatus(response.status)) {
          common.snack("S", "Permission has been updated");
        } else {
          common.snack("E", "There was an error while update permission");
          this.setState({ isUpdating: false })
        }
      },
      (error) => {
        common.snack("E", "There was an error while update permission");
        this.setState({ isUpdating: false })
      },
      (final) => {
        this.getPermissionList();
      }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  // module & page access
  crudAccess() {
    let allow = false;
    common.isAccess("USERGROUP_ASSIGNMODULEPERMISSION") && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      status,
      columns,
      data,
      paging,
      isLoading,
      isUpdating,
      userGroupInfo,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Group Permission</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Permission
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className={`${isUpdating && "poc-none"} poTable`}>
                <div>
                  <div className="d-flex">
                    <div className="mr-auto p-2">
                      {userGroupInfo != "" ? (
                        <span className="potableHeadTitle"> Set permission for <span className="poc-color">{userGroupInfo.UserGroupName}</span> -  </span>
                      ) :
                        <span className="potableHeadTitle"> Permission List - </span>
                      }
                      <Link to="/admin/user/group">
                        <Button variant="primary config-btn">Back to group</Button>
                      </Link>
                    </div>
                    <div className="p-2">
                      <div className="d-flex">
                        <div className="poTableIcon">
                          <img
                            src={common.appIcon("RefreshIcon")}
                            className="poc-cp"
                            alt="Refresh"
                            onClick={() => this.handleRefresh()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {isLoading && (
                    <div className="d-flex justify-content-center">
                      <span className="poTableFetcher">fetching data please wait...</span>
                    </div>
                  )}

                  <div className={isLoading ? "poTableOnLoad" : ""}>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Module</th>
                          <th>Module Code</th>
                          <th>Permission Name</th>
                          <th>Permission Code</th>
                          <th>Permission Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {status == "process" ? (
                          <tr className="poTableMsg">
                            <td colSpan={columns}>Loading data please wait...</td>
                          </tr>
                        ) : status == "error" ? (
                          <tr className="poTableMsg">
                            <td colSpan={columns}> There was an error while fetching record. </td>
                          </tr>
                        ) : data.RecordsCount == 0 ? (
                          <tr className="poTableMsg">
                            <td colSpan={columns}>Sorry no record found.</td>
                          </tr>
                        ) : data.result.map((item, key) => {
                          let id = key + 1
                          return (
                            <tr key={id}>
                              <td>{id}</td>
                              <td>{item.Module}</td>
                              <td>{item.ModuleCode}</td>
                              <td>{item.Permission}</td>
                              <td>{item.PermissionCode}</td>
                              <td>
                                <BootstrapSwitchButton
                                  onstyle="outline-success"
                                  offstyle="outline-danger"
                                  onlabel="On"
                                  offlabel="Off"
                                  size="sm"
                                  name={"switch" + id}
                                  checked={item.PermissionStatus}
                                  onChange={(e) =>
                                    this.updatePermission(e, key, item)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {data.RecordsCount > 0 && (
                      <div className="d-flex">
                        <div className="mr-auto p-2">
                          Total Records: {data.RecordsCount}
                        </div>
                        <div className="p-2">
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={paging.pageIndex}
                            itemsCountPerPage={paging.pageSize}
                            totalItemsCount={data.RecordsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AdminGroupPermission }