import React from "react";
import { Row, Col } from "react-bootstrap";
import { Line, Bar } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";
// _includes
import { history, common } from "_helpers";
import Filter from "../includes/Filter";
// API Services
import { apiServices } from "_api";
// css
import styles from "../css/Analytics.module.css";
import "react-circular-progressbar/dist/styles.css";
// Constant
let chartColors = [
  "#672AEB",
  "#9E52F6",
  "#2D008E",
  "#004EFF",
  "#0094FF",
  "#bda3f6",
  "#b9acd1",
  "#B385F5",
  "#D8B9FB",
];

class AnalyticsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDate: {
        Date: 0,
        DefaultDate: 0,
        NumberOfDays: 0,
        DefaultDays: 0,
      },
      // data
      overviewData: {
        regConnected: [],
        regNotConnected: [],
        visualMechand: [],
        uniqueReturn: [],
        newRegister: [],
        ananymous: [],
        passbyInsideTraffic: [],
        passingTraffic: [],
        bounceCustomer: [],
        averageTimeSpent: [],
        recencyFrequency: [],
      },
    };
  }

  componentDidMount() {
    this.setState({
      filterDate: this.filter.state.filterDate,
    });
  }

  overviewAPI() {
    this.regConnected();
    this.regNotConnected();
    this.visualMechand();
    this.uniqueReturn();
    this.newRegister();
    this.ananymous();
    this.passbyInsideTraffic();
    this.passingTraffic();
    this.bounceCustomer();
    this.averageTimeSpent();
    this.recencyFrequency();
  }

  // ### Analytics functions start here ###
  regConnected = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_REGISTERED_CONNECTED_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.regConnected = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  regNotConnected = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_REGISTERED_NOTCONNECTED_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.regNotConnected = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  newRegister = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_NEW_REGISTRATIONS_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.newRegister = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  ananymous = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_ANONYMOUS_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.ananymous = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  visualMechand = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_VISUAL_MERCHANDISING_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.visualMechand = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  uniqueReturn = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_UNIQUE_RETURNING_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.uniqueReturn = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  passbyInsideTraffic = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_PASSBY_TRAFFIC_VS_INSIDE_TRAFFIC_DATA",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let result = [[], [], []];
          response.map((item) => {
            result[0].push(item.Label);
            result[1].push(item.PassbyTraffic);
            result[2].push(item.InsideTraffic);
          });
          overviewData.passbyInsideTraffic = result;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  passingTraffic = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_PASSING_TRAFFIC_DATA",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let result = [[], [], []];
          response.map((item) => {
            result[0].push(item.Label);
            result[1].push(item.Registered);
            result[2].push(item.Anonymous);
          });
          overviewData.passingTraffic = result;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  bounceCustomer = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_BOUNCE_CUSTOMERS_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.bounceCustomer = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  averageTimeSpent = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_AVERAGE_TIMESPENT_INFO",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          overviewData.averageTimeSpent = response;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  recencyFrequency = () => {
    const { overviewData } = this.state;
    const { filter, filterDate } = this.filter.state;

    var data = {
      url: "AO_GET_RECENCY_FREQUENCY_DATA",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let result = [[], [], []];
          response.map((item) => {
            result[0].push(item.Label);
            result[1].push(item.Recency);
            result[2].push(item.Frequency);
          });
          overviewData.recencyFrequency = result;
          this.setState({ overviewData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  customerFilter(typeId, legend = "") {
    let data = {
      typeId: typeId,
      legend: legend,
    };
    this.props.customerFilter(data);
  }

  redirect(page) {
    history.push("/analytics/" + page);
  }

  // chat js click
  chartEvent(e, type) {
    if (e.length > 0) {
      const { overviewData } = this.state;
      let dataIndex = e[0]._index;
      let dataSetIndex = e[0]._datasetIndex;
      if (type == "passbyInside") {
        let legend = overviewData.passbyInsideTraffic[0][dataIndex];
        if (dataSetIndex == 0) {
          this.customerFilter(8, legend);
        } else {
          this.customerFilter(9, legend);
        }
      } else if (type == "passing") {
        let legend = overviewData.passingTraffic[0][dataIndex];
        if (dataSetIndex == 0) {
          this.customerFilter(10, legend);
        } else {
          this.customerFilter(11, legend);
        }
      }
    }
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.overviewAPI();
  }

  filterReset() {
    this.overviewAPI();
  }

  render() {
    const { filterDate, overviewData } = this.state;
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div
                  className={`${styles.aoPageBody} ${styles.aoSmallBox} poc-mb-20`}
                >
                  <div className={`${styles.abHead}`}>
                    <span>Registered Connected</span>
                  </div>
                  <div className={`${styles.absContent}`}>
                    <Row
                      className="poc-cp"
                      onClick={() => this.customerFilter(1)}
                    >
                      <Col xs={4}>
                        <img src={common.appIcon("registered_connected")} />
                      </Col>
                      <Col xs={8}>
                        <h1>{overviewData.regConnected.Count}</h1>
                        <p>{overviewData.regConnected.Total} TOTAL</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${styles.absBottom}`}>
                    <Row>
                      <Col xs={12}>
                        <h5
                          className={`${
                            overviewData.regConnected.ComparisonPercentage > 0
                              ? styles.abIconUp
                              : styles.abIconDown
                          }`}
                        >
                          {overviewData.regConnected.ComparisonPercentage >
                          0 ? (
                            <img src={common.appIcon("triangelUp")} />
                          ) : (
                            <img src={common.appIcon("triangelDown")} />
                          )}
                          {overviewData.regConnected.ComparisonPercentage}%
                          <span>
                            {" "}
                            Compared to previous{" "}
                            <span className="poc-color">
                              {filterDate.NumberOfDays}
                            </span>{" "}
                            days
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div
                  className={`${styles.aoPageBody} ${styles.aoSmallBox} poc-mb-20`}
                >
                  <div className={`${styles.abHead}`}>
                    <span>Registered Not Connected</span>
                  </div>
                  <div className={`${styles.absContent}`}>
                    <Row
                      className="poc-cp"
                      onClick={() => this.customerFilter(2)}
                    >
                      <Col xs={4}>
                        <img src={common.appIcon("registered_notconnected")} />
                      </Col>
                      <Col xs={8}>
                        <h1>{overviewData.regNotConnected.Count}</h1>
                        <p>{overviewData.regNotConnected.Total} TOTAL</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${styles.absBottom}`}>
                    <Row>
                      <Col xs={12}>
                        <h5
                          className={`${
                            overviewData.regNotConnected.ComparisonPercentage >
                            0
                              ? styles.abIconUp
                              : styles.abIconDown
                          }`}
                        >
                          {overviewData.regNotConnected.ComparisonPercentage >
                          0 ? (
                            <img src={common.appIcon("triangelUp")} />
                          ) : (
                            <img src={common.appIcon("triangelDown")} />
                          )}
                          {overviewData.regNotConnected.ComparisonPercentage}%
                          <span>
                            {" "}
                            Compared to previous{" "}
                            <span className="poc-color">
                              {filterDate.NumberOfDays}
                            </span>{" "}
                            days
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div
                  className={`${styles.aoPageBody} ${styles.aoSmallBox} poc-mb-20`}
                >
                  <div className={`${styles.abHead}`}>
                    <span>New Registration</span>
                  </div>
                  <div className={`${styles.absContent}`}>
                    <Row
                      className="poc-cp"
                      onClick={() => this.customerFilter(6)}
                    >
                      <Col xs={4}>
                        <img src={common.appIcon("new_registration")} />
                      </Col>
                      <Col xs={8}>
                        <h1>{overviewData.newRegister.Count}</h1>
                        <p>{overviewData.newRegister.Total} TOTAL</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${styles.absBottom}`}>
                    <Row>
                      <Col xs={12}>
                        <h5
                          className={`${
                            overviewData.newRegister.ComparisonPercentage > 0
                              ? styles.abIconUp
                              : styles.abIconDown
                          }`}
                        >
                          {overviewData.newRegister.ComparisonPercentage > 0 ? (
                            <img src={common.appIcon("triangelUp")} />
                          ) : (
                            <img src={common.appIcon("triangelDown")} />
                          )}
                          {overviewData.newRegister.ComparisonPercentage}%
                          <span>
                            {" "}
                            Compared to previous{" "}
                            <span className="poc-color">
                              {filterDate.NumberOfDays}
                            </span>{" "}
                            days
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div
                  className={`${styles.aoPageBody} ${styles.aoSmallBox} poc-mb-20`}
                >
                  <div className={`${styles.abHead}`}>
                    <span>Ananymous</span>
                  </div>
                  <div className={`${styles.absContent}`}>
                    <Row
                      className="poc-cp"
                      onClick={() => this.customerFilter(7)}
                    >
                      <Col xs={5}>
                        <img src={common.appIcon("anonymous")} />
                      </Col>
                      <Col xs={7}>
                        <h1>{overviewData.ananymous.Count}</h1>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${styles.absBottom}`}>
                    <Row>
                      <Col xs={12}>
                        <h5
                          className={`${
                            overviewData.ananymous.ComparisonPercentage > 0
                              ? styles.abIconUp
                              : styles.abIconDown
                          }`}
                        >
                          {overviewData.ananymous.ComparisonPercentage > 0 ? (
                            <img src={common.appIcon("triangelUp")} />
                          ) : (
                            <img src={common.appIcon("triangelDown")} />
                          )}
                          {overviewData.ananymous.ComparisonPercentage}%
                          <span>
                            {" "}
                            Compared to previous{" "}
                            <span className="poc-color">
                              {filterDate.NumberOfDays}
                            </span>{" "}
                            days
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={3} className="poc-cp" onClick={() => this.customerFilter(3)}>
            <div
              className={`${styles.aoPageBody} ${styles.aoMediumBox} ${styles.abVisual} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Visual Mechandising</span>
              </div>
              <div className={`${styles.abmContent}`}>
                <img src={common.appIcon("visual_merchandising")} />
                <h1>{overviewData.visualMechand.Percentage}%</h1>
              </div>
              <div className={`${styles.abmBottom}`}>
                <Row>
                  <Col xs={12}>
                    <h5
                      className={`${
                        overviewData.visualMechand.ComparisonPercentage > 0
                          ? styles.abIconUp
                          : styles.abIconDown
                      }`}
                    >
                      {overviewData.visualMechand.ComparisonPercentage > 0 ? (
                        <img src={common.appIcon("triangelUp")} />
                      ) : (
                        <img src={common.appIcon("triangelDown")} />
                      )}
                      {overviewData.visualMechand.ComparisonPercentage}%
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div
              className={`${styles.aoPageBody} ${styles.aoMediumBox} ${styles.abUr} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Unique & Returning</span>
              </div>
              <div className={`${styles.abUrContent}`}>
                <Row>
                  <Col
                    xs={6}
                    className="poc-cp"
                    onClick={() => this.customerFilter(4)}
                  >
                    <CircularProgressbar
                      value={
                        overviewData.uniqueReturn != ""
                          ? overviewData.uniqueReturn.UniquePercentage
                          : 0
                      }
                      text={
                        (overviewData.uniqueReturn != ""
                          ? overviewData.uniqueReturn.UniquePercentage
                          : 0) + "%"
                      }
                      styles={{
                        path: {
                          stroke: "#672AEB",
                        },
                        trail: { stroke: "#F4F4F4" },
                        text: {
                          fill: "#672AEB",
                          fontSize: "18px",
                          fontWeight: "bold",
                        },
                      }}
                    />
                    <p>Unique</p>
                  </Col>
                  <Col
                    xs={6}
                    className="poc-cp"
                    onClick={() => this.customerFilter(5)}
                  >
                    <CircularProgressbar
                      value={
                        overviewData.uniqueReturn != ""
                          ? overviewData.uniqueReturn.ReturningPercentage
                          : 0
                      }
                      text={
                        (overviewData.uniqueReturn != ""
                          ? overviewData.uniqueReturn.ReturningPercentage
                          : 0) + "%"
                      }
                      styles={{
                        path: {
                          stroke: "#9E52F6",
                        },
                        trail: { stroke: "#F4F4F4" },
                        text: {
                          fill: "#9E52F6",
                          fontSize: "18px",
                          fontWeight: "bold",
                        },
                      }}
                    />
                    <p>Returning</p>
                  </Col>
                </Row>
              </div>
              <span></span>
            </div>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row>
          <Col sm={6}>
            <div
              className={`${styles.aoPageBody} ${styles.largeBox} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Passby Traffic Vs Inside Traffic</span>
                <div className={`${styles.abHeadRight}`}></div>
              </div>
              <div className={`${styles.ablContent}`}>
                <Bar
                  data={{
                    labels: overviewData.passbyInsideTraffic[0],
                    datasets: [
                      {
                        data: overviewData.passbyInsideTraffic[1],
                        borderColor: chartColors[2],
                        backgroundColor: chartColors[2],
                        label: "Passby Traffic",
                      },
                      {
                        data: overviewData.passbyInsideTraffic[2],
                        borderColor: chartColors[0],
                        backgroundColor: chartColors[0],
                        label: "Inside Traffic",
                      },
                    ],
                  }}
                  getElementAtEvent={(elems) => {
                    this.chartEvent(elems, "passbyInside");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div
              className={`${styles.aoPageBody} ${styles.largeBox} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Passing Traffic</span>
              </div>
              <div className={`${styles.ablContent}`}>
                <Line
                  data={{
                    labels: overviewData.passingTraffic[0],
                    datasets: [
                      {
                        data: overviewData.passingTraffic[1],
                        borderColor: chartColors[0],
                        backgroundColor: "transparent",
                        label: "Registered",
                      },
                      {
                        data: overviewData.passingTraffic[2],
                        borderColor: chartColors[1],
                        backgroundColor: "transparent",
                        label: "Anonymous",
                      },
                    ],
                  }}
                  getElementAtEvent={(elems) => {
                    this.chartEvent(elems, "passing");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* Row 3 */}
        <Row>
          <Col
            sm={3}
            className="poc-cp"
            onClick={() => this.customerFilter(12)}
          >
            <div
              className={`${styles.aoPageBody} ${styles.aoMediumLgBox} ${styles.abBc} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Bounce Customers</span>
              </div>
              <div className={`${styles.abmlContent}`}>
                <img src={common.appIcon("bounce_customers")} />
                <h1>
                  {overviewData.bounceCustomer.Count}
                  <span>({overviewData.bounceCustomer.Percentage}%)</span>
                </h1>
              </div>
              <div className={`${styles.abmlBottom}`}>
                <Row>
                  <Col xs={12}>
                    <h5
                      className={`${
                        overviewData.bounceCustomer.ComparisonPercentage > 0
                          ? styles.abIconUp
                          : styles.abIconDown
                      }`}
                    >
                      {overviewData.bounceCustomer.ComparisonPercentage > 0 ? (
                        <img src={common.appIcon("triangelUp")} />
                      ) : (
                        <img src={common.appIcon("triangelDown")} />
                      )}
                      {overviewData.bounceCustomer.ComparisonPercentage}%
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div
              className={`${styles.aoPageBody} ${styles.aoMediumLgBox} ${styles.abAts} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Average Time Spents</span>
              </div>
              <div className={`${styles.abmlContent}`}>
                <img src={common.appIcon("averagetimespents")} />
                <h1>
                  {overviewData.averageTimeSpent.Hours}
                  <span className="hrs">HRS</span>
                </h1>
              </div>
              <div className={`${styles.abAtsBottom}`}>
                {overviewData.averageTimeSpent.TopLocations &&
                  overviewData.averageTimeSpent.TopLocations.map(
                    (item, key) => {
                      return (
                        <div key={key}>
                          <span>{item.LocationName}</span>
                          <span>{item.Hours} Hrs</span>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div
              className={`${styles.aoPageBody} ${styles.largeBox} poc-mb-20`}
            >
              <div className={`${styles.abHead}`}>
                <span>Recency and Frequency</span>
              </div>
              <div className={`${styles.ablContent}`}>
                <Line
                  data={{
                    labels: overviewData.recencyFrequency[0],
                    datasets: [
                      {
                        data: overviewData.recencyFrequency[1],
                        borderColor: chartColors[0],
                        backgroundColor: chartColors[7],
                        label: "Recency",
                      },
                      {
                        data: overviewData.recencyFrequency[2],
                        borderColor: chartColors[1],
                        backgroundColor: chartColors[8],
                        label: "Frequency",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Filter
          type="overview"
          filterInit={() => this.overviewAPI()}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export { AnalyticsOverview };
