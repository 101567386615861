import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
// pages
import CustomerAccount from "./CustomerAccount";
import BlockList from "./BlockList";
import CustomCriteria from "./CustomCriteria";

class AdminManageAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: history.location.pathname.split("/")[3],
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
    history.push(tab);
  };

  // page access
  checkAccess() {
    const { activeTab } = this.state;
    let pageList = {
      account: "MANAGECUSTOMERACCOUNT_PAGEACCESS",
      block: "BLACKLISTACCOUNT_PAGEACCESS",
      criteria: "CUSTOMERCRITERIAACCOUNT_PAGEACCESS",
    };
    if (!common.isAccess(pageList[activeTab])) {
      common.accessDenied();
    } else {
      return true;
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="pageContent">
        <Tab.Container activeKey={activeTab}>
          <div className="contentHead">
            <div className="row">
              <div className="col-sm-4 poFlex">
                <h1>Manage Account</h1>
                <ul>
                  <li>
                    <a href="#">Customer Account</a>
                  </li>
                  <li>
                    <a href="#" className="noPipe">
                      Overview
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-8">
                <Form className="poTabs poFlex poDefault">
                  {common.isAccess("MANAGECUSTOMERACCOUNT_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "account" ? "inActive" : ""}
                      onClick={() => this.setTab("account")}
                    >
                      Customer Account
                    </Button>
                  )}
                  {common.isAccess("BLACKLISTACCOUNT_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "block" ? "inActive" : ""}
                      onClick={() => this.setTab("block")}
                    >
                      Blocklist
                    </Button>
                  )}
                  {common.isAccess("CUSTOMERCRITERIAACCOUNT_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "criteria" ? "inActive" : ""}
                      onClick={() => this.setTab("criteria")}
                    >
                      Custom Criteria
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
          <div className="contentBody">
            <div className="content">
              <Tab.Content>
                {common.isAccess("MANAGECUSTOMERACCOUNT_PAGEACCESS") && (
                  <Tab.Pane eventKey="account" title="Customer Account">
                    <CustomerAccount />
                  </Tab.Pane>
                )}
                {common.isAccess("BLACKLISTACCOUNT_PAGEACCESS") && (
                  <Tab.Pane eventKey="block" title="Blocklist">
                    <BlockList />
                  </Tab.Pane>
                )}
                {common.isAccess("CUSTOMERCRITERIAACCOUNT_PAGEACCESS") && (
                  <Tab.Pane eventKey="criteria" title="Custom Criteria">
                    <CustomCriteria />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
export { AdminManageAccount };
