import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";

class ReportSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 5,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        ReportTypeId: 0,
        ScheduleTypeId: 0,
      },
      filterData: {
        reportTypeList: [],
        scheduleTypeList: [],
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      crudAccess: {
        add: false,
        edit: false,
        delete: false,
        action: false
      }
    };
  }

  componentDidMount = async () => {
    if (!common.isAccess("REPORTSCHEDULING_PAGEACCESS")) {
      common.accessDenied();
    } else {
      this.crudAccess();
      this.loadData();
      this.getAllReportType();
      this.getAllScheduleType();
    }
  };

  loadData = () => {
    const { paging, filter } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_GET_TABLE",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: {
        ReportTypeId: filter.ReportTypeId,
        ScheduleTypeId: filter.ScheduleTypeId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          ReportTypeId: 0,
          ScheduleTypeId: 0,
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(id, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(id, type) {
    var data = {
      url: "REPORT_SCHEDULE_DELETE",
    };

    if (type == "single") {
      data.query = "/" + id;
    } else {
      const { deleteIds } = this.state;
      data.query =
        "?ReportScheduleIds=" + deleteIds.join("&ReportScheduleIds=");
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  selectData(id) {
    const { data } = this.state;
    let deleteIds = this.state.deleteIds;
    let isBulkDelete = false;
    let isSelectAll = false;
    if (deleteIds.includes(id)) {
      deleteIds = common.removeItem(deleteIds, id);
    } else {
      deleteIds.push(id);
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    if (data.result.length == deleteIds.length) {
      isSelectAll = true;
    }
    this.setState({
      deleteIds,
      isBulkDelete,
      isSelectAll,
    });
  }

  selectAll() {
    const { data, isSelectAll } = this.state;
    let deleteIds = [];
    let select = false;
    let isBulkDelete = false;
    if (!isSelectAll) {
      select = true;
      if (data.RecordsCount > 0) {
        data.result.map((item, key) => {
          let id = item.ReportScheduleId;
          deleteIds.push(id);
        });
      }
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    this.setState({
      deleteIds,
      isSelectAll: select,
      isBulkDelete,
    });
  }

  // ### filter functions start here ###
  getAllReportType = () => {
    const { filterData } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_REPORT_TYPE_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.reportTypeList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getAllScheduleType = () => {
    const { filterData } = this.state;
    var data = {
      url: "REPORT_SCHEDULE_SCHEDULE_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.scheduleTypeList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // module & page access
  crudAccess() {
    const { crudAccess } = this.state;
    let columns = this.state.columns;
    (common.isAccess("REPORTSCHEDULING_ADD") && (crudAccess.add = true));
    (common.isAccess("REPORTSCHEDULING_EDIT") && (crudAccess.edit = true));
    (common.isAccess("REPORTSCHEDULING_DELETE") && (crudAccess.delete = true));
    (crudAccess.edit || crudAccess.delete) ? (crudAccess.action = true) : --columns;
    (!crudAccess.delete) && --columns;
    this.setState({
      columns,
      crudAccess
    })
  }

  render() {
    const {
      status,
      columns,
      data,
      paging,
      filter,
      filterData,
      isLoading,
      isFilter,
      isBulkDelete,
      deleteIds,
      isSelectAll,
      crudAccess
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-12 poFlex">
              <h1>Report Schedule List</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Report Schedule List
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    <span className="potableHeadTitle">Report Schedule</span>
                    {crudAccess.add && (
                      <> - <Link to="/report/schedule/add"><Button variant="primary config-btn">Schedule New Report</Button></Link></>
                    )}
                  </div>
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="poTableIcon">
                        <OverlayTrigger
                          trigger="click"
                          placement="left"
                          rootClose={true}
                          overlay={
                            <Popover id="location-filter">
                              <Popover.Title as="h3">Filter List</Popover.Title>
                              <Popover.Content>
                                <Form className="poTableFilter">
                                  <Form.Control
                                    as="select"
                                    name="ScheduleTypeId"
                                    value={filter.ScheduleTypeId}
                                    onChange={this.filterData}
                                  >
                                    <option value="0">Select Schedule</option>
                                    {filterData.scheduleTypeList.map(
                                      (item, key) => (
                                        <option key={key} value={item.Id}>
                                          {item.Value}
                                        </option>
                                      )
                                    )}
                                  </Form.Control>

                                  <Form.Control
                                    as="select"
                                    name="ReportTypeId"
                                    value={filter.ReportTypeId}
                                    onChange={this.filterData}
                                  >
                                    <option value="0">Select Report</option>
                                    {filterData.reportTypeList.map(
                                      (item, key) => {
                                        return (
                                          <option key={key} value={item.Id}>
                                            {item.Value}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                          onExit={() => this.handleFilter(false)}
                        >
                          {isFilter ? (
                            <img
                              src={common.appIcon("FilterSelect")}
                              className="poc-cp"
                              alt="Filter"
                              onClick={() => this.handleFilter(false)}
                            />
                          ) : (
                              <img
                                src={common.appIcon("FilterIcon")}
                                className="poc-cp"
                                alt="Filter"
                                onClick={() => this.handleFilter(true)}
                              />
                            )}
                        </OverlayTrigger>
                        <img
                          src={common.appIcon("RefreshIcon")}
                          className="poc-cp"
                          alt="Refresh"
                          onClick={() => this.handleRefresh()}
                        />
                        {crudAccess.delete && (
                          isBulkDelete ? (
                            <img
                              src={common.appIcon("DelAllActIcon")}
                              className="poc-cp"
                              alt="Delete"
                              onClick={() => this.deleteData(0, "bulk")}
                            />
                          ) : (
                              <img
                                src={common.appIcon("DelAllNoneIcon")}
                                className="poc-cp"
                                alt="Delete"
                              />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <span className="poTableFetcher">
                      fetching data please wait...
                    </span>
                  </div>
                )}

                <div className={isLoading ? "poTableOnLoad" : ""}>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Report Type</th>
                        <th>Schedule Type</th>
                        {crudAccess.action && <th>Actions</th>}
                        {crudAccess.delete && (<th>
                          <div className="d-flex">
                            <span className="poTableSelectAll">Select All</span>
                            {data.RecordsCount > 0 && (
                              <Form.Check
                                custom
                                label=""
                                type={"checkbox"}
                                id={"survey_select_all"}
                                checked={isSelectAll}
                                onChange={() => this.selectAll()}
                              />
                            )}
                          </div>
                        </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {status == "process" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Loading data please wait...</td>
                        </tr>
                      ) : status == "error" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>
                            There was an error while fetching record.
                          </td>
                        </tr>
                      ) : data.RecordsCount == 0 ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Sorry no record found.</td>
                        </tr>
                      ) : data.result.map((item, key) => {
                        let id = item.ReportScheduleId;
                        return (
                          <tr key={id}>
                            <td>{item.serial}</td>
                            <td>{item.ReportType}</td>
                            <td>{item.ScheduleType}</td>
                            {crudAccess.action && (
                              <td>
                                <span className="poTableAction">
                                  {crudAccess.edit && (
                                    <Link to={`/report/schedule/edit/${id}`}>
                                      <img
                                        className="poc-cp"
                                        src={common.appIcon("EditIcon")}
                                        alt="Edit"
                                      />
                                    </Link>
                                  )}
                                  {crudAccess.delete && (
                                    <img
                                      className="poc-cp"
                                      src={common.appIcon("DelIcon")}
                                      alt="Delete"
                                      onClick={() =>
                                        this.deleteData(id, "single")
                                      }
                                    />
                                  )}
                                </span>
                              </td>
                            )}
                            {crudAccess.delete && (
                              <td>
                                <Form.Check
                                  custom
                                  inline
                                  label=""
                                  type={"checkbox"}
                                  id={"check_" + id}
                                  checked={deleteIds.includes(id)}
                                  onChange={() => this.selectData(id)}
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })
                      }
                    </tbody>
                  </Table>
                  {data.RecordsCount > 0 && (
                    <div className="d-flex">
                      <div className="mr-auto p-2">
                        Total Records: {data.RecordsCount}
                      </div>
                      <div className="p-2">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={paging.pageIndex}
                          itemsCountPerPage={paging.pageSize}
                          totalItemsCount={data.RecordsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { ReportSchedule };
