import React from "react";
import { common } from "_helpers";
import { Button } from "react-bootstrap";
class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return Object.keys(nextState).length
      ? (nextState.status = nextProps.status)
      : null;
  }

  render() {
    const { status } = this.state;
    return status == "process" ? (
      <div className="po-status-bar">
        <img
          src={common.loadImg("loader.gif")}
          alt={"Loader"}
          className="img-fluid"
        />
        <p>Loading details please wait...</p>
      </div>
    ) : status == "error" ? (
      <div className="po-status-bar">
        {/* <img
          src={common.loadImg("wrong.png")}
          alt={"Loader"}
          className="img-fluid"
        /> */}
        <p>Oops! Something went wrong</p>
        <Button
          variant="primary config-btn poc-m0 poc-mt-10"
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
      </div>
    ) : (
      status == "empty" && (
        <div className="po-status-bar">
          {/* <img
            src={common.loadImg("noRecord.png")}
            alt={"Loader"}
            className="img-fluid"
          /> */}
          <p>Sorry no record found</p>
        </div>
      )
    );
  }
}

export { StatusBar };
