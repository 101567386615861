import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";
import FilterBackgroundLocation from "../includes/FilterBackgroundLocation";
// pages
import BackgroundAssignModal from "../includes/BackgroundAssignModal";
class AdminBackgroundAssign extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 8,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      backgroundImageInfo: "",
    };
  }

  componentDidMount = async () => {
    this.crudAccess();
    this.loadData();
    this.getBackgroundImageInfo();
  };

  getBackgroundImageInfo = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            backgroundImageInfo: result,
          });
        } else {
          this.setState({
            status: "error",
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  loadData = () => {
    const { params, paging } = this.state;
    const { filter } = this.filter.state;
    this.setState({ status: "process" });
    
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_LOCATION_GET_TABLE",
      query:
        "/" +
        params.id +
        "?PageSize=" +
        paging.pageSize +
        "&PageIndex=" +
        (paging.pageIndex - 1),
      body: filter,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => {}
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          BackgroundTypeId: 0,
          BackgroundImageName: "",
        },
      },
      () => {
        this.filter.resetForm();
      }
    );
  }

  deleteData(bId, lId) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(bId, lId);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(bId, lId) {
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_LOCATION_DELETE",
      query: "/" + bId + "/" + lId,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  }

  // props
  onComplete(type) {
    if (type == "success") {
      this.handleRefresh();
    }
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.loadData();
  }

  filterReset() {
    this.loadData();
  }

  // module & page access
  crudAccess() {
    let allow = false;
    common.isAccess("BACKGROUNDIMAGE_ASSIGNSTORE") && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      status,
      columns,
      data,
      paging,
      filter,
      filterData,
      isLoading,
      isFilter,
      isBulkDelete,
      deleteIds,
      isSelectAll,
      backgroundImageInfo,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Background Image Assign</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Assign Location
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    {backgroundImageInfo != "" ? (
                      <span className="potableHeadTitle">
                        Assign Location for{" "}
                        <span className="poc-color">
                          {backgroundImageInfo.BackgroundImageName}
                        </span>
                        {" - "}
                      </span>
                    ) : (
                      <span className="potableHeadTitle">
                        Assign Location {" - "}
                      </span>
                    )}
                    <Link to="/admin/visualize/background">
                      <Button variant="primary config-btn">Go Back</Button>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="poTableIcon">
                        <img
                          src={common.appIcon("FilterIcon")}
                          className="poc-cp"
                          alt="Filter"
                          onClick={() => this.filterModal(true)}
                        />
                        <img
                          src={common.appIcon("RefreshIcon")}
                          className="poc-cp"
                          alt="Refresh"
                          onClick={() => this.handleRefresh()}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <span className="poTableFetcher">
                      fetching data please wait...
                    </span>
                  </div>
                )}

                <div className={isLoading ? "poTableOnLoad" : ""}>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>LocationName</th>
                        <th>LocationType</th>
                        <th>CountryName</th>
                        <th>ProvinceName</th>
                        <th>ConceptName</th>
                        <th>Assign</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {status == "process" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Loading data please wait...</td>
                        </tr>
                      ) : status == "error" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>
                            There was an error while fetching record.
                          </td>
                        </tr>
                      ) : data.RecordsCount == 0 ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Sorry no record found.</td>
                        </tr>
                      ) : (
                        data.result.map((item, key) => {
                          let id = item.BackgroundImageId;
                          return (
                            <tr key={key}>
                              <td>{item.serial}</td>
                              <td>{item.LocationName}</td>
                              <td>{item.LocationType}</td>
                              <td>{item.CountryName}</td>
                              <td>{item.ProvinceName}</td>
                              <td>{item.ConceptName}</td>
                              <td>
                                <span className="poTableAction">
                                  {item.BackgroundImageLocation == null ? (
                                    <img
                                      className="poc-cp"
                                      src={common.loadImg(
                                        "administration/assign.png"
                                      )}
                                      alt="Assign"
                                      onClick={() =>
                                        this.BackgroundAssignModal.parentCall(
                                          params.id,
                                          item
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      className="poc-cp"
                                      src={common.loadImg(
                                        "administration/assign_edit.png"
                                      )}
                                      alt="Edit"
                                      onClick={() =>
                                        this.BackgroundAssignModal.parentCall(
                                          params.id,
                                          item
                                        )
                                      }
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="poTableAction">
                                  {item.BackgroundImageLocation != null ? (
                                    <img
                                      className="poc-cp"
                                      src={common.appIcon("DelIcon")}
                                      alt="Delete"
                                      onClick={() =>
                                        this.deleteData(
                                          item.BackgroundImageLocation
                                            .BackgroundImageId,
                                          item.BackgroundImageLocation
                                            .LocationId
                                        )
                                      }
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                  {data.RecordsCount > 0 && (
                    <div className="d-flex">
                      <div className="mr-auto p-2">
                        Total Records: {data.RecordsCount}
                      </div>
                      <div className="p-2">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={paging.pageIndex}
                          itemsCountPerPage={paging.pageSize}
                          totalItemsCount={data.RecordsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackgroundAssignModal
          ref={(instance) => {
            this.BackgroundAssignModal = instance;
          }}
          onComplete={(type) => this.onComplete(type)}
        />
        <FilterBackgroundLocation
          type="background"
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export { AdminBackgroundAssign };
