import React from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import Select from "react-select";
// _includes
import { StatusBar } from "_includes";
import { common } from "_helpers";
import { apiServices } from "_api";
// css
import styles from "pages/Administration/css/Admin.module.css";

export default class BackgroundAssignModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Assign Location",
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        BackgroundImageId: "",
        LocationId: "",
        PageCodeList: [],
        BackgroundTypeId: "",
      },
      errors: {
        BackgroundTypeId: {
          error: "",
          isReq: "Please select Type",
        },
        PageCodeList: {
          error: "",
          isReq: "Please select page",
        },
      },
      // data
      backgroundImageInfo: "",
      pageCodeList: [],
      pageCodeSelectList: [],
      backgroundTypeList: [],
    };
  }

  parentCall(bId, item) {
    // api process
    this.apiCount = 0;
    this.apiTotal = 3;

    // fields
    let fields = {
      BackgroundImageId: bId,
      LocationId: item.LocationId,
      BackgroundTypeId: "",
      PageCodeList: "",
    };

    if (item.BackgroundImageLocation != null) {
      fields.BackgroundTypeId = item.BackgroundImageLocation.BackgroundTypeId;
      fields.PageCodeList = item.BackgroundImageLocation.PageCodeList;
    }

    this.setState(
      {
        fields,
        isModal: true,
      },
      () => {
        this.initCheck();
      }
    );
  }

  initCheck() {
    this.getImageInfo();
    this.getPageCodeList();
    this.getBackgroundTypeList();
  }

  getImageInfo = () => {
    const { params, fields } = this.state;
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_BY_ID",
      query: "/" + fields.BackgroundImageId,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            backgroundImageInfo: result,
          });
        } else {
          this.apiError();
        }
      },
      (error) => {
        this.apiError();
      },
      (final) => {
        this.setApiStatus();
      }
    );
  };

  getPageCodeList = () => {
    const { fields } = this.state;

    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_GET_PAGE_CODE",
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          let pageCodeList = [];
          let pageCodeSelectList = [];
          response.data.map((data, key) => {
            let obj = {
              label: data,
              value: data,
            };
            if (fields.PageCodeList.includes(data)) {
              pageCodeSelectList.push(obj);
            }
            pageCodeList.push(obj);
          });

          this.setState({
            pageCodeList,
            pageCodeSelectList,
          });
        } else {
          this.apiError();
        }
      },
      (error) => {
        this.apiError();
      },
      (final) => {
        this.setApiStatus();
      }
    );
  };

  getBackgroundTypeList = () => {
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_GET_TYPE",
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({
            backgroundTypeList: response.data,
          });
        } else {
          this.apiError();
        }
      },
      (error) => {
        this.apiError();
      },
      (final) => {
        this.setApiStatus();
      }
    );
  };

  apiError() {
    this.setState({
      status: "error",
      isLoading: false,
    });
  }

  setApiStatus() {
    this.apiCount += 1;
    if (this.apiCount == this.apiTotal) {
      this.setState({
        status: "success",
      });
    }
  }

  // Validation and Handler
  validateForm() {
    const { fields, errors, actorId } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    let listItems = [];
    data.map((item, key) => {
      listItems.push(item.value);
    });
    fields[name] = listItems;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  }

  // CRUD Operations
  onSubmit = () => {
    this.setState({ formSubmit: true });
    let isValid = this.validateAll();
    if (isValid) {
      this.setState({ isLoading: true });
      const { fields } = this.state;
      let urlencoded = new URLSearchParams();
      urlencoded.append("BackgroundImageId", fields.BackgroundImageId);
      urlencoded.append("LocationId", fields.LocationId);
      urlencoded.append("BackgroundTypeId", fields.BackgroundTypeId);

      fields.PageCodeList.map((item) => {
        urlencoded.append("PageCodeList", item);
      });

      var data = {
        url: "ADMIN_VISUALIZE_BACKGROUND_LOCATION_PERSIST",
        body: urlencoded,
        method: "POST",
        directEncode: true,
      };

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { id, title } = this.state;
    if (id) {
      common.snack("S", title + " has been updated");
    } else {
      common.snack("S", "New " + title + " has been added");
    }
    this.onComplete("success");
  }

  crudError() {
    const { title } = this.state;
    common.snack("S", "There was an error while add / update a " + title);
    this.setState({
      isLoading: false,
    });
  }

  // Modal
  onComplete(status) {
    this.setState({
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        BackgroundImageId: "",
        LocationId: "",
        PageCodeList: [],
        BackgroundTypeId: "",
      },
    });
    this.props.onComplete(status);
  }

  render() {
    const {
      status,
      isLoading,
      isModal,
      fields,
      errors,
      formSubmit,
      backgroundImageInfo,
      pageCodeList,
      pageCodeSelectList,
      backgroundTypeList,
    } = this.state;
    return (
      <Modal show={isModal} onHide={() => this.onComplete("cancel")}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="cropApp">
          <StatusBar status={status} />
          {status == "success" && (
            <div>
              <div className={`${styles.avbAssignModal} poc-mb-15`}>
                <label className="poLabel">
                  {backgroundImageInfo.BackgroundImageName}
                </label>
                <img
                  className="poc-cp"
                  src={common.loadWeServ(
                    "https://portalone3resources.portalonewifi.com/" +
                      backgroundImageInfo.ImageFileName,
                    500,
                    500
                  )}
                  alt={backgroundImageInfo.BackgroundImageName}
                />
              </div>
              <Form className="poTableFilter">
                <label className="poLabel">
                  Select Background Type{" "}
                  {errors.BackgroundTypeId.error !== "" && formSubmit && (
                    <>
                      {" "}
                      -{" "}
                      <span className="error">
                        {errors.BackgroundTypeId.error}
                      </span>
                    </>
                  )}
                </label>
                <Form.Control
                  as="select"
                  name="BackgroundTypeId"
                  value={fields.BackgroundTypeId}
                  onChange={(e) => this.handleOnChange(e)}
                  className="poc-br-0"
                >
                  <option value="">Select Type</option>
                  {backgroundTypeList.map((item, key) => {
                    return (
                      <option key={key} value={item.Id}>
                        {item.Value}
                      </option>
                    );
                  })}
                </Form.Control>
                <label className="poLabel">
                  Selet Page{" "}
                  {errors.PageCodeList.error !== "" && formSubmit && (
                    <>
                      {" "}
                      -{" "}
                      <span className="error">{errors.PageCodeList.error}</span>
                    </>
                  )}
                </label>
                {pageCodeList.length > 0 ? (
                  <Select
                    isMulti
                    options={pageCodeList}
                    defaultValue={pageCodeSelectList}
                    onChange={(data) =>
                      this.handleSelectChange(data, "PageCodeList")
                    }
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Loading page code please wait..."
                    readOnly={true}
                    className="poc-br-0"
                  />
                )}
              </Form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="poModalFooter">
          <Button
            className="config-btn"
            onClick={() => this.onSubmit()}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Submit"}
          </Button>
          <Button
            className="config-cancel"
            disabled={isLoading}
            onClick={() => this.onComplete("cancel")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
