import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
//API SERVICES
import { apiServices } from "_api";
import { common } from "_helpers/common";
import { confirmAlert } from "react-confirm-alert";

class ImportCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file:'',
      isError:'',
      isModal: false,
      isLoading: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }
  
  setModal(status) {
    this.setState({
      isModal: status,
    });
  }

  onChange(e) {
    let isError = '';
    if(e.target.files[0].type!='text/csv'){
      isError = 'notValid';
    }

    this.setState({
      file:e.target.files[0],
      isError
    })
  }

  onFormSubmit(e){
    e.preventDefault() // Stop form submit
    this.fileUpload();
  }

  fileUpload(){
    const {file,isError} = this.state;
    if(file=='' || isError!=''){
      this.setState({
        isError:(file=='' ? 'selectFile': 'notValid')
      })
    } else {
      common.checkToken((token) => {
        if (token) {
          this.setState({isLoading:true})
          var formdata = new FormData();
          formdata.append("", file,"file");
          formdata.append("FileUploadScheduleTypeId", "1");

          var data = {
            url: 'COMMON_FILE_UPLOAD_SCHEDULE_UPLOAD_FILES',
            body: formdata,
            contentType:2,
          };
      
          apiServices.call(
            data,
            response => {
              if (response.status === 200) {
                if (response.status === 200) {
                  this.confirmBox('success');
                }
              }
            },
            error => {
              this.confirmBox('error');
            },
            final => {}
          )
        }
      });
    }
  }

  confirmBox(type){
    this.setState({
      file:'',
      isError:'',
      isModal: false,
      isLoading: false
    },()=>{
      confirmAlert({
        title: (type=='success' ? 'Success' : 'Error'),
        message: (type=='success' ? 'File has been uploaded successfully' : 'There was an error while upload a file please try again!'),
        buttons: [
          {
            label: (type=='success' ? 'Upload Again' : 'Try Again'),
            onClick: () => {
              this.setState({
                isModal:true
              })
            }
          },
          {
            label: "Close",
          }
        ]
      });
    })
  }


  render() {
    const { isModal, isError, isLoading } = this.state;
    return (
      <Modal show={isModal}>
        <Modal.Header>
          <Modal.Title>Import CSV</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.onFormSubmit}>
        <Modal.Body>
          <input type="file" onChange={this.onChange} />
        </Modal.Body>
        <Modal.Footer>
          {isError!='' && (<span className="error">{isError=='notValid' ? 'Upload file type must be CSV format': 'Please select the file'}</span>)}
          <Button variant="primary config-btn" type="submit" disabled={isLoading}>{isLoading ? 'Uploading...' : 'Upload'}</Button>
          <Button variant="primary config-btn" onClick={() => this.setModal(false)}>Cancel</Button>
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ImportCSV;
