import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class DeviceCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Device",
      parent: "/admin/config/device",
      status: "process",
      formSubmit: false,
      isLoading: false,
      isDataLoading: {
        deviceType: true,
        location: true,
        zone: false,
        accessPointModal: true
      },
      fields: {
        DeviceName: "",
        Description: "",
        MacAddress: "",
        DeviceTypeId: "",
        AccessPointModelId: "",
        LocationId: "",
        ZoneId: "",
      },
      errors: {
        DeviceName: {
          error: "",
          isReq: "Please enter the device name",
        },
        MacAddress: {
          error: "",
          isReq: "Please enter the Mac Address",
          isValidMac: "Please enter the valid Mac address",
        },
        DeviceTypeId: {
          error: "",
          isReq: "Please select the device type",
        },
        AccessPointModelId: {
          error: "",
          isReq: "Please select the accessPointModal",
        },
        LocationId: {
          error: "",
          isReq: "Please select the Location",
        },
        ZoneId: {
          error: "",
          isReq: "Please select the Zone",
        },
      },
      // data
      persistData: [],
      deviceTypeList: [],
      deviceTypeSelected: [],
      accessPointModalList: [],
      accessPointModalSelected: [],
      locationList: [],
      locationSelected: [],
      zoneList: [],
      zoneSelected: [],
    };
  }

  componentDidMount() {
    if(this.crudAccess()){
      this.initCheck();
    }
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.setState({ status: "success" })
      this.fetchRecord();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_CONFIG_DEVICE_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            status: "success",
            fields: {
              DeviceName: result.DeviceName,
              Description: result.Description,
              MacAddress: result.MacAddress,
            },
            persistData: {
              DeviceTypeId: result.DeviceTypeId,
              AccessPointModelId: result.AccessPointModelId,
              LocationId: result.LocationId,
              ZoneId: result.ZoneId,
            },
          }, () => {
            this.fetchRecord();
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  fetchRecord() {
    this.getAllDeviceType();
    this.getAllAccessPointModal();
    this.getAllLocation();
  }

  getAllDeviceType = () => {
    const { fields, persistData } = this.state;
    this.setDataLoading("deviceType", true);
    var data = {
      url: "ADMIN_CONFIG_DEVICE_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let deviceTypeList = [];
          let deviceTypeSelected = [];

          result.map((item) => {
            let obj = {
              label: item.Value,
              value: item.Id,
            };
            if (persistData.DeviceTypeId == item.Id) {
              deviceTypeSelected.push(obj);
              fields.DeviceTypeId = obj;
            }
            deviceTypeList.push(obj);
          });
          this.setState({
            fields,
            deviceTypeList,
            deviceTypeSelected,
          });
        }
      },
      (error) => {
      },
      (final) => {
        this.setDataLoading("deviceType", false);
      }
    );
  };

  getAllAccessPointModal = () => {
    const { persistData, fields } = this.state;
    this.setDataLoading("accessPointModal", true);
    var data = {
      url: "ADMIN_CONFIG_DEVICE_ACCESS_POINT_MODEL_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let accessPointModalList = [];
          let accessPointModalSelected = [];
          result.map((item) => {
            let obj = {
              label: item.Value,
              value: item.Id,
            };
            if (persistData.AccessPointModelId == item.Id) {
              accessPointModalSelected.push(obj);
              fields.AccessPointModelId = obj;
            }
            accessPointModalList.push(obj);
          });
          this.setState({
            fields,
            accessPointModalList,
            accessPointModalSelected
          });
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("accessPointModal", false);
      }
    );
  };

  getAllLocation = async () => {
    const { params, fields, persistData } = this.state;
    this.setDataLoading("location", true);
    var data = {
      url: "ADMIN_CONFIG_LOCATION_GET_ALL",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          let locationList = [];
          let locationSelected = [];
          response.map((data) => {
            let obj = {
              label: data.LocationName,
              value: data.LocationId,
            };
            if (persistData.LocationId == data.LocationId) {
              locationSelected.push(obj);
              fields.LocationId = obj;
            }
            locationList.push(obj);
          });
          this.setState(
            {
              fields,
              locationList,
              locationSelected
            },
            () => {
              if (params.id) {
                this.getAllZone("load");
              }
            }
          );
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("location", false);
      }
    );
  };

  getAllZone = (type) => {
    const { fields, persistData } = this.state;
    this.setDataLoading("zone", true);
    fields.ZoneId = "";
    this.setState({ fields });

    var data = {
      url: "ADMIN_CONFIG_ZONE_GET_ALL",
      body: {
        LocationId: fields.LocationId.value,
      },
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let zoneList = [];
          let zoneSelected = [];
          result.map((item) => {
            let obj = {
              label: item.ZoneName,
              value: item.ZoneId,
            };
            if (type == "load") {
              if (persistData.ZoneId == item.ZoneId) {
                zoneSelected.push(obj);
                fields.ZoneId = obj;
              }
            }
            zoneList.push(obj);
          });
          this.setState({
            fields,
            zoneList,
            zoneSelected,
          });
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("zone", false);
      }
    );
  };
  
  setDataLoading(type, status) {
    const { isDataLoading } = this.state;
    isDataLoading[type] = status
    this.setState({
      isDataLoading
    })
  }

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isValidMac) {
        if (!this.checkMackAddress(fields[key])) {
          errors[key].error = errors[key].isValidMac;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isValidMac) {
        if (!this.checkMackAddress(fields[key])) {
          errors[key].error = errors[key].isValidMac;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  checkMackAddress(val) {
    var regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
    return regex.test(val);
  }

  formatMacAddress(value) {
    var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
      str = value.replace(/[^a-f0-9]/gi, "");
    while (r.test(str)) str = str.replace(r, "$1" + "-" + "$2");
    return str.slice(0, 17);
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    let name = event.target.name;
    let value = event.target.value;

    if (name === "MacAddress") {
      value = this.formatMacAddress(value);
    }
    fields[name] = value;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    fields[name] = data;
    this.setState(
      {
        fields,
      },
      () => {
        if (name == "LocationId") {
          this.getAllZone("change");
        }
        this.validateForm();
      }
    );
  }

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var bodayData = {
        DeviceName: fields.DeviceName,
        Description: fields.Description,
        MacAddress: fields.MacAddress,
        DeviceTypeId: fields.DeviceTypeId.value,
        AccessPointModelId: fields.AccessPointModelId.value,
        ZoneId: fields.ZoneId.value,
      }

      var data = {};
      if (params.type == "add") {
        data = {
          url: "ADMIN_CONFIG_ZONE_PERSIST",
          query: "?IsAdd=true",
          body: bodayData,
          method: "POST",
        };
      } else {
        bodayData.DeviceId = params.id;
        data = {
          url: "ADMIN_CONFIG_ZONE_PERSIST",
          query: "?IsAdd=false",
          body: bodayData,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("E", "There was an error while adding a " + title);
    } else {
      common.snack("E", "There was an error while updating a " + title);
    }
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type=="add" && common.isAccess("DEVICECONFIGURATION_ADD")) && (allow=true);
    (params.type=="edit" && common.isAccess("DEVICECONFIGURATION_EDIT")) && (allow=true);
    if(!allow){
      common.accessDenied();
    }
    return allow;
  }
  
  render() {
    const {
      params,
      status,
      isLoading,
      isDataLoading,
      fields,
      errors,
      formSubmit,
      // data
      deviceTypeList,
      deviceTypeSelected,
      accessPointModalList,
      accessPointModalSelected,
      locationList,
      locationSelected,
      zoneList,
      zoneSelected,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Device</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add Device" : "Update Device"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add Device" : "Update Device"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Device Name
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="DeviceName"
                          placeholder="Device Name"
                          value={fields.DeviceName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.DeviceName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.DeviceName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Mac Address
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="MacAddress"
                          placeholder="Mac Address"
                          value={fields.MacAddress}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.MacAddress.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.MacAddress.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Device Type
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.deviceType ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading device type please wait..."
                            readOnly={true}
                          />
                        ) :
                          deviceTypeList.length > 0 ? (
                            <Select
                              options={deviceTypeList}
                              defaultValue={deviceTypeSelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "DeviceTypeId")
                              }
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.DeviceTypeId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.DeviceTypeId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="accessPointModal" className="mb-3">
                      <Form.Label column sm="3">
                        AccessPointModal Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.accessPointModal ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading Access Point Modal please wait..."
                            readOnly={true}
                          />
                        ) :
                          accessPointModalList.length > 0 ? (
                            <Select
                              options={accessPointModalList}
                              defaultValue={accessPointModalSelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "AccessPointModelId")
                              }
                              value={fields.AccessPointModelId}
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.AccessPointModelId.error && formSubmit && (
                          <span className="error">{errors.AccessPointModelId.error}</span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Select Location
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.location ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading location please wait..."
                            readOnly={true}
                          />
                        ) :
                          locationList.length > 0 ? (
                            <Select
                              options={locationList}
                              defaultValue={locationSelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "LocationId")
                              }
                              value={fields.LocationId}
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.LocationId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LocationId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="zone" className="mb-3">
                      <Form.Label column sm="3">
                        Zone Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.zone ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading zone please wait..."
                            readOnly={true}
                          />
                        ) :
                          <Select
                            options={zoneList}
                            defaultValue={zoneSelected}
                            onChange={(data) =>
                              this.handleSelectChange(data, "ZoneId")
                            }
                            value={fields.ZoneId}
                          />
                        }
                        {errors.ZoneId.error && formSubmit && (
                          <span className="error">{errors.ZoneId.error}</span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading ? "Processing..." : params.type == "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { DeviceCrud };
