import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { history } from "_helpers";
import { AdminLayoutRoute, LoginLayoutRoute } from "./routeLayout";
// pages
import {
  Login,
  // ### Dashboard
  Dashboard,

  // ### Logs
  Logs,

  // ### Reports
  ReportRegister,
  ReportVisit,
  ReportInsight,
  ReportLoc,
  ReportSchedule,
  ReportScheduleCrud,

  // ### Analytics
  Analytics,

  // ### Action & Alerts
  ActionAlert,
  ActionRuleCrud,

  // ### Marketing
  Marketing,
  MarketingCampaign,
  MarketingCampaignCrud,
  MarketingSurvey,
  MarketingSurveyCrud,

  // ### Administration
  // --Configuration
  Configuration,
  CountryCrud,
  ProvinceCrud,
  LocationCrud,
  ConceptCrud,
  ZoneCrud,
  DeviceCrud,
  // --ManageAccount
  AdminManageAccount,
  // --License
  License,
  // --User
  AdminUser,
  AdminAccountCrud,
  AdminGroupCrud,
  AdminGroupPermission,
  // --Visualize
  Visualize,
  AdminBackgroundCrud,
  AdminBackgroundAssign
} from "../pages";

class RouterComponent extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          {/*### Login ###*/}
          <LoginLayoutRoute exact path="/" component={Login} />

          {/*### Dashboard ###*/}
          <AdminLayoutRoute path="/dashboard" component={Dashboard} />

          {/*### Logs ###*/}
          <AdminLayoutRoute
            exact
            path="/logs/:page"
            component={Logs}
          />

          {/*### Analytics ###*/}
          <AdminLayoutRoute
            exact
            path="/analytics/:page"
            component={Analytics}
          />

          {/*### Reports ###*/}
          <AdminLayoutRoute
            exact
            path="/report/register"
            component={ReportRegister}
          />
          <AdminLayoutRoute
            exact
            path="/report/visit"
            component={ReportVisit}
          />
          <AdminLayoutRoute
            exact
            path="/report/insight/:page"
            component={ReportInsight}
          />
          <AdminLayoutRoute
            exact
            path="/report/location/:page"
            component={ReportLoc}
          />
          <AdminLayoutRoute
            exact
            path="/report/schedule"
            component={ReportSchedule}
          />
          <AdminLayoutRoute
            exact
            path="/report/schedule/:type/:id?"
            component={ReportScheduleCrud}
          />

          {/*### Action & Alert ###*/}
          <AdminLayoutRoute exact path="/actionalert" component={ActionAlert} />
          <AdminLayoutRoute
            exact
            path="/actionalert/:type/:id?"
            component={ActionRuleCrud}
          />

          {/*### Marketing ###*/}
          <AdminLayoutRoute exact path="/marketing" component={Marketing} />
          <AdminLayoutRoute
            exact
            path="/marketing/campaign"
            component={MarketingCampaign}
          />
          <AdminLayoutRoute
            exact
            path="/marketing/campaign/:type/:id?"
            component={MarketingCampaignCrud}
          />
          <AdminLayoutRoute
            exact
            path="/marketing/survey"
            component={MarketingSurvey}
          />
          <AdminLayoutRoute
            exact
            path="/marketing/survey/:type/:id?"
            component={MarketingSurveyCrud}
          />

          {/*### Administration ###*/}
          {/* Configuration */}
          <AdminLayoutRoute
            exact
            path="/admin/config/:page"
            component={Configuration}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/country/:type/:id?"
            component={CountryCrud}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/province/:type/:id?"
            component={ProvinceCrud}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/concept/:type/:id?"
            component={ConceptCrud}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/location/:type/:id?"
            component={LocationCrud}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/zone/:type/:id?"
            component={ZoneCrud}
          />
          <AdminLayoutRoute
            exact
            path="/admin/config/device/:type/:id?"
            component={DeviceCrud}
          />
          {/* Manage Account */}
          <AdminLayoutRoute
            exact
            path="/admin/customer/:page"
            component={AdminManageAccount}
          />
          {/* License */}
          <AdminLayoutRoute exact path="/admin/license" component={License} />
          {/* User */}
          <AdminLayoutRoute exact path="/admin/user/:page" component={AdminUser} />
          <AdminLayoutRoute
            path="/admin/user/account/:type/:id?"
            component={AdminAccountCrud}
          />
          <AdminLayoutRoute
            path="/admin/user/group/:type/:id?"
            component={AdminGroupCrud}
          />
          <AdminLayoutRoute
            path="/admin/user/permission/:id"
            component={AdminGroupPermission}
          />
          {/* Visualize */}
          <AdminLayoutRoute
            exact
            path="/admin/visualize/:page"
            component={Visualize}
          />
          <AdminLayoutRoute
            path="/admin/visualize/background/add/:id?"
            component={AdminBackgroundCrud}
          />
          <AdminLayoutRoute
            path="/admin/visualize/background/edit/:id?"
            component={AdminBackgroundCrud}
          />
          <AdminLayoutRoute
            path="/admin/visualize/background/assign/:id"
            component={AdminBackgroundAssign}
          />
        </Switch>
      </Router>
    );
  }
}

export { RouterComponent };
