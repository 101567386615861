import React from "react";
import { Tab, Tabs } from "react-bootstrap";
// Pages
import ZoneOverview from "./ZoneOverview";
import ZoneAnalysis from "./ZoneAnalysis";
// css
import styles from "../css/Analytics.module.css";

class AnalyticsZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        result: [],
      },
      activeTab:'overview'
    };
  }

  setTab(tab){
    this.setState({
      activeTab:tab
    })
  };
  
  // filter
  filterModal(status) {
    const {activeTab} = this.state
    if(activeTab=='overview'){
      this.overview.filterModal(status)
    } else if(activeTab=='analysis'){
      this.analysis.filterModal(status)
    }
  }

  render() {
    return (
      <div className="contentBox">
        <div className={`${styles.acTabs} poc-mb-20`}>
          <Tabs id="azTabs" defaultActiveKey="overview" onSelect={(tab)=>this.setTab(tab)}>
            <Tab eventKey="overview" title="Overview">
              <ZoneOverview
                ref={(instance) => {
                  this.overview = instance;
                }}
              />
            </Tab>
            <Tab eventKey="analysis" title="Analysis">
              <ZoneAnalysis
                ref={(instance) => {
                  this.analysis = instance;
                }}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
export { AnalyticsZone };
