import { envConfig } from './config';
// ---------- LOGIN API ----------
export const ADMIN_LOGIN = envConfig.LOGIN_API + 'GetToken';

// ---------- DASHBOARD API ----------
export const GET_CUSTOMER_REGISTRATION = envConfig.DASHBOARD_API + 'GetCustomerRegistrations'; //GET CUSTOMER REGISTRATION
export const GET_DATE_FILTER_LISTS = envConfig.DASHBOARD_API + 'GetDashboardDateFilterItemList'; //FILTER LIST
export const GET_NEW_VISITOR_INFO = envConfig.DASHBOARD_API + 'GetNewVisitorsInfo'; //GET NEW VISITOR INFO
export const GET_RETURNING_VISITORS = envConfig.DASHBOARD_API + 'GetReturningVisitorsInfo'; //GET RETURNING VISITORS INFO
export const GET_LOCATION_COUNT = envConfig.DASHBOARD_API + 'GetLocationCount'; //GET LOCATION COUNT INFO
export const GET_BUSIEST_DAY = envConfig.DASHBOARD_API + 'GetBusiestDay'; //GET BUSIEST DAY
export const GET_BUSIEST_TIME = envConfig.DASHBOARD_API + 'GetBusiestTime'; //GET BUSIEST TIME
export const GET_ACCESS_POINT_AND_SENSOR = envConfig.DASHBOARD_API + 'GetAccessPointAndSensorCount'; //GET ACCESS POINT AND SENSOR
export const GET_CUSTOMER_VISIT = envConfig.DASHBOARD_API + 'GetCustomerGenderWiseVisitInfo'; //GET CUSTOMER VISIT GENDER WISE
export const GET_AUTHENTICATION_MODE = envConfig.DASHBOARD_API + 'GetCustomerAuthenticationModeInfo'; //GET AUTHENTICATION MODE DATA
export const GET_DEVICE_CHART = envConfig.DASHBOARD_API + 'GetCustomerDeviceInfo'; //GET DEVICE CHART DATA
export const GET_LOYALTY_CHART = envConfig.DASHBOARD_API + 'GetLoyaltyInfo'; //GET LOYALTY DATA
export const GET_RECENT_VISIT_CHART = envConfig.DASHBOARD_API + 'GetRecencyInfo'; //GET RECENT VISIT DATA
export const GET_NEW_VISITORS_CHART = envConfig.DASHBOARD_API + 'GetCustomerRepeatInfo'; //GET NEW VISITORS DATA

// ---------- LOGS ----------
export const LOGS_SMS_GET_TABLE = envConfig.LOGS_API + 'SmsLog/GetTableData';
export const LOGS_SMS_GET_ALL = envConfig.LOGS_API + 'SmsLog/GetAll';
export const LOGS_SMS_TYPE_LIST = envConfig.LOGS_API + 'SmsLog/GetSmsTypeList';
export const LOGS_SMS_STATUS_LIST = envConfig.LOGS_API + 'SmsLog/GetSmsStatusList';
export const LOGS_ACTIVITY_GET_TABLE = envConfig.LOGS_API + 'ActivityLog/GetTableData';
export const LOGS_ACTIVITY_MODULE_LIST = envConfig.LOGS_API + 'ActivityLog/GetModuleList';
export const LOGS_ACTIVITY_PROCESS_LIST = envConfig.LOGS_API + 'ActivityLog/GetProcessList';
export const LOGS_UNSUBSCRIPTION_GET_TABLE = envConfig.LOGS_API + 'CustomerUnsubsriptionLog/GetTableData';
export const LOGS_UNSUBSCRIPTION_GET_ALL = envConfig.LOGS_API + 'CustomerUnsubsriptionLog/GetAll';
export const LOGS_UNSUBSCRIPTION_SUBSCRIPTION_STATUS_LIST = envConfig.LOGS_API + 'CustomerUnsubsriptionLog/GetCustomerSubscriptionStatusList';

// ---------- REPORTS ----------
// Register
export const REPORT_CUSTOMER_REGISTRATION = envConfig.REPORTS_API + 'Customer/GetCustomerRegistrationTableData';
export const REPORT_CUSTOMER_REGISTRATION_GET_ALL = envConfig.REPORTS_API + 'Customer/GetAllCustomerRegistration';
// Visit Log
export const REPORT_CUSTOMER_VISIT_GET_TABLE = envConfig.REPORTS_API + 'Customer/GetCustomerVisitsTableData';
export const REPORT_CUSTOMER_VISIT_GET_ALL = envConfig.REPORTS_API + 'Customer/GetAllCustomerVisits';
// Age Group
export const REPORT_CUSTOMER_AGE_GROUP_GET_TABLE = envConfig.REPORTS_API + 'Customer/GetAgeGroupReportTableData';
export const REPORT_CUSTOMER_AGE_GROUP_LIST = envConfig.REPORTS_API + 'Customer/GetAllCustomerAgeGroupList';
// Visit time
export const REPORT_CUSTOMER_VISIT_TIME_GET_TABLE = envConfig.REPORTS_API + 'Customer/GetCustomerVisitTimeReportTableData';
export const REPORT_CUSTOMER_VISIT_TIME_LIST = envConfig.REPORTS_API + 'Customer/GetCustomerVisitTimeList';
// Location
export const REPORT_LOCATION_TOP_LOCATION_GET_TABLE = envConfig.REPORTS_API + 'Location/GetTopLocationReportTableData/1';
export const REPORT_LOCATION_TOP_VISITORS_GET_TABLE = envConfig.REPORTS_API + 'Location/GetTopVisitorsReportTableData/1';
export const REPORT_LOCATION_SUMMARY_GET_TABLE = envConfig.REPORTS_API + 'Location/GetSummaryReportTableData/1';

// Report Schedule
export const REPORT_SCHEDULE_GET_TABLE = envConfig.REPORTS_API + 'ReportSchedule/GetTableData';
export const REPORT_SCHEDULE_GET_ALL = envConfig.REPORTS_API + 'ReportSchedule/GetAll';
export const REPORT_SCHEDULE_GET_BY_ID = envConfig.REPORTS_API + 'ReportSchedule/GetById';
export const REPORT_SCHEDULE_PERSIST = envConfig.REPORTS_API + 'ReportSchedule/Persist';
export const REPORT_SCHEDULE_DELETE = envConfig.REPORTS_API + 'ReportSchedule/Delete';
export const REPORT_SCHEDULE_REPORT_TYPE_LIST = envConfig.REPORTS_API + 'ReportSchedule/GetReportTypeList';
export const REPORT_SCHEDULE_SCHEDULE_TYPE_LIST = envConfig.REPORTS_API + 'ReportSchedule/GetScheduleTypeList';

// ---------- ANALYTICS ----------
// Common
export const ANALYTICS_GET_ANALYTICS_DASHBOARD_DATE_FILTER_ITEM_LIST = envConfig.ANALYTICS_API + 'Common/GetAnalyticsDashboardDateFilterItemList';
// Overview
export const AO_GET_REGISTERED_CONNECTED_INFO = envConfig.ANALYTICS_API + 'Overview/GetRegisteredConnectedInfo/';
export const AO_GET_REGISTERED_NOTCONNECTED_INFO = envConfig.ANALYTICS_API + 'Overview/GetRegisteredNotConnectedInfo/';
export const AO_GET_NEW_REGISTRATIONS_INFO = envConfig.ANALYTICS_API + 'Overview/GetNewRegistrationsInfo/';
export const AO_GET_ANONYMOUS_INFO = envConfig.ANALYTICS_API + 'Overview/GetAnonymousInfo/';
export const AO_GET_VISUAL_MERCHANDISING_INFO = envConfig.ANALYTICS_API + 'Overview/GetVisualMerchandisingInfo/';
export const AO_GET_UNIQUE_RETURNING_INFO = envConfig.ANALYTICS_API + 'Overview/GetUniqueReturningInfo/';
export const AO_GET_PASSBY_TRAFFIC_VS_INSIDE_TRAFFIC_DATA = envConfig.ANALYTICS_API + 'Overview/GetPassbyTrafficVsInsideTrafficData/';
export const AO_GET_PASSING_TRAFFIC_DATA = envConfig.ANALYTICS_API + 'Overview/GetPassingTrafficData/';
export const AO_GET_BOUNCE_CUSTOMERS_INFO = envConfig.ANALYTICS_API + 'Overview/GetBounceCustomersInfo/';
export const AO_GET_AVERAGE_TIMESPENT_INFO = envConfig.ANALYTICS_API + 'Overview/GetAverageTimeSpentInfo/';
export const AO_GET_RECENCY_FREQUENCY_DATA = envConfig.ANALYTICS_API + 'Overview/GetRecencyFrequencyData/';
// Customer
export const AC_GET_CUSTOMER_VIEW_DATA = envConfig.ANALYTICS_API + 'Customer/GetCustomerViewData/';
export const AC_GET_CUSTOMER_DETAIL_BY_DEVICE_MAC_ADDRESS = envConfig.ANALYTICS_API + 'Customer/GetCustomerDetailByDeviceMacAddress/';
export const AC_GET_CUSTOMER_ACTIVITY_BY_DEVICE_MAC_ADDRESS = envConfig.ANALYTICS_API + 'Customer/GetCustomerActivityByDeviceMacAddress/';
export const AC_GET_CUSTOMER_NOTIFICATION_BY_DEVICE_MAC_ADDRESS = envConfig.ANALYTICS_API + 'Customer/GetCustomerNotificationByDeviceMacAddress/';
// Zone
export const AZ_GET_OVERVIEW_DATA = envConfig.ANALYTICS_API + 'Zone/GetOverviewData/';
export const AZ_GET_ANALYTICS_DATA = envConfig.ANALYTICS_API + 'Zone/GetAnalyticsData/';

// ---------- ACTION & ALERTS ----------
export const GET_ACTION_ALERT_TABLE_DATA = envConfig.ACTION_ALERT_API + 'GetTableData'; //Action Alert Table Data
export const ACTION_ALERT_DELETE = envConfig.ACTION_ALERT_API + 'Delete'; //Action Alert Delete
export const GET_CONDITION_LIST = envConfig.ACTION_ALERT_API + 'GetConditionList'; //Condition List  
export const GET_TASK_LIST = envConfig.ACTION_ALERT_API + 'GetTaskList';
export const UPDATE_ACTION_ALERT = envConfig.ACTION_ALERT_API + 'Persist?IsAdd=false'; //Update action alert List
export const CREATE_ACTION_ALERT = envConfig.ACTION_ALERT_API + 'Persist?IsAdd=true'; //Create action alert List
export const GET_ACTION_BY_ID = envConfig.ACTION_ALERT_API + 'GetById'; //Task List

// ---------- MARKETINGS ----------
// Survey
export const SURVEY_PERSIST = envConfig.MARKETING_API + 'Survey/Persist'; //Survey Add / Update
export const SURVEY_DELETE = envConfig.MARKETING_API + 'Survey/Delete'; //Survey Delete
export const GET_SURVEY_BY_ID = envConfig.MARKETING_API + 'Survey/GetById'; //Survey Table Data
export const GET_SURVEY_TABLE_DATA = envConfig.MARKETING_API + 'Survey/GetTableData'; //Survey Table Data
export const GET_SURVEY_QUESTION_TYPES = envConfig.MARKETING_API + 'Survey/GetSurveyQuestionTypeList'; //Survey Table Data
// Campaign
export const GET_CAMPAIGN_TABLE_DATA = envConfig.MARKETING_API + 'Campaign/GetTableData'; //Campaign Table Data
export const CAMPAIGN_ADD = envConfig.MARKETING_API + 'Campaign/Persist?IsAdd=true'; //Campaign Add
export const CAMPAIGN_UPDATE = envConfig.MARKETING_API + 'Campaign/Persist?IsAdd=false'; //Campaign Update
export const CAMPAIGN_DELETE = envConfig.MARKETING_API + 'Campaign/Delete'; //Campaign Delete
export const GET_CAMPAIGN_BY_ID = envConfig.MARKETING_API + 'Campaign/GetById'; //Campaign Update

// ---------- ADMINISTRATION ----------
// # Configuration #
// Country
export const ADMIN_CONFIG_COUNTRY_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Country/GetTableData';
export const ADMIN_CONFIG_COUNTRY_GET_ALL = envConfig.ADMIN_API + 'Configuration/Country/GetAll';
export const ADMIN_CONFIG_COUNTRY_PERSIST = envConfig.ADMIN_API + 'Configuration/Country/Persist';
export const ADMIN_CONFIG_COUNTRY_BY_ID = envConfig.ADMIN_API + 'Configuration/Country/GetById';
export const ADMIN_CONFIG_COUNTRY_DELETE = envConfig.ADMIN_API + 'Configuration/Country/Delete';
// province
export const ADMIN_CONFIG_PROVINCE_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Province/GetTableData';
export const ADMIN_CONFIG_PROVINCE_GET_ALL = envConfig.ADMIN_API + 'Configuration/Province/GetAll';
export const ADMIN_CONFIG_PROVINCE_PERSIST = envConfig.ADMIN_API + 'Configuration/Province/Persist';
export const ADMIN_CONFIG_PROVINCE_BY_ID = envConfig.ADMIN_API + 'Configuration/Province/GetById';
export const ADMIN_CONFIG_PROVINCE_DELETE = envConfig.ADMIN_API + 'Configuration/Province/Delete';
// Concept
export const ADMIN_CONFIG_CONCEPT_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Concept/GetTableData';
export const ADMIN_CONFIG_CONCEPT_GET_ALL = envConfig.ADMIN_API + 'Configuration/Concept/GetAll';
export const ADMIN_CONFIG_CONCEPT_PERSIST = envConfig.ADMIN_API + 'Configuration/Concept/Persist';
export const ADMIN_CONFIG_CONCEPT_BY_ID = envConfig.ADMIN_API + 'Configuration/Concept/GetById';
export const ADMIN_CONFIG_CONCEPT_DELETE = envConfig.ADMIN_API + 'Configuration/Concept/Delete';
// Location
export const ADMIN_CONFIG_LOCATION_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Location/GetTableData';
export const ADMIN_CONFIG_LOCATION_GET_ALL = envConfig.ADMIN_API + 'Configuration/Location/GetAll';
export const ADMIN_CONFIG_LOCATION_PERSIST = envConfig.ADMIN_API + 'Configuration/Location/Persist';
export const ADMIN_CONFIG_LOCATION_BY_ID = envConfig.ADMIN_API + 'Configuration/Location/GetById';
export const ADMIN_CONFIG_LOCATION_DELETE = envConfig.ADMIN_API + 'Configuration/Location/Delete';
export const ADMIN_CONFIG_LOCATION_TYPE_LIST = envConfig.ADMIN_API + 'Configuration/Location/GetLocationTypeList';
export const ADMIN_CONFIG_LOCATION_TYPE_BY_ID = envConfig.ADMIN_API + 'Configuration/Location/GetLocationTypeById';
// Zone
export const ADMIN_CONFIG_ZONE_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Zone/GetTableData';
export const ADMIN_CONFIG_ZONE_GET_ALL = envConfig.ADMIN_API + 'Configuration/Zone/GetAll';
export const ADMIN_CONFIG_ZONE_PERSIST = envConfig.ADMIN_API + 'Configuration/Zone/Persist';
export const ADMIN_CONFIG_ZONE_BY_ID = envConfig.ADMIN_API + 'Configuration/Zone/GetById';
export const ADMIN_CONFIG_ZONE_DELETE = envConfig.ADMIN_API + 'Configuration/Zone/Delete';
// Device
export const ADMIN_CONFIG_DEVICE_GET_TABLE = envConfig.ADMIN_API + 'Configuration/Device/GetTableData';
export const ADMIN_CONFIG_DEVICE_GET_ALL = envConfig.ADMIN_API + 'Configuration/Device/GetAll';
export const ADMIN_CONFIG_DEVICE_PERSIST = envConfig.ADMIN_API + 'Configuration/Device/Persist';
export const ADMIN_CONFIG_DEVICE_BY_ID = envConfig.ADMIN_API + 'Configuration/Device/GetById';
export const ADMIN_CONFIG_DEVICE_DELETE = envConfig.ADMIN_API + 'Configuration/Device/Delete';
export const ADMIN_CONFIG_DEVICE_TYPE_LIST = envConfig.ADMIN_API + 'Configuration/Device/GetDeviceTypeList';
export const ADMIN_CONFIG_DEVICE_TYPE_BY_ID = envConfig.ADMIN_API + 'Configuration/Device/GetDeviceTypeById';
export const ADMIN_CONFIG_DEVICE_ACCESS_POINT_MODEL_LIST = envConfig.ADMIN_API + 'Configuration/Device/GetAccessPointModelList';
export const ADMIN_CONFIG_DEVICE_ACCESS_POINT_MODEL_BY_ID = envConfig.ADMIN_API + 'Configuration/Device/GetAccessPointModelById';

// Manage Account
export const ADMIN_CUSTOMER_DELETE = envConfig.ADMINISTRATION_API + 'ManageAccount/DeleteCustomer';
export const ADMIN_CUSTOMER_BLOCK_LIST_GET_TABLE = envConfig.ADMINISTRATION_API + 'ManageAccount/GetCustomerBlackListTableData';
export const ADMIN_CUSTOMER_BLOCK_LIST_TYPE_LIST = envConfig.ADMINISTRATION_API + 'ManageAccount/GetCustomerBlackListTypeList';
export const ADMIN_CUSTOMER_BLOCK_LIST_PERSIST = envConfig.ADMINISTRATION_API + 'ManageAccount/PersistCustomerBlackList';
export const ADMIN_CUSTOMER_BLOCK_LIST_DELETE = envConfig.ADMINISTRATION_API + 'ManageAccount/DeleteCustomerBlackList';
export const ADMIN_CUSTOMER_BLOCK_LIST_IMPORT = envConfig.ADMINISTRATION_API + 'ManageAccount/ImportCustomerBlackListCsv';
export const ADMIN_CUSTOMER_CUSTOM_CRITERIA_GET_AUTO_BLOCK = envConfig.ADMINISTRATION_API + 'ManageAccount/GetAutoBlockCriteria';
export const ADMIN_CUSTOMER_CUSTOM_CRITERIA_SET_AUTO_BLOCK = envConfig.ADMINISTRATION_API + 'ManageAccount/SetAutoBlockCriteria';

// License Management
export const ADMIN_LICENSE_SYSTEM_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetSystemAddress';
export const ADMIN_LICENSE_SENSOR_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetSensorLicenseCount';
export const ADMIN_LICENSE_ACCESSPOINT_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetAccessPointLicenseCount';
export const ADMIN_LICENSE_ONBOARDING_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetOnboardingLicense'; 
export const ADMIN_LICENSE_ONBOARDING_STATUS = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetOnboardingLicenseStatus';
export const ADMIN_LICENSE_ONBOARDING_UPDATE = envConfig.ADMINISTRATION_API + 'LicenseManagement/UpdateOnboardingLicense';
export const ADMIN_LICENSE_ANALYTICS_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetAnalyticsLicense'; 
export const ADMIN_LICENSE_ANALYTICS_STATUS = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetAnalyticsLicenseStatus';
export const ADMIN_LICENSE_ANALYTICS_UPDATE = envConfig.ADMINISTRATION_API + 'LicenseManagement/UpdateAnalyticsLicense';
export const ADMIN_LICENSE_MARKETING_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetMarketingLicense'; 
export const ADMIN_LICENSE_MARKETING_STATUS = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetMarketingLicenseStatus';
export const ADMIN_LICENSE_MARKETING_UPDATE = envConfig.ADMINISTRATION_API + 'LicenseManagement/UpdateMarketingLicense';
export const ADMIN_LICENSE_THIRD_PARTY_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetThirdPartyIntegrationLicense'; 
export const ADMIN_LICENSE_THIRD_PARTY_STATUS = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetThirdPartyIntegrationLicenseStatus';
export const ADMIN_LICENSE_THIRD_PARTY_UPDATE = envConfig.ADMINISTRATION_API + 'LicenseManagement/UpdateThirdPartyIntegrationLicense';
export const ADMIN_LICENSE_LOCATION_GET = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetLocationLicense'; 
export const ADMIN_LICENSE_LOCATION_STATUS = envConfig.ADMINISTRATION_API + 'LicenseManagement/GetLocationLicenseStatus';
export const ADMIN_LICENSE_LOCATION_UPDATE = envConfig.ADMINISTRATION_API + 'LicenseManagement/UpdateLocationLicense';

// User Account Management
export const ADMIN_USER_ACCOUNT_GET_ALL = envConfig.ADMINISTRATION_API + 'Users/UserInfo/GetAll';
export const ADMIN_USER_ACCOUNT_GET_TABLE = envConfig.ADMINISTRATION_API + 'Users/UserInfo/GetTableData';
export const ADMIN_USER_ACCOUNT_BY_ID = envConfig.ADMINISTRATION_API + 'Users/UserInfo/GetById';
export const ADMIN_USER_ACCOUNT_PERSIST = envConfig.ADMINISTRATION_API + 'Users/UserInfo/Persist';
export const ADMIN_USER_ACCOUNT_DELETE = envConfig.ADMINISTRATION_API + 'Users/UserInfo/Delete';
export const ADMIN_USER_ACCOUNT_CHANGE_PASSWORD = envConfig.ADMINISTRATION_API + 'Users/UserInfo/ChangePassword';
export const ADMIN_USER_ACCOUNT_AUTH_TYPE_LIST = envConfig.ADMINISTRATION_API + 'Users/UserInfo/GetAuthenticationTypeList';
export const ADMIN_USER_ACCOUNT_TIME_ZONE_LIST = envConfig.ADMINISTRATION_API + 'Users/UserInfo/GetTimeZoneList';

export const ADMIN_USER_GROUP_GET_ALL = envConfig.ADMINISTRATION_API + 'Users/UserGroup/GetAll';
export const ADMIN_USER_GROUP_GET_TABLE = envConfig.ADMINISTRATION_API + 'Users/UserGroup/GetTableData';
export const ADMIN_USER_GROUP_BY_ID = envConfig.ADMINISTRATION_API + 'Users/UserGroup/GetById';
export const ADMIN_USER_GROUP_PERSIST = envConfig.ADMINISTRATION_API + 'Users/UserGroup/Persist';
export const ADMIN_USER_GROUP_DELETE = envConfig.ADMINISTRATION_API + 'Users/UserGroup/Delete';

export const ADMIN_USER_GROUP_PERMISSION_GET = envConfig.ADMINISTRATION_API + 'Users/UserGroupPermission/GetPermissionByUserGroupId';
export const ADMIN_USER_GROUP_PERMISSION_SET = envConfig.ADMINISTRATION_API + 'Users/UserGroupPermission/SetPermission';

// Visualize
export const BANNER_CATEGORY_GET_ALL = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategory/GetAll';
export const BANNER_CATEGORY_BY_ID = envConfig.ADMINISTRATION_API + 'Visualize/Banner/GetByBannerCategoryId';
export const BANNER_CATEGORY_PERSIST = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategory/Persist';
export const BANNER_CATEGORY_DELETE = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategory/Delete';
export const BANNER_DELETE = envConfig.ADMINISTRATION_API + 'Visualize/Banner/Delete';
export const BANNER_CATEGORY_LOCATION_LIST = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategoryLocation/GetLocationList';
export const BANNER_IMAGE_UPLOAD = envConfig.ADMINISTRATION_API + 'Visualize/Banner/Persist';
export const BANNER_UPDATE_INDEX = envConfig.ADMINISTRATION_API + 'Visualize/Banner/BulkUpdateBannerIndex';
export const BANNER_CATEGORY_LOCATION = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategoryLocation/GetLocationList';
export const BANNER_CATEGORY_LOCATION_ASSIGN = envConfig.ADMINISTRATION_API + 'Visualize/BannerCategoryLocation/AssignBannerCategory';

export const ADMIN_VISUALIZE_BACKGROUND_GET_ALL = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/GetAll';
export const ADMIN_VISUALIZE_BACKGROUND_GET_TABLE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/GetTableData';
export const ADMIN_VISUALIZE_BACKGROUND_BY_ID = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/GetById';
export const ADMIN_VISUALIZE_BACKGROUND_PERSIST = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/Persist';
export const ADMIN_VISUALIZE_BACKGROUND_DELETE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/Delete';
export const ADMIN_VISUALIZE_BACKGROUND_GET_PAGE_CODE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/GetPageCodeList';
export const ADMIN_VISUALIZE_BACKGROUND_GET_TYPE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImage/GetBackgroundTypeList';

export const ADMIN_VISUALIZE_BACKGROUND_LOCATION_GET_TABLE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImageLocation/GetLocationTableData';
export const ADMIN_VISUALIZE_BACKGROUND_LOCATION_PERSIST = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImageLocation/Persist';
export const ADMIN_VISUALIZE_BACKGROUND_LOCATION_DELETE = envConfig.ADMINISTRATION_API + 'Visualize/BackgroundImageLocation/DeleteByBackgroundImageIdAndLocationId';

// ---------- COMMON API ----------
// File Upload Schedule
export const COMMON_FILE_UPLOAD_SCHEDULE_UPLOAD_FILES = envConfig.COMMON_API + 'FileUploadSchedule/UploadFiles';
// User Profile
export const COMMON_USER_PROFILE_GET_USER_PROFILE_INFO = envConfig.COMMON_API + 'UserProfile/GetUserProfileInfo';
export const COMMON_USER_PROFILE_CHANGE_PASSWORD = envConfig.COMMON_API + 'UserProfile/ChangePassword';
export const COMMON_USER_PROFILE_GET_USER_GROUP_MODULE_PERMISSIONS = envConfig.COMMON_API + 'UserProfile/GetUserGroupModulePermissions';