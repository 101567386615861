import React from 'react';
import { withSnackbar } from 'notistack';

var useSnackbarRef = null
const setUseSnackbarRef = (props) => {
    useSnackbarRef = props
}

class InnerCommonSnackbarConfig extends React.Component {
    componentDidMount(){ this.props.setUseSnackbarRef(this.props) }
    render() { return null }
}


const ExportICSC = withSnackbar(InnerCommonSnackbarConfig)

export const CommonSnackbarConfig = () => {
    return <ExportICSC setUseSnackbarRef={setUseSnackbarRef} />
}

export default {
    success(msg) { this.toast(msg, 'success') },
    warning(msg) { this.toast(msg, 'warning') },
    info(msg) { this.toast(msg, 'info') },
    error(msg) { this.toast(msg, 'error') },
    toast(msg, variant = 'default') { useSnackbarRef.enqueueSnackbar(msg, { variant }) },
    closeAll() { useSnackbarRef.closeSnackbar() },
}