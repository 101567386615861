import React from "react";
import { Row, Col, Button, Tab, Tabs, Table } from "react-bootstrap";
// _includes
import { common } from "_helpers";
import Filter from "../includes/Filter";
// API Services
import { apiServices } from "_api";
// css
import styles from "../css/Analytics.module.css";

class ZoneOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      ovData: [],
    };
  }

  overviewData = () => {
    const { filter, filterDate } = this.filter.state;
    var data = {
      url: "AZ_GET_OVERVIEW_DATA",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // set data
          this.setState({
            status: "success",
            ovData: response,
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          ovData: [],
        });
      },
      (final) => {}
    );
  };

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState(
      {
        status: "process",
      },
      () => {
        this.overviewData();
      }
    );
  }

  filterReset() {
    this.setState(
      {
        status: "process",
      },
      () => {
        this.overviewData();
      }
    );
  }

  render() {
    const { status, ovData } = this.state;
    return (
      <div className={`${styles.azOv}`}>
        {status == "process" ? (
          <p className={`${styles.azLoading}`}>Loading data please wait...</p>
        ) : (
          <div>
            <div className={`${styles.azOvBg}`}>
              <div className={`row`}>
                <div className="col-sm-3">
                  <ul className={`${styles.azOvList}`}>
                    <li>
                      <div className="row">
                        <div className="col-sm-3 align-self-center">
                          <img src={common.appIcon("az_ov_average_vist")} />
                        </div>
                        <div className="col-sm-9">
                          <p>Average Visit Duration</p>
                          <h1>
                            {" "}
                            {ovData.Summary.AverageVisitDuration.DurationMin}
                            <span className={`${styles.azOvMin}`}>
                              min
                            </span>{" "}
                            <span
                              className={`${
                                ovData.Summary.AverageVisitDuration
                                  .ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {
                                ovData.Summary.AverageVisitDuration
                                  .ComparisonPercentage
                              }
                              %
                            </span>
                          </h1>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-sm-3 align-self-center">
                          <img src={common.appIcon("az_ov_new_visitor")} />
                        </div>
                        <div className="col-sm-9">
                          <p>New Visitors</p>
                          <h1>
                            {" "}
                            {ovData.Summary.NewVisitors.Percentage}
                            <span className={`${styles.azOvMin}`}>
                              min
                            </span>{" "}
                            <span
                              className={`${
                                ovData.Summary.NewVisitors
                                  .ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.Summary.NewVisitors.ComparisonPercentage}%
                            </span>
                          </h1>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-sm-3 align-self-center">
                          <img src={common.appIcon("az_ov_repeat_visitor")} />
                        </div>
                        <div className="col-sm-9">
                          <p>Repeat Visitors</p>
                          <h1>
                            {" "}
                            {ovData.Summary.RepeatedVisitors.Percentage}
                            <span className={`${styles.azOvMin}`}>
                              min
                            </span>{" "}
                            <span
                              className={`${
                                ovData.Summary.RepeatedVisitors
                                  .ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {
                                ovData.Summary.RepeatedVisitors
                                  .ComparisonPercentage
                              }
                              %
                            </span>
                          </h1>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-sm-3 align-self-center">
                          <img
                            src={common.appIcon("az_ov_indentified_visitor")}
                          />
                        </div>
                        <div className="col-sm-9">
                          <p>Identified Visitors</p>
                          <h1>
                            {" "}
                            {ovData.Summary.IdentifiedVisitors.Percentage}
                            <span className={`${styles.azOvMin}`}>
                              min
                            </span>{" "}
                            <span
                              className={`${
                                ovData.Summary.IdentifiedVisitors
                                  .ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {
                                ovData.Summary.IdentifiedVisitors
                                  .ComparisonPercentage
                              }
                              %
                            </span>
                          </h1>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.azOvDraw} col-sm-9`}>
                  <div className="row no-gutters">
                    <div className="col-3"></div>
                    <div className="col-7">
                      <div
                        className={`${styles.azOvBlueBorder} ${styles.azOvSecondBlue}`}
                      ></div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                  <div className={`${styles.azOvMt11} row`}>
                    <div className="col-5">
                      <div className={`${styles.azOvWhiteBg}`}>
                        <div className="row">
                          <div className="col-3">
                            <p className={`${styles.azOvP}`}>Visits</p>
                            <h2 className={`${styles.azOvH}`}>
                              {ovData.Visits.Count}
                            </h2>
                            <span
                              className={`${
                                ovData.Visits.ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.Visits.ComparisonPercentage}%
                            </span>
                          </div>
                          <div
                            className={`${styles.azOvBt} col-6 align-items-center`}
                          >
                            <div className={`${styles.azOvGreenBorder}`}>
                              <img
                                src={common.appIcon("az_ov_visitor")}
                                className={`${styles.azOvImgBorder}`}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <p className={`${styles.azOvP}`}>Visitors</p>
                            <h2 className={`${styles.azOvH}`}>
                              {ovData.Visitors.Count}
                            </h2>
                            <span
                              className={`${
                                ovData.Visitors.ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.Visitors.ComparisonPercentage}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={`poc-mt-80`}>
                        <div className="row">
                          <div className="col-3 poc-tar">
                            <p className={`${styles.azOvP}`}>Left</p>
                            <h2 className={`${styles.azOvH}`}>
                              {ovData.Left.Count}
                            </h2>
                            <span
                              className={`${
                                ovData.Left.ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.Left.ComparisonPercentage}%
                            </span>
                          </div>
                          <div className="col-6">
                            <div className={`${styles.azOvBtg}`}>
                              <div className={`${styles.azOvBgEm}`}></div>
                              <div className={`${styles.azOvBgEmCont}`}>
                                <p className={`${styles.azOvP}`}>Straight</p>
                                <h2 className={`${styles.azOvH}`}>
                                  {ovData.Straight.Count}
                                </h2>
                                <span
                                  className={`${
                                    ovData.Straight.ComparisonPercentage > 0
                                      ? styles.azOvUp
                                      : styles.azOvDown
                                  }`}
                                >
                                  {ovData.Straight.ComparisonPercentage}%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <p className={`${styles.azOvP}`}>Right</p>
                            <h2 className={`${styles.azOvH}`}>
                              {ovData.Right.Count}
                            </h2>
                            <span
                              className={`${
                                ovData.Right.ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.Right.ComparisonPercentage}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.azOvVertRedBr} col-3`}>
                      <div className={`${styles.azOvRedBorderHr}`}></div>
                      <div className={`${styles.azOvMt49}`}>
                        <div className="row">
                          <div className="col-6 poc-tar">
                            <p className={`${styles.azOvP}`}>Bounced Visits</p>
                            <h2 className={`${styles.azOvH}`}>
                              {ovData.BouncedVisits.Percentage}%
                            </h2>
                            <span
                              className={`${
                                ovData.BouncedVisits.ComparisonPercentage > 0
                                  ? styles.azOvUp
                                  : styles.azOvDown
                              }`}
                            >
                              {ovData.BouncedVisits.ComparisonPercentage}%
                            </span>
                          </div>
                          <div className="col-6">
                            <div
                              className={`${styles.azOvGreenBorder} ${styles.azOvEngageGreenBorder} ${styles.azOvRedBorder} ml-0`}
                            >
                              <img
                                src={common.appIcon("az_ov_bounced_visits")}
                                className={`${styles.azOvImgBorder}`}
                              />
                            </div>
                            {/* <h6 className={`${styles.azOvMinRed}`}> 8 min</h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.azOvVertBlueBr} col-4`}>
                      <div className="row">
                        <div className="col-sm-4"></div>
                        <div className={`${styles.azOvBlBord} col-sm`}></div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div
                            className={`${styles.azOvGreenBorder} ${styles.azOvEngageGreenBorder} ml-0`}
                          >
                            <img
                              src={common.appIcon("az_ov_engaged_visit")}
                              className={`${styles.azOvImgBorder}`}
                            />
                          </div>
                          {/* <h6 className={`${styles.azOvMinBlue}`}> 20 min</h6> */}
                        </div>
                        <div className="col-sm">
                          <p className={`${styles.azOvP}`}>Engaged Visitors</p>
                          <h2 className={`${styles.azOvH}`}>
                            {ovData.EngagedVisits.Percentage}%
                          </h2>
                          <span
                            className={`${
                              ovData.EngagedVisits.ComparisonPercentage > 0
                                ? styles.azOvUp
                                : styles.azOvDown
                            }`}
                          >
                            {ovData.EngagedVisits.ComparisonPercentage}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.azOvBotton}`}></div>
            <div className={`${styles.azOvBottonSec} row`}>
              <div className="col-sm-3">
                <div className={`${styles.azOvImg} ${styles.azOvBorderBrown}`}>
                  <img src={common.appIcon("az_ov_visitors")} />
                </div>
                <div className="row">
                  <div className="col-sm poc-tar">
                    <p className={`${styles.azOvP}`}>Potential Visits</p>
                    <h2 className={`${styles.azOvH}`}>
                      {ovData.OutsidePotentialsVisits.Count}
                    </h2>
                    <span
                      className={`${
                        ovData.OutsidePotentialsVisits.ComparisonPercentage > 0
                          ? styles.azOvUp
                          : styles.azOvDown
                      }`}
                    >
                      {ovData.OutsidePotentialsVisits.ComparisonPercentage}%
                    </span>
                  </div>
                  <div className="col-sm">
                    <p className={`${styles.azOvP}`}>Potential Visitors</p>
                    <h2 className={`${styles.azOvH}`}>
                      {ovData.OutsidePotentialVisitors.Count}
                    </h2>
                    <span
                      className={`${
                        ovData.OutsidePotentialVisitors.ComparisonPercentage > 0
                          ? styles.azOvUp
                          : styles.azOvDown
                      }`}
                    >
                      {ovData.OutsidePotentialVisitors.ComparisonPercentage}%
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${styles.azOvBr} col-sm text-center`}>
                <div
                  className={`${styles.azOvImg} ${styles.azOvBorderBrown} ${styles.azOvTwoP}`}
                >
                  <img src={common.appIcon("az_ov_visitor")} />
                </div>
                <p className={`${styles.azOvP}`}>Potential Visitors</p>
                <h2 className={`${styles.azOvH}`}>
                  {ovData.PotentialVisitors.Percentage}
                </h2>
                <span
                  className={`${
                    ovData.PotentialVisitors.ComparisonPercentage > 0
                      ? styles.azOvUp
                      : styles.azOvDown
                  }`}
                >
                  {ovData.PotentialVisitors.ComparisonPercentage}%
                </span>
              </div>
              <div className="col-sm-2"></div>
              <div className="col-sm-3 text-center">
                <div
                  className={`${styles.azOvImg} ${styles.azOvBorderGray} ${styles.azOvThP}`}
                >
                  <img src={common.appIcon("az_ov_missed_visits")} />
                </div>
                <div className="row">
                  <div className="col-sm poc-tar">
                    <p className={`${styles.azOvP}`}>Missed Visits</p>
                    <h2 className={`${styles.azOvH}`}>
                      {ovData.MissedVisits.Count}
                    </h2>
                    <span
                      className={`${
                        ovData.MissedVisits.ComparisonPercentage > 0
                          ? styles.azOvUp
                          : styles.azOvDown
                      }`}
                    >
                      {ovData.MissedVisits.ComparisonPercentage}%
                    </span>
                  </div>
                  <div className="col-sm">
                    <p className={`${styles.azOvP}`}>Missed Visitors</p>
                    <h2 className={`${styles.azOvH}`}>
                      {ovData.MissedVisitors.Count}
                    </h2>
                    <span
                      className={`${
                        ovData.MissedVisitors.ComparisonPercentage > 0
                          ? styles.azOvUp
                          : styles.azOvDown
                      }`}
                    >
                      {ovData.MissedVisitors.ComparisonPercentage}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Filter
          type="zoneOverview"
          filterInit={() => {
            this.overviewData();
          }}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export default ZoneOverview;
