import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
// switch
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";
// css
import styles from "../css/Marketing.module.css";
class MarketingSurveyCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Survey",
      parent: "/marketing/survey",
      status: "process",
      isLoading: false,
      fields: {
        SurveyName: "",
        Description: "",
        SurveyQuestionList: [],
      },
      errors: [],
      questionType: "",
      questionTypesList: [],
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getAllQuestionTypes();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "GET_SURVEY_BY_ID",
      query: "/" + params.id,
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status==200) {
          let result = response.data;
          this.setState(
            {
              fields: {
                SurveyName: result.SurveyName,
                Description: result.Description,
                SurveyQuestionList: result.SurveyQuestionList,
              },
            },
            () => {
              this.getAllQuestionTypes();
            }
          );
        } else {
          this.setState({
            status:"error"
          })
        }
      },
      (error) => {
        this.setState({
          status:"error"
        })
      },
      (final) => {}
    );
  };

  getAllQuestionTypes() {
    var data = {
      url: "GET_SURVEY_QUESTION_TYPES",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status==200) {
          this.setState({
            status: "success",
            questionTypesList: response.data,
          });
        } else {
          this.setState({
            status:"error"
          })
        }
      },
      (error) => {
        this.setState({
          status:"error"
        })
      },
      (final) => {
      }
    );
  }

  getQuestionType(Id) {
    const { questionTypesList } = this.state;
    const result = questionTypesList.map((item) => {
      if (item.Id == Id) {
        return item.Value;
      }
    });
    return result;
  }

  validateCustom() {
    const { fields } = this.state;
    let errors = [];
    if (fields.SurveyName == "") {
      errors.push("Survey name is required");
    } else if (fields.SurveyQuestionList.length == 0) {
      errors.push("Please add atleast one question");
    } else {
      fields.SurveyQuestionList.map((item, key) => {
        let qus = key;
        ++qus;
        item.QuestionIndex = qus;
        if (item.QuestionText == "") {
          errors.push("Please add Question Name for Question " + qus);
        }
        if(item.QuestionTypeId!=4){
          item.SurveyQuestionAnswerList.map((cItem, cKey) => {
            if (cItem.AnswerText == "") {
              errors.push("Please fill all the answers in Question " + qus);
            }
          });
        }
      });
    }
    if (errors.length > 0) {
      this.setState({
        errors,
      });
      common.snack("E", errors[0]);
      return false;
    } else {
      return true;
    }
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }

    this.setState({
      fields: fields,
      formSubmit: false,
    });
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }
    this.setState({ fields: fields });
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    let storeIds = [];
    data.map((item, key) => {
      storeIds.push(item.value);
    });
    fields[name] = storeIds;
    this.setState({
      fields,
    });
  }

  handleSwitchChange = (checked, name) => {
    const { fields } = this.state;
    fields[name] = checked;
    this.setState({ fields: fields });
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateCustom();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var data = {};
      if (params.type == "add") {
        data = {
          url: "SURVEY_PERSIST",
          query: "?IsAdd=true",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      } else {
        fields.SurveyId = params.id;
        data = {
          url: "SURVEY_PERSIST",
          query: "?IsAdd=false",
          body: fields,
          contentType: 1,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
    this.setState({
      isLoading: false,
    });
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // question answer fields
  generateQuestion(typeId) {
    const { fields } = this.state;
    let question = {
      QuestionTypeId: parseInt(typeId),
      QuestionText: "",
      QuestionIndex: 0,
      SurveyQuestionAnswerList: [
        {
          AnswerText: "",
        },
      ],
    };
    fields.SurveyQuestionList.push(question);
    this.setState({
      fields,
      error: [],
    });
  }

  removeQuestion(id) {
    const { fields } = this.state;
    let SurveyQuestionList = [];
    fields.SurveyQuestionList.map((item,key)=>{
      if(key!=id){
        SurveyQuestionList.push(item);
      }
    })
    fields.SurveyQuestionList = SurveyQuestionList;
    this.setState({
      fields,
      error: [],
    });
  }

  addAnswerInput(key) {
    const { fields } = this.state;
    fields.SurveyQuestionList[key].SurveyQuestionAnswerList.push({
      AnswerText: "",
    });
    this.setState({
      fields,
    });
  }

  removeAnswerInput(key) {
    const { fields } = this.state;
    let count =
      fields.SurveyQuestionList[key].SurveyQuestionAnswerList.length - 1;
    let SurveyQuestionAnswerList = [];
    fields.SurveyQuestionList[key].SurveyQuestionAnswerList.map((item, key) => {
      if (key < count) {
        SurveyQuestionAnswerList.push(item);
      }
    });

    fields.SurveyQuestionList[
      key
    ].SurveyQuestionAnswerList = SurveyQuestionAnswerList;

    this.setState({
      fields,
    });
  }

  handleQuestionSelect(val) {
    this.generateQuestion(val);
  }

  handleQuestionChange(e, key) {
    const { fields } = this.state;
    const { value } = e.target;
    fields.SurveyQuestionList[key].QuestionText = value;
    this.setState({
      fields,
      errors: [],
    });
  }

  handleAnswerChange(e, key, cKey) {
    const { fields } = this.state;
    const { value } = e.target;
    fields.SurveyQuestionList[key].SurveyQuestionAnswerList[
      cKey
    ].AnswerText = value;
    this.setState({
      fields,
      errors: [],
    });
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type=="add" && common.isAccess("SURVEYMARKETING_ADD")) && (allow=true);
    (params.type=="edit" && common.isAccess("SURVEYMARKETING_EDIT")) && (allow=true);
    if(!allow){
      common.accessDenied();
    }
  }
  
  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      questionType,
      questionTypesList,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Configuration</h1>
              <ul>
                <li>
                  <a href="/marketing/campaign">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add Campaign" : "Update Campaign"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add Campaign" : "Update Campaign"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    {formSubmit && errors.length > 0 && (
                      <Form.Group as={Row} className="mb-3">
                        <Col sm="3"></Col>
                        <Col sm="4">
                          <div className="alert alert-danger">
                            <strong>Required Error!</strong> {errors[0]}
                          </div>
                        </Col>
                        <Col sm="5"></Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Survey Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="SurveyName"
                          placeholder="Survey Name"
                          value={fields.SurveyName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    {/* All Question List */}
                    {fields.SurveyQuestionList.map((item, key) => {
                      let qus = key;
                      return (
                        <div key={key}>
                          <Form.Group as={Row} className="mb-3">
                            <Col sm="3"></Col>
                            <Col sm="4">
                              <Form.Label className="poc-tal">
                                Question {++qus}
                              </Form.Label>
                              {" - "}
                              <Form.Label
                                className={`${styles.msAddNewAnswer} poc-tal`}
                                onClick={() => this.removeQuestion(key)}
                              >
                               Remove
                              </Form.Label>
                            </Col>
                            <Col sm="5"></Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                              Question Type
                            </Form.Label>
                            <Form.Label column sm="4" className="poc-tal">
                              {this.getQuestionType(item.QuestionTypeId)}
                            </Form.Label>
                            <Col sm="5"></Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                              Question Name<span className="poc-color">*</span>
                            </Form.Label>
                            <Col sm="4">
                              <Form.Control
                                type="text"
                                name="QuestionName"
                                placeholder="Question Name"
                                value={item.QuestionText}
                                onChange={(e) =>
                                  this.handleQuestionChange(e, key)
                                }
                              />
                            </Col>
                            <Col sm="5"></Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                              Answers<span className="poc-color">*</span>
                            </Form.Label>
                            <Col sm="4" className={styles.msAnswerList}>
                              {item.SurveyQuestionAnswerList.map(
                                (cItem, cKey) => {
                                  return (
                                    <Form.Control
                                      key={cKey}
                                      type="text"
                                      name="Answer"
                                      placeholder="Answer"
                                      value={cItem.AnswerText}
                                      onChange={(e) =>
                                        this.handleAnswerChange(e, key, cKey)
                                      }
                                    />
                                  );
                                }
                              )}
                              <Form.Label
                                className={styles.msAddNewAnswer}
                                onClick={() => this.addAnswerInput(key)}
                              >
                                Add New
                              </Form.Label>
                              {item.SurveyQuestionAnswerList.length > 1 && (
                                <>
                                  {" - "}
                                  <Form.Label
                                    className={styles.msAddNewAnswer}
                                    onClick={() => this.removeAnswerInput(key)}
                                  >
                                    Remove
                                  </Form.Label>
                                </>
                              )}
                            </Col>
                            <Col sm="5"></Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-3">
                            <Col sm="2"></Col>
                            <Col sm="5" className="poc-border"></Col>
                            <Col sm="5"></Col>
                          </Form.Group>
                        </div>
                      );
                    })}

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="3"></Col>
                      <Form.Label column sm="4" className="poc-tal">
                        Add New Question
                      </Form.Label>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Question Type
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            this.handleQuestionSelect(e.target.value)
                          }
                          value={questionType}
                        >
                          <option value="">Select Type</option>
                          {questionTypesList.map((item, key) => {
                            return (
                              <option key={key} value={item.Id}>
                                {item.Value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading
                            ? "Processing..."
                            : params.type == "add"
                            ? "Save"
                            : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { MarketingSurveyCrud };
