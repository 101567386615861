import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
// _includes
import { StatusBar } from "_includes";
import { common } from "_helpers";
import { apiServices } from "_api";

export default class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        UserInfoId: "",
        NewPassword: "",
        ConfirmPassword: ""
      },
      errors: {
        NewPassword: {
          error: "",
          isReq: "Please enter the NewPassword",
        },
        ConfirmPassword: {
          error: "",
          isReq: "Please enter the passworg again",
          isMisMatch: "Password and cofirm password not same!"
        }
      },
    };
  }

  parentCall(id) {
    const { fields } = this.state;
    fields.UserInfoId = id;
    this.setState(
      {
        fields,
        isModal: true,
      },
    );
  }

  // Validation and Handler
  validateForm() {
    const { fields, errors, actorId } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isMisMatch) {
        if (fields["NewPassword"] != fields[key]) {
          errors[key].error = errors[key].isMisMatch;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isMisMatch) {
        if (fields["NewPassword"] != fields[key]) {
          errors[key].error = errors[key].isMisMatch;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({ formSubmit: true });
    let isValid = this.validateAll();
    if (isValid) {
      this.setState({ isLoading: true });
      const { fields } = this.state;
      var data = {
        url: "ADMIN_USER_ACCOUNT_CHANGE_PASSWORD",
        body: fields,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    common.snack("S", "Password has been updated");
    this.onComplete("success");
  }

  crudError() {
    common.snack("S", "There was an error while update a password");
    this.setState({
      isLoading: false,
    });
  }

  // Modal
  onComplete(status) {
    this.setState({
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        UserInfoId: "",
        NewPassword: "",
        ConfirmPassword: ""
      }
    })
    this.props.onComplete(status);
  }

  render() {
    const {
      status,
      isLoading,
      isModal,
      fields,
      errors,
      formSubmit,
    } = this.state;
    return (
      <Modal show={isModal} onHide={() => this.onComplete("cancel")}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="cropApp">
          <div>
            <Form className="poTableFilter">
              <label className="poLabel">Password {errors.NewPassword.error !== "" && formSubmit && (<> - <span className="error">{errors.NewPassword.error}</span></>)}</label>
              <Form.Control
                type="text"
                name="NewPassword"
                placeholder="NewPassword"
                value={fields.NewPassword}
                onChange={(e) => this.handleOnChange(e)}
                className="poc-br-0"
              />
              <label className="poLabel">Confirm Password {errors.ConfirmPassword.error !== "" && formSubmit && (<> - <span className="error">{errors.ConfirmPassword.error}</span></>)}</label>
              <Form.Control
                type="text"
                name="ConfirmPassword"
                placeholder="ConfirmPassword"
                value={fields.ConfirmPassword}
                onChange={(e) => this.handleOnChange(e)}
                className="poc-br-0"
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="poModalFooter">
          <Button
            className="config-btn"
            onClick={() => this.onSubmit()}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Submit"}
          </Button>
          <Button
            className="config-cancel"
            disabled={isLoading}
            onClick={() => this.onComplete("cancel")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}