import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import { CSVLink } from "react-csv";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";
import ImportCSV from "../includes/ImportCSV";
// css
import styles from "../css/Action.module.css";

class ActionAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 6,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        RuleName: "",
        Description: ""
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      crudAccess: {
        add: false,
        edit: false,
        delete: false,
        action: false
      }
    };
  }

  componentDidMount = async () => {
    if (!common.isAccess("ACTIONANDALERT_PAGEACCESS")) {
      common.accessDenied();
    } else {
      this.crudAccess();
      this.loadData();
    }
  };

  loadData = () => {
    const { paging, filter } = this.state;
    var data = {
      url: "GET_ACTION_ALERT_TABLE_DATA",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: {
        RuleName: filter.RuleName,
        Description: filter.Description
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          RuleName: "",
          Description: ""
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(id, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(id, type) {
    var data = {
      url: "ACTION_ALERT_DELETE",
    };

    if (type == "single") {
      data.query = "/" + id;
    } else {
      const { deleteIds } = this.state;
      data.query = "?ActionAlertIds=" + deleteIds.join("&ActionAlertIds=");
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  selectData(id) {
    let deleteIds = this.state.deleteIds;
    let isBulkDelete = false;
    if (deleteIds.includes(id)) {
      deleteIds = common.removeItem(deleteIds, id);
    } else {
      deleteIds.push(id);
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    this.setState({
      deleteIds,
      isBulkDelete,
    });
  }

  selectAll() {
    const { data, isSelectAll } = this.state;
    let deleteIds = [];
    let select = false;
    let isBulkDelete = false;
    if (!isSelectAll) {
      select = true;
      if (data.RecordsCount > 0) {
        data.result.map((item, key) => {
          let id = item.ActionAlertId;
          deleteIds.push(id);
        });
      }
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    this.setState({
      deleteIds,
      isSelectAll: select,
      isBulkDelete,
    });
  }

  // module & page access
  crudAccess() {
    const { crudAccess } = this.state;
    let columns = this.state.columns;
    (common.isAccess("ACTIONANDALERT_ADD") && (crudAccess.add = true));
    (common.isAccess("ACTIONANDALERT_EDIT") && (crudAccess.edit = true));
    (common.isAccess("ACTIONANDALERT_DELETE") && (crudAccess.delete = true));
    (crudAccess.edit || crudAccess.delete) ? (crudAccess.action = true) : --columns;
    (!crudAccess.delete) && --columns;
    this.setState({
      columns,
      crudAccess
    })
  }

  render() {
    const {
      status,
      data,
      columns,
      paging,
      filter,
      isLoading,
      isFilter,
      isBulkDelete,
      deleteIds,
      isSelectAll,
      crudAccess
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className=" poFlex col-sm-12">
              <h1>Action & Alerts</h1>
              <ul>
                <li>
                  <a href="/actionalert">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Action & Alerts
                      </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    <span className="potableHeadTitle">Rules List</span>
                    {crudAccess.add && (
                      <> - <Link to="/actionalert/add"><Button variant="primary config-btn">Add New Rule</Button></Link></>
                    )}
                  </div>
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="poTableIcon">
                        <OverlayTrigger
                          trigger="click"
                          placement="left"
                          rootClose={true}
                          overlay={
                            <Popover id="account-filter">
                              <Popover.Title as="h3">Filter List</Popover.Title>
                              <Popover.Content>
                                <Form className="poTableFilter">
                                  <Form.Control
                                    type="text"
                                    name="RuleName"
                                    placeholder="Rule Name"
                                    value={filter.RuleName}
                                    onChange={this.filterData}
                                  />
                                  <Form.Control
                                    type="text"
                                    name="Description"
                                    placeholder="Description"
                                    value={filter.Description}
                                    onChange={this.filterData}
                                  />
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                          onExit={() => this.handleFilter(false)}
                        >
                          {isFilter ? (
                            <img
                              src={common.appIcon("FilterSelect")}
                              className="poc-cp"
                              alt="Filter"
                              onClick={() => this.handleFilter(false)}
                            />
                          ) : (
                              <img
                                src={common.appIcon("FilterIcon")}
                                className="poc-cp"
                                alt="Filter"
                                onClick={() => this.handleFilter(true)}
                              />
                            )}
                        </OverlayTrigger>
                        <img
                          src={common.appIcon("RefreshIcon")}
                          className="poc-cp"
                          alt="Refresh"
                          onClick={() => this.handleRefresh()}
                        />
                        {crudAccess.delete && (
                          isBulkDelete ? (
                            <img
                              src={common.appIcon("DelAllActIcon")}
                              className="poc-cp"
                              alt="Delete"
                              onClick={() => this.deleteData(0, "bulk")}
                            />
                          ) : (
                              <img
                                src={common.appIcon("DelAllNoneIcon")}
                                className="poc-cp"
                                alt="Delete"
                              />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <span className="poTableFetcher">fetching data please wait...</span>
                  </div>
                )}

                <div className={isLoading ? "poTableOnLoad" : ""}>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Rule Name</th>
                        <th>Description</th>
                        <th>Email</th>
                        {crudAccess.action && (<th>Actions</th>)}
                        {crudAccess.delete && (
                          <th>
                            <div className="d-flex">
                              <span className="poTableSelectAll">Select All</span>
                              {data.RecordsCount > 0 && (
                                <Form.Check
                                  custom
                                  label=""
                                  type={"checkbox"}
                                  id={"action_select_all"}
                                  checked={isSelectAll}
                                  onChange={() => this.selectAll()}
                                />
                              )}
                            </div>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {status == "process" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Loading data please wait...</td>
                        </tr>
                      ) : status == "error" ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}> There was an error while fetching record.</td>
                        </tr>
                      ) : data.RecordsCount == 0 ? (
                        <tr className="poTableMsg">
                          <td colSpan={columns}>Sorry no record found.</td>
                        </tr>
                      ) : (
                              data.result.map((item, key) => {
                                let id = item.ActionAlertId;
                                return (
                                  <tr key={key}>
                                    <td>{item.serial}</td>
                                    <td>{item.RuleName}</td>
                                    <td>{item.Description}</td>
                                    <td>{item.Value}</td>
                                    {crudAccess.action && (
                                      <td>
                                        <span className="poTableAction">
                                          {crudAccess.edit && (
                                            <Link to={`/actionalert/edit/${id}`}>
                                              <img
                                                className="poc-cp"
                                                src={common.appIcon("EditIcon")}
                                                alt="Edit"
                                              />
                                            </Link>
                                          )}
                                          {crudAccess.delete && (
                                            <img
                                              className="poc-cp"
                                              src={common.appIcon("DelIcon")}
                                              alt="Delete"
                                              onClick={() => this.deleteData(id, "single")}
                                            />
                                          )}
                                        </span>
                                      </td>
                                    )}
                                    {crudAccess.delete && (
                                      <td>
                                        <Form.Check
                                          custom
                                          inline
                                          label=""
                                          type={"checkbox"}
                                          id={"check_" + id}
                                          checked={deleteIds.includes(id)}
                                          onChange={() => this.selectData(id)}
                                        />
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            )}
                    </tbody>
                  </Table>
                  {data.RecordsCount > 0 && (
                    <div className="d-flex">
                      <div className="mr-auto p-2">
                        Total Records: {data.RecordsCount}
                      </div>
                      <div className="p-2">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={paging.pageIndex}
                          itemsCountPerPage={paging.pageSize}
                          totalItemsCount={data.RecordsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <ImportCSV
                  ref={(instance) => {
                    this.ImportCSV = instance;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { ActionAlert };