import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-js-pagination";
// _includes
import { CustomerDetails } from "./CustomerDetails";
import { history, common } from "_helpers";
import Filter from "../includes/Filter";
//API Services
import { apiServices } from "_api";
// css
import styles from "../css/Analytics.module.css";

class AnalyticsCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isPage: "customerList",
      data: {
        RecordsCount: 0,
        result: [],
      },
      filter: {
        // customer
        CustomerName: "",
        EmailAddress: "",
        MobileNumber: "",
        MacAddress: "",
        LoyalityId: "",
        // overview
        OverViewFilterTypeId: "",
        OverviewFilterLegendValue: "",
      },
      paging: {
        pageSize: 1000,
        pageIndex: 1,
        recordCount: 0,
      },
      // overview filter type
      aoFilterTypes: [
        "Registered Connected",
        "Registered Not Connected",
        "Visual Mechandising",
        "Unique",
        "Returning",
        "New Registration",
        "Ananymous",
        "Passby Traffic",
        "Inside Traffic",
        "Passing Traffic Registered",
        "Passing Traffic Anonymous",
        "Bounce Customers",
      ],
    };
  }

  customerList = () => {
    const { paging } = this.state;
    const pFilter = this.state.filter;
    const { filter, filterDate } = this.filter.state;
    var data = {
      url: "AC_GET_CUSTOMER_VIEW_DATA",
      query: filterDate.Date,
      body: {
        // main filter
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
        // sub filter
        CustomerName: pFilter.CustomerName,
        EmailAddress: pFilter.EmailAddress,
        MobileNumber: pFilter.MobileNumber,
        MacAddress: pFilter.MacAddress,
        LoyalityId: pFilter.LoyalityId,
        // overview filter
        OverViewFilterTypeId: pFilter.OverViewFilterTypeId,
        OverviewFilterLegendValue: pFilter.OverviewFilterLegendValue,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.length,
              result: response,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => {}
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.customerList();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.customerList();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        paging: {
          pageSize: 1000,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          // customer
          CustomerName: "",
          EmailAddress: "",
          MobileNumber: "",
          MacAddress: "",
          LoyalityId: "",
          // overview
          OverViewFilterTypeId: "",
          OverviewFilterLegendValue: "",
        },
      },
      () => {
        this.customerList();
      }
    );
  }

  customerInfo(macAddress) {
    this.props.setFilter(false);
    this.setState(
      {
        isPage: "customerDetails",
      },
      () => {
        this.customerDetails.loadData(macAddress);
      }
    );
  }

  redirect(page) {
    history.push("/analytics/" + page);
  }

  setList() {
    this.props.setFilter(true);
    this.setState({ isPage: "customerList" });
  }

  overviewFilterType() {
    const { filter, aoFilterTypes } = this.state;
    if (filter.OverViewFilterTypeId) {
      return " - " + aoFilterTypes[filter.OverViewFilterTypeId - 1];
    }
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.customerList();
      }
    );
  }

  filterReset() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.customerList();
      }
    );
  }

  render() {
    const {
      isPage,
      status,
      data,
      paging,
      filter,
      isFilter,
      isLoading,
    } = this.state;
    return isPage == "customerDetails" ? (
      <CustomerDetails
        isPage={() => this.setList()}
        ref={(instance) => {
          this.customerDetails = instance;
        }}
      />
    ) : (
      <div className="contentBox">
        <div className="poTable">
          <div className="d-flex">
            <div className="mr-auto p-2">
              <span className="potableHeadTitle">
                {" "}
                Customer List {this.overviewFilterType()}{" "}
              </span>
            </div>
            <div className="p-2">
              <div className="d-flex">
                <div className="poTableIcon">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    rootClose={true}
                    overlay={
                      <Popover id="country-filter">
                        <Popover.Title as="h3">Filter List</Popover.Title>
                        <Popover.Content>
                          <Form className="poTableFilter">
                            <Form.Control
                              type="text"
                              name="CustomerName"
                              placeholder="Customer Name"
                              value={filter.CustomerName}
                              onChange={this.filterData}
                            />
                            <Form.Control
                              type="text"
                              name="EmailAddress"
                              placeholder="Email Address"
                              value={filter.EmailAddress}
                              onChange={this.filterData}
                            />
                            <Form.Control
                              type="text"
                              name="MobileNumber"
                              placeholder="Mobile Number"
                              value={filter.MobileNumber}
                              onChange={this.filterData}
                            />
                            <Form.Control
                              type="text"
                              name="MacAddress"
                              placeholder="Mac Address"
                              value={filter.MacAddress}
                              onChange={this.filterData}
                            />
                            <Form.Control
                              type="text"
                              name="LoyalityId"
                              placeholder="Loyality Id"
                              value={filter.LoyalityId}
                              onChange={this.filterData}
                            />
                          </Form>
                        </Popover.Content>
                      </Popover>
                    }
                    onExit={() => this.handleFilter(false)}
                  >
                    {isFilter ? (
                      <img
                        src={common.appIcon("FilterSelect")}
                        className="poc-cp"
                        alt="Filter"
                        onClick={() => this.handleFilter(false)}
                      />
                    ) : (
                      <img
                        src={common.appIcon("FilterIcon")}
                        className="poc-cp"
                        alt="Filter"
                        onClick={() => this.handleFilter(true)}
                      />
                    )}
                  </OverlayTrigger>
                  <img
                    src={common.appIcon("RefreshIcon")}
                    className="poc-cp"
                    alt="Refresh"
                    onClick={() => this.handleRefresh()}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <span className="poTableFetcher">
                fetching data please wait...
              </span>
            </div>
          )}

          <div className={isLoading ? "poTableOnLoad" : ""}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Client MAC Address</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Registered On</th>
                </tr>
              </thead>
              <tbody>
                {status == "process" ? (
                  <tr className="poTableMsg">
                    <td colSpan="8">Loading data please wait...</td>
                  </tr>
                ) : status == "error" ? (
                  <tr className="poTableMsg">
                    <td colSpan="8">
                      There was an error while fetching record.
                    </td>
                  </tr>
                ) : data.RecordsCount == 0 ? (
                  <tr className="poTableMsg">
                    <td colSpan="8">Sorry no record found.</td>
                  </tr>
                ) : (
                  data.result.map((item, key) => {
                    let id = item.CountryId;
                    return (
                      <tr key={key}>
                        <td>{item.serial}</td>
                        <td>
                          <label
                            className="poc-link"
                            onClick={() =>
                              this.customerInfo(item.ClientMacAddress)
                            }
                          >
                            {item.CustomerName}
                          </label>
                        </td>
                        <td>{item.Email}</td>
                        <td>{item.MobileNumber}</td>
                        <td>{item.ClientMacAddress}</td>
                        <td>{item.DateOfBirth}</td>
                        <td>{item.Gender}</td>
                        <td>{item.RegisteredOn}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
            {data.RecordsCount > 0 && (
              <div className="d-flex">
                <div className="mr-auto p-2">
                  Total Records: {data.RecordsCount}
                </div>
                <div className="p-2">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paging.pageIndex}
                    itemsCountPerPage={paging.pageSize}
                    totalItemsCount={data.RecordsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Filter
          type={isPage}
          filterInit={() => {
            this.customerList();
          }}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export { AnalyticsCustomer };
