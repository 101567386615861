import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
import { AnalyticsOverview, AnalyticsCustomer, AnalyticsZone } from "../index";
import styles from "../css/Analytics.module.css";

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "exist",
      activeTab: history.location.pathname.split("/")[2],
      isFilter: true,
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
    history.push(tab);
  };

  // page access
  checkAccess() {
    const { activeTab } = this.state;
    let pageList = {
      "overview": "ANALYTICSOVERVIEW_PAGEACCESS",
      "customer": "CUSTOMERANALYTICS_PAGEACCESS",
      "zone": "ZONEANALYTICS_PAGEACCESS",
    };
    if (!common.isAccess(pageList[activeTab])) {
      common.accessDenied();
    } else {
      return true;
    }
  }

  customerFilter = (data) => {
    this.setState(
      {
        activeTab: "customer",
      },
      () => {
        const { filter } = this.customer.state;
        filter.OverViewFilterTypeId = data.typeId;
        filter.OverviewFilterLegendValue = data.legend;
        this.customer.setState(
          {
            filter,
          },
          () => {
            this.customer.filterSubmit();
            history.push("customer");
            window.scrollTo(0, 0);
          }
        );
      }
    );
  };

  filterModal(status) {
    const { activeTab } = this.state;
    if (activeTab == "overview") {
      this.overview.filterModal(status);
    } else if (activeTab == "customer") {
      this.customer.filterModal(status);
    } else if (activeTab == "zone") {
      this.zone.filterModal(status);
    }
  }

  render() {
    const { isFilter, activeTab } = this.state;
    return (
      <div className="pageContent">
        <Tab.Container activeKey={activeTab}>
          <div className="contentHead">
            <div className="row">
              <div className="col-sm-4 poFlex">
                <h1>Analytics</h1>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#" className="noPipe">
                      Overview
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-5">
                <Form className={`poTabs poFlex poDefault`}>
                  {common.isAccess("ANALYTICSOVERVIEW_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "overview" ? "inActive" : ""}
                      onClick={() => this.setTab("overview")}
                    >
                      Overview
                    </Button>
                  )}
                  {common.isAccess("CUSTOMERANALYTICS_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "customer" ? "inActive" : ""}
                      onClick={() => this.setTab("customer")}
                    >
                      Customer
                    </Button>
                  )}
                  {common.isAccess("ZONEANALYTICS_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "zone" ? "inActive" : ""}
                      onClick={() => this.setTab("zone")}
                    >
                      Zone
                    </Button>
                  )}
                  {/* <Button
                    variant="primary"
                    className={activeTab != "imr" ? 'inActive' : ""}
                    onClick={() => this.setTab("imr")}
                  >
                    IMR
                  </Button> */}
                </Form>
              </div>
              <div className="col-sm-3">
                {isFilter && (
                  <div className={`${styles.ahForm} ${styles.ahFilter}`}>
                    <Button
                      variant="primary filter-btn"
                      onClick={() => this.filterModal(true)}
                    >
                      Filter
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="contentBody">
            <div className={`${styles.analytisTabs} content`}>
              <Tab.Content>
                {common.isAccess("ANALYTICSOVERVIEW_PAGEACCESS") && (
                  <Tab.Pane eventKey="overview" title="Overview">
                    <AnalyticsOverview
                      customerFilter={(data) => this.customerFilter(data)}
                      ref={(instance) => {
                        this.overview = instance;
                      }}
                    />
                  </Tab.Pane>
                )}
                {common.isAccess("CUSTOMERANALYTICS_PAGEACCESS") && (
                  <Tab.Pane eventKey="customer" title="Customer">
                    <AnalyticsCustomer
                      setFilter={(filter) =>
                        this.setState({ isFilter: filter })
                      }
                      ref={(instance) => {
                        this.customer = instance;
                      }}
                    />
                  </Tab.Pane>
                )}
                {common.isAccess("ZONEANALYTICS_PAGEACCESS") && (
                  <Tab.Pane eventKey="zone" title="Zone">
                    <AnalyticsZone
                      ref={(instance) => {
                        this.zone = instance;
                      }}
                    />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
export { Analytics };
