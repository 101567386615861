import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-js-pagination";
// switch
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";

export default class BannerLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: "",
      categoryName: "",
      status: "process",
      isLoading: false,
      isFilter: false,
      isUpdating: false,
      columns: 8,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        LocationName: "",
        LocationCode: "",
        Description: "",
        ConceptId: "",
        ProvinceId: "",
        CountryId: "",
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
    };
  }

  getBannerLocation(id, name) {
    this.setState(
      {
        categoryId: id,
        categoryName: name,
      },
      () => {
        this.loadData();
      }
    );
  }

  loadData = () => {
    this.setState({ status: "process" });
    const { categoryId, paging, filter } = this.state;
    var data = {
      url: "BANNER_CATEGORY_LOCATION",
      query:
        "/" +
        categoryId +
        "?PageSize=" +
        paging.pageSize +
        "&PageIndex=" +
        (paging.pageIndex - 1),
      body: {
        LocationName: filter.LocationName,
        LocationCode: filter.LocationCode,
        Description: filter.Description,
        CountryId: filter.CountryId,
        ProvinceId: filter.ProvinceId,
        ConceptId: filter.ConceptId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => {}
    );
  };

  updateLocation = (checked, key, LocationId) => {
    const { data, categoryId } = this.state;
    data.result[key].IsAssigned = checked;

    this.setState({
      data,
      isUpdating: true,
    });

    var aData = {
      url: "BANNER_CATEGORY_LOCATION_ASSIGN",
      body: {
        BannerCategoryId: categoryId,
        LocationId: LocationId,
      },
      method: "POST",
    };

    apiServices.call(
      aData,
      (response) => {
        if (this.crudStatus(response.status)) {
          common.snack("S", "Status has been updated");
        } else {
          common.snack("E", "There was an error while update status");
        }
      },
      (error) => {
        common.snack("E", "There was an error while update status");
      },
      (final) => {
        this.setState({ isUpdating: false });
        this.loadData();
      }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          LocationName: "",
          LocationCode: "",
          Description: "",
          ConceptId: "",
          ProvinceId: "",
          CountryId: "",
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {
      status,
      columns,
      data,
      paging,
      filter,
      isLoading,
      isUpdating,
      isFilter,
      // data
      categoryName,
    } = this.state;

    return (
      <div className={`${isUpdating && "poc-none"} poTable`}>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <span className="potableHeadTitle">
              {categoryName} - Assign Location
            </span>
          </div>
          <div className="p-2">
            <div className="d-flex">
              <div className="poTableIcon">
                {/* <OverlayTrigger
                  trigger="click"
                  placement="left"
                  rootClose={true}
                  overlay={
                    <Popover id="account-filter">
                      <Popover.Title as="h3">Filter List</Popover.Title>
                      <Popover.Content>
                        <Form className="poTableFilter">
                          <Form.Control
                            type="text"
                            name="CampaignName"
                            placeholder="Campaign Name"
                            value={filter.CampaignName}
                            onChange={this.filterData}
                          />
                          <Form.Control
                            type="text"
                            name="Description"
                            placeholder="Description"
                            value={filter.Description}
                            onChange={this.filterData}
                          />
                        </Form>
                      </Popover.Content>
                    </Popover>
                  }
                  onExit={() => this.handleFilter(false)}
                >
                  {isFilter ? (
                    <img
                      src={common.appIcon("FilterSelect")}
                      className="poc-cp"
                      alt="Filter"
                      onClick={() => this.handleFilter(false)}
                    />
                  ) : (
                    <img
                      src={common.appIcon("FilterIcon")}
                      className="poc-cp"
                      alt="Filter"
                      onClick={() => this.handleFilter(true)}
                    />
                  )}
                </OverlayTrigger> */}
                <img
                  src={common.appIcon("RefreshIcon")}
                  className="poc-cp"
                  alt="Refresh"
                  onClick={() => this.handleRefresh()}
                />
              </div>
            </div>
          </div>
        </div>

        {isLoading && (
          <div className="d-flex justify-content-center">
            <span className="poTableFetcher">fetching data please wait...</span>
          </div>
        )}

        <div className={isLoading ? "poTableOnLoad" : ""}>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Location Name</th>
                <th>Location Code</th>
                <th>Country Name</th>
                <th>Province Name</th>
                <th>Concept Name</th>
                <th>Assigned?</th>
              </tr>
            </thead>
            <tbody>
              {status == "process" ? (
                <tr className="poTableMsg">
                  <td colSpan={columns}>Loading data please wait...</td>
                </tr>
              ) : status == "error" ? (
                <tr className="poTableMsg">
                  <td colSpan={columns}>
                    There was an error while fetching record.
                  </td>
                </tr>
              ) : data.RecordsCount == 0 ? (
                <tr className="poTableMsg">
                  <td colSpan={columns}>Sorry no record found.</td>
                </tr>
              ) : (
                data.result.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.serial}</td>
                      <td>{item.LocationName}</td>
                      <td>{item.LocationCode}</td>
                      <td>{item.CountryName}</td>
                      <td>{item.ProvinceName}</td>
                      <td>{item.ConceptName}</td>
                      <td>
                        <BootstrapSwitchButton
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onlabel="Yes"
                          offlabel="No"
                          size="sm"
                          name={"switch" + key}
                          checked={item.IsAssigned}
                          disabled={item.IsAssigned}
                          onChange={(e) =>
                            this.updateLocation(e, key, item.LocationId)
                          }
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
          {data.RecordsCount > 0 && (
            <div className="d-flex">
              <div className="mr-auto p-2">
                Total Records: {data.RecordsCount}
              </div>
              <div className="p-2">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={paging.pageIndex}
                  itemsCountPerPage={paging.pageSize}
                  totalItemsCount={data.RecordsCount}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
