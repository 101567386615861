import React, { Component } from 'react';
import { Footer } from '../../../_components';
import styles from './LoginLayout.module.css';
class LoginLayout extends Component {
    render() {
        return (
            <div className={styles.loginBackground} >
                {this.props.children}
                <Footer />
            </div>
        )
    }
}
export { LoginLayout };