import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
// _includes
import { common, tools } from "_helpers";
import { apiServices } from "_api";
// pages
import PasswordModal from "../includes/PasswordModal";

export default class AccountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 10,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        UserGroupId: "",
        AuthenticationTypeId: "",
        MobileNumber: "",
        Email: "",
      },
      filterData: {
        userGroupList: [],
        authenticationTypeList: [],
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      crudAccess: {
        add: false,
        edit: false,
        delete: false,
        password: false,
        action: false
      }
    };
  }

  componentDidMount = async () => {
    this.crudAccess();
    this.loadData();
    this.getUserGroupList();
    this.getAuthenticationTypeList();
  };

  loadData = () => {
    const { paging, filter } = this.state;
    var data = {
      url: "ADMIN_USER_ACCOUNT_GET_TABLE",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: filter,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  getUserGroupList = () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_USER_GROUP_GET_ALL",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.userGroupList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getAuthenticationTypeList = () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_USER_ACCOUNT_AUTH_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.authenticationTypeList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          UserGroupId: "",
          AuthenticationTypeId: "",
          MobileNumber: "",
          Email: "",
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(id, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(id, type) {
    var data = {
      url: "ADMIN_USER_ACCOUNT_DELETE",
    };

    if (type == "single") {
      data.query = "/" + id;
    } else {
      const { deleteIds } = this.state;
      data.query = "?UserInfoIds=" + deleteIds.join("&UserInfoIds=");
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  selectData(id) {
    let deleteIds = this.state.deleteIds;
    let isBulkDelete = false;
    if (deleteIds.includes(id)) {
      deleteIds = common.removeItem(deleteIds, id);
    } else {
      deleteIds.push(id);
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    this.setState({
      deleteIds,
      isBulkDelete,
    });
  }

  selectAll() {
    const { data, isSelectAll } = this.state;
    let deleteIds = [];
    let select = false;
    let isBulkDelete = false;
    if (!isSelectAll) {
      select = true;
      if (data.RecordsCount > 0) {
        data.result.map((item, key) => {
          let id = item.CampaignId;
          deleteIds.push(id);
        });
      }
    }
    if (deleteIds.length > 0) {
      isBulkDelete = true;
    }
    this.setState({
      deleteIds,
      isSelectAll: select,
      isBulkDelete,
    });
  }

  // module & page access
  crudAccess() {
    const { crudAccess } = this.state;
    let columns = this.state.columns;
    (common.isAccess("USERACCOUNT_ADD") && (crudAccess.add = true));
    (common.isAccess("USERACCOUNT_EDIT") && (crudAccess.edit = true));
    (common.isAccess("USERACCOUNT_DELETE") && (crudAccess.delete = true));
    (common.isAccess("USERACCOUNT_CHANGEPASSWORD") && (crudAccess.password = true));
    (crudAccess.edit || crudAccess.delete) ? (crudAccess.action = true) : --columns;
    (!crudAccess.delete) && --columns;
    (!crudAccess.password) && --columns;
    this.setState({
      columns,
      crudAccess
    })
  }

  render() {
    const {
      status,
      columns,
      data,
      paging,
      filter,
      filterData,
      isLoading,
      isFilter,
      isBulkDelete,
      deleteIds,
      isSelectAll,
      crudAccess
    } = this.state;

    return (
      <div className="contentBox">
        <div className="poTable">
          <div className="d-flex">
            <div className="mr-auto p-2">
              <span className="potableHeadTitle">Users List</span>
              {crudAccess.add && (
                <> - <Link to="/admin/user/account/add"><Button variant="primary config-btn">Add New User</Button></Link></>
              )}
            </div>
            <div className="p-2">
              <div className="d-flex">
                <div className="poTableIcon">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    rootClose={true}
                    overlay={
                      <Popover id="account-filter">
                        <Popover.Title as="h3">Filter List</Popover.Title>
                        <Popover.Content>
                          <Form className="poTableFilter">
                            <Form.Control
                              as="select"
                              name="UserGroupId"
                              value={filter.UserGroupId}
                              onChange={this.filterData}
                            >
                              <option value="0">Select User Group</option>
                              {filterData.userGroupList.map(
                                (item, key) => (
                                  <option key={key} value={item.UserGroupId}>
                                    {item.UserGroupName}
                                  </option>
                                )
                              )}
                            </Form.Control>
                            <Form.Control
                              as="select"
                              name="AuthenticationTypeId"
                              value={filter.AuthenticationTypeId}
                              onChange={this.filterData}
                            >
                              <option value="0">Select Auth Type</option>
                              {filterData.authenticationTypeList.map(
                                (item, key) => {
                                  return (
                                    <option key={key} value={item.Id}>
                                      {item.Value}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                            <Form.Control
                              type="text"
                              name="MobileNumber"
                              placeholder="Mobile Number"
                              value={filter.MobileNumber}
                              onChange={this.filterData}
                            />
                            <Form.Control
                              type="text"
                              name="Email"
                              placeholder="Email"
                              value={filter.Email}
                              onChange={this.filterData}
                            />
                          </Form>
                        </Popover.Content>
                      </Popover>
                    }
                    onExit={() => this.handleFilter(false)}
                  >
                    {isFilter ? (
                      <img
                        src={common.appIcon("FilterSelect")}
                        className="poc-cp"
                        alt="Filter"
                        onClick={() => this.handleFilter(false)}
                      />
                    ) : (
                        <img
                          src={common.appIcon("FilterIcon")}
                          className="poc-cp"
                          alt="Filter"
                          onClick={() => this.handleFilter(true)}
                        />
                      )}
                  </OverlayTrigger>

                  <img
                    src={common.appIcon("RefreshIcon")}
                    className="poc-cp"
                    alt="Refresh"
                    onClick={() => this.handleRefresh()}
                  />
                  {crudAccess.delete && (
                    isBulkDelete ? (
                      <img
                        src={common.appIcon("DelAllActIcon")}
                        className="poc-cp"
                        alt="Delete"
                        onClick={() => this.deleteData(0, "bulk")}
                      />
                    ) : (
                        <img
                          src={common.appIcon("DelAllNoneIcon")}
                          className="poc-cp"
                          alt="Delete"
                        />
                      ))}
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex justify-content-center">
              <span className="poTableFetcher">
                fetching data please wait...
            </span>
            </div>
          )}

          <div className={isLoading ? "poTableOnLoad" : ""}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Employee Id</th>
                  <th>Full Name</th>
                  <th>Designation</th>
                  <th>Description</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  {crudAccess.password && (<th>Change Password?</th>)}
                  {crudAccess.action && (<th>Actions</th>)}
                  {crudAccess.delete && (
                    <th>
                      <div className="d-flex">
                        <span className="poTableSelectAll">Select All</span>
                        {data.RecordsCount > 0 && (
                          <Form.Check
                            custom
                            label=""
                            type={"checkbox"}
                            id={"account_select_all"}
                            checked={isSelectAll}
                            onChange={() => this.selectAll()}
                          />
                        )}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {status == "process" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Loading data please wait...</td>
                  </tr>
                ) : status == "error" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>
                      There was an error while fetching record.
                  </td>
                  </tr>
                ) : data.RecordsCount == 0 ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Sorry no record found.</td>
                  </tr>
                ) : data.result.map((item, key) => {
                  let id = item.UserInfoId;
                  return (
                    <tr key={key}>
                      <td>{item.serial}</td>
                      <td>{tools.setVal(item.EmployeeId)}</td>
                      <td>{tools.setVal(item.UserFullName)}</td>
                      <td>{tools.setVal(item.Designation)}</td>
                      <td>{tools.setVal(item.Description)}</td>
                      <td>{tools.setVal(item.MobileNumber)}</td>
                      <td>{tools.setVal(item.Email)}</td>
                      {crudAccess.password && (
                        <td>
                          <img
                            className="poc-cp"
                            src={common.loadImg("lock.png")}
                            alt="Change Password"
                            onClick={() =>
                              this.PasswordModal.parentCall(id)
                            }
                          />
                        </td>
                      )}
                      {crudAccess.action && (
                        <td>
                          <span className="poTableAction">
                            {crudAccess.edit && (
                              <Link to={`/admin/user/account/edit/${id}`}>
                                <img
                                  className="poc-cp"
                                  src={common.appIcon("EditIcon")}
                                  alt="Edit"
                                />
                              </Link>
                            )}
                            {crudAccess.delete && (
                              <img
                                className="poc-cp"
                                src={common.appIcon("DelIcon")}
                                alt="Delete"
                                onClick={() =>
                                  this.deleteData(id, "single")
                                }
                              />
                            )}
                          </span>
                        </td>
                      )}
                      {crudAccess.delete && (
                        <td>
                          <Form.Check
                            custom
                            inline
                            label=""
                            type={"checkbox"}
                            id={"check_" + id}
                            checked={deleteIds.includes(id)}
                            onChange={() => this.selectData(id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data.RecordsCount > 0 && (
              <div className="d-flex">
                <div className="mr-auto p-2">
                  Total Records: {data.RecordsCount}
                </div>
                <div className="p-2">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paging.pageIndex}
                    itemsCountPerPage={paging.pageSize}
                    totalItemsCount={data.RecordsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <PasswordModal
          ref={(instance) => {
            this.PasswordModal = instance;
          }}
          onComplete={(type) => { }}
        />
      </div>
    );
  }
}