import React from 'react';
import { render } from "react-dom";
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// css
import './asset/css/common.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
// Import Snackbar
import { SnackbarProvider } from 'notistack';
import { CommonSnackbarConfig } from "_includes/SnackBar";

// ReactDOM.render(<App />, document.getElementById('root'));
const renderApp = () =>
  render(
      <SnackbarProvider 
        maxSnack={1}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CommonSnackbarConfig />
        <App />
      </SnackbarProvider>,
    document.getElementById("root")
  );
  renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();