import React from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
// API Services
import { apiServices } from "_api";
// css
import "react-dates/lib/css/_datepicker.css";

export default class FilterActivity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFilter: "none",
      filter: {
        customDate: {
          startDate: null,
          endDate: null,
          DateFrom: "",
          DateTo: "",
        },
        UserInfoId: "",
        ModuleId: "",
        ProcessId: ""
      },
      filterData: {
        userInfoList: [],
        moduleList: [],
        processList: [],
      },
      // common
      isModal: false,
      focusedInput: null,
    };
  }

  componentDidMount() {
    this.defaultAPI();
  }

  defaultAPI() {
    this.getUserInfoList();
    this.getModuleList();
    this.getProcessList();
  }

  // ### filter functions start here ###
  getUserInfoList = () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_USER_ACCOUNT_GET_ALL",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          filterData.userInfoList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getModuleList = () => {
    const { filterData } = this.state;
    var data = {
      url: "LOGS_ACTIVITY_MODULE_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          filterData.moduleList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  getProcessList = async () => {
    const { filterData } = this.state;
    var data = {
      url: "LOGS_ACTIVITY_PROCESS_LIST",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          filterData.processList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };


  // filter select change
  selectChange = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState({
      filter
    });
  };

  setCustomDate(startDate, endDate) {
    const { filter } = this.state;
    filter.customDate = {
      startDate,
      endDate,
      DateFrom: moment(startDate).format("DD-MM-YYYY"),
      DateTo: moment(endDate).format("DD-MM-YYYY"),
    };

    this.setState({
      filter,
    });
  }

  filterForm() {
    const { filter } = this.state;
    this.setState(
      {
        filter,
        isModal: false,
      },
      () => {
        this.props.filterSubmit()
      }
    );
  }

  resetForm() {
    const { filter, filterData } = this.state;
    filter.Date = 1;
    filter.customDate = {
      DateFrom: "",
      DateTo: "",
    };

    filter.UserInfoId = "";
    filter.ModuleId = "";
    filter.ProcessId = "";

    this.setState(
      {
        filter,
        filterData,
        isModal: false,
      },
      () => {
        this.props.filterReset();
      }
    );
  }

  filterModal(status) {
    this.setState({
      isModal: status,
    });
  }

  resetDate() {
    const { filter } = this.state;
    filter.customDate = {
      startDate: null,
      endDate: null,
      DateFrom: "",
      DateTo: ""
    }
    this.setState({ filter })
  }

  render() {
    const { isFilter, isModal, focusedInput, filter, filterData } = this.state;
    return (
      <Modal show={isModal}>
        <Modal.Header>
          <Modal.Title>Filter List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form
              className="poModalFilter"
              onSubmit={(event) => event.preventDefault}
            >
              <div>
                <label className="poModalDateLabel">
                  <span>Start Date - End Date</span>
                  <span className="poc-link poc-cp" onClick={() => this.resetDate()}>Reset Date</span>
                </label>
                <div className="poModalDateInput">
                  <DateRangePicker
                    startDate={filter.customDate.startDate}
                    startDateId="DateFrom"
                    endDate={filter.customDate.endDate}
                    endDateId="DateTo"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setCustomDate(startDate, endDate)
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    }
                    isOutsideRange={() => false}
                  />
                </div>
              </div>

              <label className="poLabel">User</label>
              <Form.Control
                as="select"
                name="UserInfoId"
                value={filter.UserInfoId}
                onChange={this.selectChange}
              >
                <option value="">Select User</option>
                {filterData.userInfoList.map((item, key) => {
                  return (
                    <option key={key} value={item.UserInfoId}>
                      {item.UserFullName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Module</label>
              <Form.Control
                as="select"
                name="ModuleId"
                value={filter.ModuleId}
                onChange={this.selectChange}
              >
                <option value="">Select Module</option>
                {filterData.moduleList.map((item, key) => {
                  return (
                    <option key={key} value={item.Id}>
                      {item.Value}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Process</label>
              <Form.Control
                as="select"
                name="ProcessId"
                value={filter.ProcessId}
                onChange={this.selectChange}
              >
                <option value="">Select Process</option>
                {filterData.processList.map((item, key) => {
                  return (
                    <option key={key} value={item.Id}>
                      {item.Value}
                    </option>
                  );
                })}
              </Form.Control>

            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterForm()}
          >
            Filter
          </Button>
          <Button variant="primary config-btn" onClick={() => this.resetForm()}>
            Reset
          </Button>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
