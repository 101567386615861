import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
// Pages
import AgeGroupReport from "./Insight/AgeGroupReport";
import VisitTimeReport from "./Insight/VisitTimeReport";

class ReportInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: history.location.pathname.split("/")[3],
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
    history.push(tab);
  };

  // page access
  checkAccess() {
    const { activeTab } = this.state;
    let pageList = {
      1: "AGEGROUPREPORT_PAGEACCESS",
      2: "VISITTIMEREPORT_PAGEACCESS",
    };
    if (!common.isAccess(pageList[activeTab])) {
      common.accessDenied();
    } else {
      return true;
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="pageContent">
        <Tab.Container activeKey={activeTab}>
          <div className="contentHead">
            <div className="row">
              <div className="col-sm-5 poFlex">
                <h1> Customer Insight </h1>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#" className="noPipe">
                      Customer Insight
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-7">
                <Form className={`poTabs poFlex poDefault`}>
                  {common.isAccess("AGEGROUPREPORT_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != 1 ? "inActive" : ""}
                      onClick={() => this.setTab("1")}
                    >
                      Age Group Report
                    </Button>
                  )}
                  {common.isAccess("VISITTIMEREPORT_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != 2 ? "inActive" : ""}
                      onClick={() => this.setTab("2")}
                    >
                      Visit Time Report
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
          <div className="contentBody">
            <div className="content">
              <Tab.Content>
                <Tab.Pane eventKey="1" title="Age Group Report">
                  {common.isAccess("AGEGROUPREPORT_PAGEACCESS") && (
                    <AgeGroupReport
                      ref={(instance) => {
                        this.agegroupreport = instance;
                      }}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="2" title="Visit Time Report">
                  {common.isAccess("VISITTIMEREPORT_PAGEACCESS") && (
                    <VisitTimeReport
                      ref={(instance) => {
                        this.visittimereport = instance;
                      }}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}

export { ReportInsight };
