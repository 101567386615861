import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";
// css
import styles from "pages/Administration/css/Admin.module.css";
// const
let apiCount = 0;

class License extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: {
        licenseKey0: false,
        licenseKey1: false,
        licenseKey2: false,
        licenseKey3: false,
        licenseKey4: false,
      },
      fields: {
        licenseKey0: "",
        licenseKey1: "",
        licenseKey2: "",
        licenseKey3: "",
        licenseKey4: "",
      },
      dataTypes: ["SYSTEM", "SENSOR", "ACCESSPOINT"],
      dataList: {
        SYSTEM: "",
        SENSOR: "",
        ACCESSPOINT: "",
      },
      licenseTypes: [
        "ONBOARDING",
        "ANALYTICS",
        "MARKETING",
        "THIRD_PARTY",
        "LOCATION",
      ],
      licenseStatus: {
        ONBOARDING: "",
        ANALYTICS: "",
        MARKETING: "",
        THIRD_PARTY: "",
        LOCATION: "",
      },
    };
  }

  componentDidMount = async () => {
    if (!common.isAccess("LICENSEMANAGEMENT_PAGEACCESS")) {
      common.accessDenied();
    } else {
      this.getData();
    }
  };

  getData() {
    const { dataTypes, licenseTypes } = this.state;
    // data type
    dataTypes.map((item) => {
      this.LicenseCrud(item, "data", "GET");
    });
    // license type
    licenseTypes.map((item) => {
      this.LicenseCrud(item, "license", "STATUS");
    });
  }

  LicenseCrud = (item, type, method) => {
    const { dataTypes, dataList, licenseTypes, licenseStatus } = this.state;
    var data = {
      url: "ADMIN_LICENSE_" + item + "_" + method,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;

          if (type == "data") {
            dataList[item] = response;
            this.setState({ dataList });
          } else {
            licenseStatus[item] = response;
            this.setState({ licenseStatus });
          }
        }
      },
      (error) => {},
      (final) => {
        if (method == "GET" || method == "STATUS") {
          ++apiCount;
        }
        if (apiCount == dataTypes.length + licenseTypes.length) {
          this.setState({
            status: "success",
          });
        }
      }
    );
  };

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    fields[name] = value;
    this.setState({
      fields: fields,
    });
  };

  validateFields(key) {
    const { fields } = this.state;
    let allow = true;
    if (fields["licenseKey" + key] == "") {
      common.snack("E", "Please enter the license key");
      allow = false;
    }
    return allow;
  }

  updateLicense = (key) => {
    const { isLoading, fields, licenseTypes } = this.state;
    // validate
    let isValid = this.validateFields(key);
    if (isValid) {
      isLoading["isLoading" + key] = true;
      this.setState({ isLoading });
      var data = {
        url: "ADMIN_LICENSE_" + licenseTypes[key] + "_UPDATE",
        body: {
          LicenseKey: fields["licenseKey" + key],
        },
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          isLoading["isLoading" + key] = false;
          this.setState({ isLoading });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    common.snack("S", "Custom criteria has been updated");
  }

  crudError() {
    common.snack("S", "There was an error while update record");
  }

  render() {
    const { status, fields, isLoading, dataList, licenseStatus } = this.state;
    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-3">
              <h1>License Management</h1>
            </div>
            <div className="col-sm-9">
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    License Management
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <StatusBar status={status} />
              {status == "success" && (
                <div>
                  <div className={`${styles.alHead}`}>
                    <div className={`${styles.alHeadChild}`}>
                      <h6>System Address</h6>
                      <span>
                        {dataList.SYSTEM != ""
                          ? dataList.SYSTEM.SystemAddress
                          : " - "}
                      </span>
                    </div>
                    <div className={`${styles.alHeadChild}`}>
                      <h6>Sensor License Count</h6>
                      <span>
                        {dataList.SENSOR != "" ? dataList.SENSOR.Count : " - "}
                      </span>
                    </div>
                    <div className={`${styles.alHeadChild}`}>
                      <h6>Access Point Licence Count</h6>
                      <span>
                        {dataList.ACCESSPOINT != ""
                          ? dataList.ACCESSPOINT.Count
                          : " - "}
                      </span>
                    </div>
                  </div>

                  <div className={`${styles.alRow}`}>
                    <div className={`${styles.alColumn}`}>
                      <Form.Group>
                        <h5>Onboarding License</h5>
                        <Form.Label>
                          License Status:{" "}
                          {licenseStatus.ONBOARDING != ""
                            ? licenseStatus.ONBOARDING.Status
                            : " - "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="licenseKey0"
                          value={fields.licenseKey0}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.updateLicense(0)}
                        disabled={isLoading["isLoading0"]}
                      >
                        {isLoading["isLoading0"] ? "Updating.." : "Update"}
                      </Button>
                    </div>
                  </div>

                  <div className={`${styles.alRow}`}>
                    <div className={`${styles.alColumn} poc-mr-20`}>
                      <Form.Group>
                        <h5>Analytics License</h5>
                        <Form.Label>
                          License Status:{" "}
                          {licenseStatus.ANALYTICS != ""
                            ? licenseStatus.ANALYTICS.Status
                            : " - "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="licenseKey1"
                          value={fields.licenseKey1}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.updateLicense(1)}
                        disabled={isLoading["isLoading1"]}
                      >
                        {isLoading["isLoading1"] ? "Updating.." : "Update"}
                      </Button>
                    </div>
                    <div className={`${styles.alColumn}`}>
                      <Form.Group>
                        <h5>Marketing License</h5>
                        <Form.Label>
                          License Status:{" "}
                          {licenseStatus.MARKETING != ""
                            ? licenseStatus.MARKETING.Status
                            : " - "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="licenseKey2"
                          value={fields.licenseKey2}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.updateLicense(2)}
                        disabled={isLoading["isLoading2"]}
                      >
                        {isLoading["isLoading2"] ? "Updating.." : "Update"}
                      </Button>
                    </div>
                  </div>

                  <div className={`${styles.alRow}`}>
                    <div className={`${styles.alColumn} poc-mr-20`}>
                      <Form.Group>
                        <h5>3 rd Party Integration License</h5>
                        <Form.Label>
                          License Status:{" "}
                          {licenseStatus.THIRD_PARTY != ""
                            ? licenseStatus.THIRD_PARTY.Status
                            : " - "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="licenseKey3"
                          value={fields.licenseKey3}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.updateLicense(3)}
                        disabled={isLoading["isLoading3"]}
                      >
                        {isLoading["isLoading3"] ? "Updating.." : "Update"}
                      </Button>
                    </div>
                    <div className={`${styles.alColumn}`}>
                      <Form.Group>
                        <h5>Location License</h5>
                        <Form.Label>
                          License Status:{" "}
                          {licenseStatus.LOCATION != ""
                            ? licenseStatus.LOCATION.Status
                            : " - "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="licenseKey4"
                          value={fields.licenseKey4}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.updateLicense(4)}
                        disabled={isLoading["isLoading4"]}
                      >
                        {isLoading["isLoading4"] ? "Updating.." : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { License };
