import React from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Line, Polar, Bar } from "react-chartjs-2";
import MultiSelect from "react-multi-select-component";
import { common } from "_helpers/common";
import { apiServices } from "_api";
import styles from "./Dashboard.module.css"; // css

let chartColors = [
  "#672AEB",
  "#9E52F6",
  "#2D008E",
  "#004EFF",
  "#0094FF",
  "#bda3f6",
  "#b9acd1",
];

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      customerRegistration: [],
      newVisitorCount: "",
      newVisitorTotalCount: "",
      newVisitorComparisonPercentage: "",
      ReturningVisitorsCount: "",
      ComparisonPercentageReturn: "",
      locCount: "",
      busiestDayDisp: "",
      busiestTimeDisp: "",
      AccessPointCount: "",
      SensorCount: "",
      chartData: [],
      deviceData: [],
      loyaltyData: [],
      recentVisitData: [],
      newVisitorsData: [],
      formValue: "",
      fbValue: "",
      twitValue: "",
      googleValue: "",
      linkedValue: "",
      instaValue: "",
      // filter
      filter: {
        CountryId: "",
        ProvinceId: "",
        ConceptId: "",
        LocationIds: "",
      },
      filterDate: {
        Date: 0,
        DefaultDate: 0,
        NumberOfDays: 0,
        DefaultDays: 0,
        SelectedDays: 0,
      },
      filterData: {
        dateWise: [],
        countryList: [],
        provinceList: [],
        conceptList: [],
        conceptPersistList: [],
        locationList: [],
        locationSelected: [],
        locationSelectedId: [],
        locationSelectedInfo: [],
      },
      // modal
      isFilter: "none",
      isModal: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.primeAPI();
    this.staticApiList();
    this.nonDateFilterList();
  }

  primeAPI() {
    this.getAllDateFilter();
  }

  staticApiList() {
    this.getAllCountry();
    this.getAllConcept();
  }

  nonDateFilterList() {
    this.getLocationCount();
    this.getAccessPointSensor();
  }

  dateFilterList() {
    this.getNewVisitor();
    this.getCustomerRegistration();
    this.getReturningVisitor();
    this.getCustomerVisit();
    this.getAuthenticationMode();
    this.getDeviceData();
    this.getLoyaltyData();
    this.getRecentVisitData();
    this.getNewVisitorData();
    this.getBusiestDay();
    this.getBusiestTime();
  }

  // ### Non date filter ###
  getLocationCount = () => {
    const { filter } = this.state;
    var data = {
      url: "GET_LOCATION_COUNT",
      query: "/" + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            locCount: response,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAccessPointSensor = () => {
    const { filter } = this.state;
    var data = {
      url: "GET_ACCESS_POINT_AND_SENSOR",
      query: "/" + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            AccessPointCount: response.AccessPointCount,
            SensorCount: response.SensorCount,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  // ### with date filter ###
  getCustomerRegistration = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_CUSTOMER_REGISTRATION",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let newDate = [];
          let maleCount = [];
          let femaleCount = [];
          let unknownCount = [];
          response.map((data) => {
            newDate.push(data.Date);
            maleCount.push(data.MaleCount);
            femaleCount.push(data.FemaleCount);
            unknownCount.push(data.UnknownCount);
          });
          let customerData = {
            labels: newDate,
            datasets: [
              {
                data: maleCount,
                borderColor: chartColors[0],
                backgroundColor: "transparent",
                label: "Male",
              },
              {
                data: femaleCount,
                borderColor: chartColors[1],
                backgroundColor: "transparent",
                label: "Female",
              },
              {
                data: unknownCount,
                borderColor: chartColors[2],
                backgroundColor: "transparent",
                label: "Unknown",
              },
            ],
          };
          this.setState({ customerRegistration: customerData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getNewVisitor = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_NEW_VISITOR_INFO",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            newVisitorCount: response.NewVisitorsCount,
            newVisitorTotalCount: response.TotalCount,
            newVisitorComparisonPercentage: response.ComparisonPercentage,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getReturningVisitor = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_RETURNING_VISITORS",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            ReturningVisitorsCount: response.ReturningVisitorsCount,
            ComparisonPercentageReturn: response.ComparisonPercentage,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getBusiestDay = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_BUSIEST_DAY",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            busiestDayDisp: response,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getBusiestTime = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_BUSIEST_TIME",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            busiestTimeDisp: response,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getCustomerVisit = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_CUSTOMER_VISIT",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let MaleCount = [];
          let FemaleCount = [];
          let UnknownCount = [];

          MaleCount.push(response.MaleCount);
          FemaleCount.push(response.FemaleCount);
          UnknownCount.push(response.UnknownCount);

          let dataChart = {
            labels: ["Male", "Female", "Unknown"],
            datasets: [
              {
                data: [MaleCount, FemaleCount, UnknownCount],
                backgroundColor: [
                  chartColors[0],
                  chartColors[1],
                  chartColors[2],
                ],
                border: "none",
              },
            ],
          };
          this.setState({ chartData: dataChart });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAuthenticationMode = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_AUTHENTICATION_MODE",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          this.setState({
            formValue: response.FormPercentage,
            fbValue: response.FacebookPercentage,
            twitValue: response.TwitterPercentage,
            googleValue: response.GooglePercentage,
            linkedValue: response.LinkedInPercentage,
            instaValue: response.InstagramPercentage,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getDeviceData = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_DEVICE_CHART",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let newDate = [];
          let AndroidCount = [];
          let IosCount = [];
          let WindowsCount = [];
          let OtherCount = [];
          response.map((data) => {
            newDate.push(data.Date);
            AndroidCount.push(data.AndroidCount);
            IosCount.push(data.IosCount);
            WindowsCount.push(data.WindowsCount);
            OtherCount.push(data.OtherCount);
          });
          let deviceDataSet = {
            labels: newDate,
            datasets: [
              {
                data: IosCount,
                borderColor: chartColors[2],
                backgroundColor: chartColors[2],
                label: "iOS",
              },
              {
                data: AndroidCount,
                borderColor: chartColors[0],
                backgroundColor: chartColors[0],
                label: "Android",
              },
              {
                data: WindowsCount,
                borderColor: chartColors[1],
                backgroundColor: chartColors[1],
                label: "Windows",
              },
              {
                data: OtherCount,
                borderColor: chartColors[6],
                backgroundColor: chartColors[7],
                label: "Others",
              },
            ],
          };
          this.setState({ deviceData: deviceDataSet });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getLoyaltyData = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_LOYALTY_CHART",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let VisitCount = [];
          let CustomerCount = [];
          response.map((data) => {
            VisitCount.push(data.VisitCount);
            CustomerCount.push(data.CustomerCount);
          });
          let loyaltyDataSet = {
            labels: VisitCount,
            datasets: [
              {
                data: CustomerCount,
                backgroundColor: chartColors[5],
                borderColor: chartColors[0],
                borderWidth: 1,
                label: "Customers",
              },
            ],
          };
          this.setState({ loyaltyData: loyaltyDataSet });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getRecentVisitData = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_RECENT_VISIT_CHART",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let VisitCount = [];
          let CustomerCount = [];
          response.map((data) => {
            VisitCount.push(data.VisitCount);
            CustomerCount.push(data.CustomerCount);
          });
          let recentVisitDataSet = {
            labels: VisitCount,
            datasets: [
              {
                data: CustomerCount,
                backgroundColor: "transparent",
                borderColor: "#9E52F6",
                borderWidth: 2,
                label: "Visits",
              },
            ],
          };
          this.setState({ recentVisitData: recentVisitDataSet });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getNewVisitorData = () => {
    const { filter, filterDate } = this.state;
    var data = {
      url: "GET_NEW_VISITORS_CHART",
      query: "/" + filterDate.Date + filter.LocationIds,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          let NewDate = [];
          let NewCount = [];
          let RepeatCount = [];
          response.map((data) => {
            NewDate.push(data.Date);
            NewCount.push(data.NewCount);
            RepeatCount.push(data.RepeatCount);
          });
          let newVisitorsDataSet = {
            labels: NewDate,
            datasets: [
              {
                data: NewCount,
                backgroundColor: chartColors[0],
                border: "none",
                borderRadius: 10,
                stack: 1,
                label: "New Visits",
              },
              {
                data: RepeatCount,
                backgroundColor: chartColors[1],
                border: "none",
                borderRadius: 10,
                stack: 1,
                label: "Returning Visitors",
              },
            ],
          };
          this.setState({ newVisitorsData: newVisitorsDataSet });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  // ### filter functions start here ###
  getAllDateFilter = () => {
    const { filterDate, filterData } = this.state;
    var data = {
      url: "GET_DATE_FILTER_LISTS",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          filterData.dateWise = result;
          // date filter
          filterDate.Date = result[0].DashboardDateFilterItemId;
          filterDate.DefaultDate = result[0].DashboardDateFilterItemId;
          filterDate.NumberOfDays = result[0].NumberOfDays;
          filterDate.DefaultDays = result[0].NumberOfDays;
          filterDate.SelectedDays = result[0].NumberOfDays;

          this.setState(
            {
              filterData,
              filterDate,
            },
            () => {
              this.dateFilterList();
            }
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAllCountry = async () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_CONFIG_COUNTRY_GET_ALL",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.countryList = response;
          this.setState({ filterData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAllProvince = () => {
    const { filter, filterData } = this.state;
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];
    if (filter.CountryId) {
      this.setState({
        filter,
        filterData,
        isFilter: "province",
      });
      var data = {
        url: "GET_ALL_PROVINCE",
        body: {
          CountryId: filter.CountryId,
        },
      };
      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            filterData.provinceList = response;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {
          this.setState({ isFilter: "none" });
        }
      );
    } else {
      this.setState({ filter, filterData });
    }
  };

  //Get All Concept for Dropdown Option
  getAllConcept = (type = "load") => {
    const { filter, filterData } = this.state;
    if (type == "filter") {
      if (filter.ProvinceId == "") {
        filter.ConceptId = "";
        filterData.conceptList = [];
        filterData.locationList = [];
      } else {
        filterData.conceptList = filterData.conceptPersistList;
      }
      this.setState({ filter, filterData });
    } else {
      var data = {
        url: "GET_ALL_CONCEPT",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            filterData.conceptPersistList = response;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  getAllLocation = async () => {
    const { filter, filterData } = this.state;
    filterData.locationList = [];
    if (filter.ConceptId == "") {
      this.setState({ filterData });
    } else {
      this.setState({ filterData, isFilter: "location" });
      var data = {
        url: "GET_ALL_LOCATION",
        body: {
          // CountryId:filter.CountryId,
          ProvinceId: filter.ProvinceId,
          ConceptId: filter.ConceptId,
        },
      };

      await apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            let locationList = [];
            response.map((data) => {
              let obj = {
                label: data.LocationName,
                value: data.LocationId,
              };
              locationList.push(obj);
            });
            filterData.locationList = locationList;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {
          this.setState({
            isFilter: "none",
          });
        }
      );
    }
  };

  // filter select change
  selectChange = (event) => {
    const { name, value, data } = event.target;
    const { filter, filterDate } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        if (name == "CountryId") {
          this.getAllProvince();
        } else if (name == "ProvinceId") {
          this.getAllConcept("filter");
        } else if (name == "ConceptId") {
          this.getAllLocation();
        }
      }
    );
  };

  selectDateChange = (event) => {
    const { value, data } = event.target;
    const { filterDate } = this.state;
    filterDate.Date = value;
    filterDate.SelectedDays = this.selectChild(event, "data");
    this.setState({
      filterDate,
    });
  };

  selectChild(e, attr) {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    return optionElement.getAttribute(attr);
  }

  locationSelect(selected) {
    const { filter, filterData } = this.state;
    filterData.locationSelected[filter.ConceptId] = selected;
    filterData.locationSelectedInfo = [];
    filterData.locationSelectedId = [];
    filterData.conceptPersistList.map((cItem, cKey) => {
      if (filterData.locationSelected[cItem.ConceptId]) {
        filterData.locationSelected[cItem.ConceptId].map((lItem, lKey) => {
          filterData.locationSelectedInfo.push(lItem);
          filterData.locationSelectedId.push(lItem.value);
        });
      }
    });
    this.setState({ filterData });
  }

  // button event
  setModal(status) {
    this.setState({
      isModal: status,
    });
  }

  filterDashboard() {
    const { filter, filterDate, filterData } = this.state;
    filter.LocationIds = "";
    if (filterData.locationSelectedId.length > 0) {
      filter.LocationIds =
        "?StoreIds=" + filterData.locationSelectedId.join("&StoreIds=");
    }

    // date
    filterDate.NumberOfDays = filterDate.SelectedDays;

    this.setState(
      {
        filter,
        isModal: false,
      },
      () => {
        this.dateFilterList();
        this.nonDateFilterList();
      }
    );
  }

  resetDashboard() {
    const { filter, filterDate, filterData } = this.state;

    filter.CountryId = "";
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filter.LocationIds = "";

    // date
    filterDate.Date = filterDate.DefaultDate;
    filterDate.NumberOfDays = filterDate.DefaultDays;
    filterDate.SelectedDays = filterDate.DefaultDays;

    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];
    filterData.locationSelected = [];
    filterData.locationSelectedId = [];
    filterData.locationSelectedInfo = [];

    this.setState(
      {
        filter,
        filterData,
        isModal: false,
      },
      () => {
        this.dateFilterList();
        this.nonDateFilterList();
      }
    );
  }

  render() {
    const {
      //render data
      customerRegistration,
      newVisitorCount,
      newVisitorTotalCount,
      newVisitorComparisonPercentage,
      formValue,
      fbValue,
      twitValue,
      googleValue,
      linkedValue,
      instaValue,
      ReturningVisitorsCount,
      ComparisonPercentageReturn,
      locCount,
      busiestDayDisp,
      busiestTimeDisp,
      AccessPointCount,
      SensorCount,
      chartData,
      deviceData,
      loyaltyData,
      recentVisitData,
      newVisitorsData,
      // filter
      filter,
      filterDate,
      filterData,
      isFilter,
      // modal
      isModal,
      isLoading,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-7 poFlex">
              <h1>Dashboard</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Dashboard
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-5">
              <Form className="poForm poFlex poFlexEnd">
                <Button variant="primary" onClick={() => this.setModal(true)}>
                  Filter
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <Row>
              <Col sm={6}>
                <div
                  className={`${styles.pageBody} ${styles.custReg} poc-mb-20`}
                >
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Customer Registration</h2>
                  </div>
                  <div>
                    <Line
                      data={
                        customerRegistration == "" ? {} : customerRegistration
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  className={`${styles.pageBody} ${styles.subSection} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>New Visitors</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img
                        src={common.appIcon("newVisitor")}
                        className={styles.nvImg}
                      />
                    </Col>
                    <Col xs={8}>
                      <h1>{newVisitorCount}</h1>
                      <p>{newVisitorTotalCount} TOTAL</p>
                    </Col>
                  </Row>
                  {newVisitorComparisonPercentage > 0 && (
                    <h5 className={styles.upIcon}>
                      <img src={common.appIcon("triangelUp")} />
                      {newVisitorComparisonPercentage} %
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  )}
                  {newVisitorComparisonPercentage < 0 && (
                    <h5 className={styles.downIcon}>
                      <img src={common.appIcon("triangelDown")} />
                      {newVisitorComparisonPercentage} %
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  )}
                </div>
                <div
                  className={`${styles.pageBody} ${styles.subSection} ${styles.singleT} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>Busiest Day</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img src={common.appIcon("busiestDay")} />
                    </Col>
                    <Col xs={8}>
                      <h1>{busiestDayDisp}</h1>
                    </Col>
                  </Row>
                  <span></span>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  className={`${styles.pageBody} ${styles.subSection} ${styles.singleT1} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>Returning Visitors</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img src={common.appIcon("returningVisitor")} />
                    </Col>
                    <Col xs={8}>
                      <h1>{ReturningVisitorsCount}</h1>
                      <p></p>
                    </Col>
                  </Row>
                  {ComparisonPercentageReturn > 0 && (
                    <h5 className={styles.upIcon}>
                      <img src={common.appIcon("triangelUp")} />
                      {ComparisonPercentageReturn} %
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  )}
                  {ComparisonPercentageReturn < 0 && (
                    <h5 className={styles.downIcon}>
                      <img src={common.appIcon("triangelDown")} />
                      {ComparisonPercentageReturn} %
                      <span>
                        {" "}
                        Compared to previous{" "}
                        <span className="poc-color">
                          {filterDate.NumberOfDays}
                        </span>{" "}
                        days
                      </span>
                    </h5>
                  )}
                </div>
                <div
                  className={`${styles.pageBody} ${styles.subSection} ${styles.singleT} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>Busiest Time</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img src={common.appIcon("busiestTime")} />
                    </Col>
                    <Col xs={8}>
                      <h1>{busiestTimeDisp}</h1>
                    </Col>
                  </Row>
                  <span></span>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  className={`${styles.pageBody} ${styles.subSection} ${styles.singleT} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>Location Count</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img
                        src={common.appIcon("locationCount")}
                        className="lcImg"
                      />
                    </Col>
                    <Col xs={8}>
                      <h1>{locCount}</h1>
                      {/* <p>240 Total</p> */}
                      <p></p>
                    </Col>
                  </Row>
                  <span></span>
                </div>
                <div
                  className={`${styles.pageBody} ${styles.subSection} ${styles.singleT} poc-mb-20`}
                >
                  <div className={styles.titleChart}>
                    <h2>AP & Sensor Count</h2>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <img src={common.appIcon("accessPoint")} />
                    </Col>
                    <Col xs={8}>
                      <h1>
                        {AccessPointCount} | {SensorCount}
                      </h1>
                      {/* <p>240 | 250 Total</p> */}
                      <p></p>
                    </Col>
                  </Row>
                  <span></span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div
                  className={`${styles.pageBody} ${styles.s2Height} poc-mb-20`}
                >
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Customer Visits</h2>
                  </div>
                  <div>
                    <Polar
                      height={290}
                      data={chartData == "" ? {} : chartData}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={3}>
                <div
                  className={`${styles.pageBody} ${styles.progressSection} ${styles.s2Height} poc-mb-20`}
                >
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Authentication Mode</h2>
                  </div>
                  <Row className={`${styles.formSec}`}>
                    <Col sm={2}>
                      <img src={common.appIcon("formIcon")} alt="Form" />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Form</h4>
                        <h5>{formValue}%</h5>
                      </Row>
                      <ProgressBar now={formValue}></ProgressBar>
                    </Col>
                  </Row>
                  <Row className={`${styles.fbSec}`}>
                    <Col sm={2}>
                      <img src={common.appIcon("fbIcon")} alt="Facebook" />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Facebook</h4>
                        <h5>{fbValue}%</h5>
                      </Row>
                      <ProgressBar now={fbValue}></ProgressBar>
                    </Col>
                  </Row>
                  <Row className={`${styles.twitSec}`}>
                    <Col sm={2}>
                      <img src={common.appIcon("twitterIcon")} alt="Twitter" />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Twitter</h4>
                        <h5>{twitValue}%</h5>
                      </Row>
                      <ProgressBar now={twitValue}></ProgressBar>
                    </Col>
                  </Row>
                  <Row className={`${styles.googleSec}`}>
                    <Col sm={2}>
                      <img src={common.appIcon("googleIcon")} alt="Google" />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Google</h4>
                        <h5>{googleValue}%</h5>
                      </Row>
                      <ProgressBar now={googleValue}></ProgressBar>
                    </Col>
                  </Row>
                  <Row className={`${styles.linkedSec}`}>
                    <Col sm={2}>
                      <img
                        src={common.appIcon("linkedinIcon")}
                        alt="Linked in"
                      />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Linkedin</h4>
                        <h5>{linkedValue}%</h5>
                      </Row>
                      <ProgressBar now={linkedValue}></ProgressBar>
                    </Col>
                  </Row>
                  <Row className={`${styles.instaSec}`}>
                    <Col sm={2}>
                      <img src={common.appIcon("instaIcon")} alt="Linked in" />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <h4>Instagram</h4>
                        <h5>{instaValue}%</h5>
                      </Row>
                      <ProgressBar now={instaValue}></ProgressBar>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={6}>
                <div
                  className={`${styles.pageBody} ${styles.s2Height} poc-mb-20`}
                >
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Device</h2>
                  </div>
                  <div>
                    <Bar data={deviceData == "" ? {} : deviceData} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className={`${styles.pageBody} poc-mb-20`}>
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Loyalty</h2>
                  </div>
                  <div>
                    <Line data={loyaltyData == "" ? {} : loyaltyData} />
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className={`${styles.pageBody} poc-mb-20`}>
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>Recent Visits</h2>
                  </div>
                  <div>
                    <Line data={recentVisitData == "" ? {} : recentVisitData} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className={`${styles.pageBody}`}>
                  <div className={styles.titleSection}>
                    <h2 className={styles.pageTitle}>New Visitors</h2>
                  </div>
                  <div>
                    <Bar data={newVisitorsData == "" ? {} : newVisitorsData} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="contentFooter">
          <Modal size="lg" show={isModal}>
            <Modal.Header>
              <Modal.Title>Filter List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="show-grid">
                  <Col xs={12} md={8}>
                    <Form
                      className={styles.filterForm}
                      onSubmit={(event) => event.preventDefault}
                    >
                      <Form.Control
                        as="select"
                        name="Date"
                        value={filterDate.Date}
                        onChange={this.selectDateChange}
                      >
                        {filterData.dateWise.map((item, key) => (
                          <option
                            key={key}
                            value={item.DashboardDateFilterItemId}
                            data={item.NumberOfDays}
                          >
                            {item.Caption}
                          </option>
                        ))}
                      </Form.Control>

                      <Form.Control
                        as="select"
                        name="CountryId"
                        value={filter.CountryId}
                        onChange={this.selectChange}
                      >
                        <option value="">Select Country</option>
                        {filterData.countryList.map((item, key) => {
                          return (
                            <option key={key} value={item.CountryId}>
                              {item.CountryName}
                            </option>
                          );
                        })}
                      </Form.Control>

                      <Form.Control
                        as="select"
                        name="ProvinceId"
                        value={filter.ProvinceId}
                        onChange={this.selectChange}
                      >
                        <option value="">
                          {isFilter == "province"
                            ? "Province Loading..."
                            : "Select Province"}
                        </option>
                        {filterData.provinceList.map((item, key) => {
                          return (
                            <option key={key} value={item.ProvinceId}>
                              {item.ProvinceName}
                            </option>
                          );
                        })}
                      </Form.Control>

                      <Form.Control
                        as="select"
                        name="ConceptId"
                        value={filter.ConceptId}
                        onChange={this.selectChange}
                      >
                        <option value="">Select Concept</option>
                        {filterData.conceptList.map((item, key) => {
                          return (
                            <option key={key} value={item.ConceptId}>
                              {item.ConceptName}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <MultiSelect
                        options={filterData.locationList}
                        value={
                          isFilter != "location" &&
                          filterData.locationSelected[filter.ConceptId]
                        }
                        isLoading={isFilter == "location"}
                        disabled={filterData.locationList.length == 0}
                        onChange={(selected) => this.locationSelect(selected)}
                        overrideStrings={{
                          selectSomeItems:
                            isFilter == "location"
                              ? "Loading Location..."
                              : "Select Location",
                          allItemsAreSelected: "All locations are selected.",
                          selectAll: "Select All Location",
                          search: "Search Location",
                        }}
                      />
                    </Form>
                  </Col>
                  {filterData.locationSelectedInfo.length > 0 ? (
                    <Col xs={6} md={4} className={styles.filterLocationList}>
                      <p>Your Selected Locations</p>
                      <ul>
                        {filterData.locationSelectedInfo.map((item, key) => {
                          return <li key={key}>{item.label}</li>;
                        })}
                      </ul>
                    </Col>
                  ) : (
                    <Col xs={6} md={4} className={styles.filterLocationNone}>
                      <p>No Location Selected</p>
                    </Col>
                  )}
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary config-btn"
                onClick={() => this.filterDashboard()}
              >
                Filter
              </Button>
              <Button
                variant="primary config-btn"
                onClick={() => this.resetDashboard()}
              >
                Reset
              </Button>
              <Button
                variant="primary config-btn"
                onClick={() => this.setModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export { Dashboard };
