import React from "react";
import { Button, Form, Table, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
// _includes
import { common } from "_helpers";
import { ExportCSV } from "_includes";
import { apiServices } from "_api";
import FilterVisit from "../includes/FilterVisit";

class ReportVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      column: 11,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      isModal: false,
    };
  }

  componentDidMount = async () => {
    if (!common.isAccess("CUSTOMERVISITLOGREPORT_PAGEACCESS")) {
      common.accessDenied();
    } else {
      this.loadData();
    }
  };

  loadData = () => {
    const { paging } = this.state;
    const { filter } = this.filter.state;

    var data = {
      url: "REPORT_CUSTOMER_VISIT_GET_TABLE",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: filter,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
      },
      () => {
        this.filter.resetForm();
      }
    );
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  filterReset() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  render() {
    const {
      status,
      data,
      paging,
      isLoading,
      column
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-12 poFlex">
              <h1>Customer Visit Log</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Customer Visit Log
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    <span className="potableHeadTitle">Customer Visit Log</span>
                    {common.isAccess("CUSTOMERVISITLOGREPORT_EXPORT") && (
                      <> - <ExportCSV type="report_visit" name="customer_visit_log" /></>
                    )}
                  </div>
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="poTableIcon">
                        <img
                          src={common.appIcon("FilterIcon")}
                          className="poc-cp"
                          alt="Filter"
                          onClick={() => this.filterModal(true)}
                        />

                        <img
                          src={common.appIcon("RefreshIcon")}
                          className="poc-cp"
                          alt="Refresh"
                          onClick={() => this.handleRefresh()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <span className="poTableFetcher">
                      fetching data please wait...
                    </span>
                  </div>
                )}

                <div className={isLoading ? "poTableOnLoad" : ""}>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>DOB</th>
                        <th>Gender</th>
                        <th>Mac Address</th>
                        <th>Location</th>
                        <th>Registered Date</th>
                        <th>Visited Date</th>
                        <th>Visit Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {status == "process" ? (
                        <tr className="poTableMsg">
                          <td colSpan={column}>Loading data please wait...</td>
                        </tr>
                      ) : status == "error" ? (
                        <tr className="poTableMsg">
                          <td colSpan={column}>
                            There was an error while fetching record.
                          </td>
                        </tr>
                      ) : data.RecordsCount == 0 ? (
                        <tr className="poTableMsg">
                          <td colSpan={column}>Sorry no record found.</td>
                        </tr>
                      ) : data.result.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.serial}</td>
                            <td>{item.CustomerName}</td>
                            <td>{item.Email}</td>
                            <td>{item.MobileNumber}</td>
                            <td>{item.DateOfBirth}</td>
                            <td>{item.Gender}</td>
                            <td>{item.DeviceMacAddress}</td>
                            <td>{item.Location}</td>
                            <td>{item.RegisteredOn}</td>
                            <td>{item.VisitedOn}</td>
                            <td>{item.VisitType}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {data.RecordsCount > 0 && (
                    <div className="d-flex">
                      <div className="mr-auto p-2">
                        Total Records: {data.RecordsCount}
                      </div>
                      <div className="p-2">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={paging.pageIndex}
                          itemsCountPerPage={paging.pageSize}
                          totalItemsCount={data.RecordsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FilterVisit
          type={"visit"}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export { ReportVisit };
