import React from "react";
import { FormControl, Button, InputGroup } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ImageUploader from "react-images-upload";
import moment from "moment";
// _includes
import { apiServices } from "_api";
import { StatusBar } from "_includes";
import { common, tools } from "_helpers";
import Sortable from "../includes/Sortable";
// css
import styles from "pages/Administration/css/Admin.module.css";
// pages
import BannerLocation from "./BannerLocation";

export default class BannerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "banner",
      categoryStatus: "process",
      bannerStatus: "process",
      isCategoryLoading: false,
      isBannerLoading: false,
      isBannerArrangeLoading: false,
      categoryId: "",
      categoryName: "",
      fields: {
        categoryName: "",
        image: [],
      },
      deleteInfo: {
        categoryId: "",
        bannerId: "",
      },
      // data
      bannerCategoryList: [],
      bannerList: [],
      storeList: [],
      // crud
      crudAccess: {
        add: false,
        delete: false,
        store: false,
      }
    };
  }

  componentDidMount = async () => {
    this.crudAccess();
    this.getBannerCategory();
  };

  getBannerCategory = () => {
    this.setStatus("process", "cat");
    var data = {
      url: "BANNER_CATEGORY_GET_ALL",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          if (result.length == 0) {
            this.setState(
              {
                categoryStatus: "empty",
                categoryId: "",
                bannerCategoryList: [],
              },
              () => {
                this.getBannerCategoryById();
              }
            );
          } else {
            this.setState(
              {
                categoryStatus: "success",
                categoryId: result[0].BannerCategoryId,
                categoryName: result[0].BannerCategoryName,
                bannerCategoryList: result,
              },
              () => {
                this.getBannerCategoryById();
              }
            );
          }
        } else {
          this.setStatus("error", "cat");
          this.setStatus("empty", "ban");
        }
      },
      (error) => {
        this.setStatus("error", "cat");
        this.setStatus("empty", "ban");
      },
      (final) => { }
    );
  };

  getBannerCategoryById = () => {
    this.setStatus("process", "ban");
    const { categoryId } = this.state;
    if (categoryId == "") {
      this.setState({
        bannerStatus: "empty",
        bannerList: "",
      });
    } else {
      var data = {
        url: "BANNER_CATEGORY_BY_ID",
        query: "/" + categoryId,
      };
      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            var result = response.data;
            if (result.length == 0) {
              this.setState({
                bannerStatus: "empty",
                bannerList: [],
              });
            } else {
              this.setState({
                bannerStatus: "success",
                bannerList: result,
              });
            }
          } else {
            this.setStatus("error", "ban");
          }
        },
        (error) => {
          this.setStatus("error", "ban");
        },
        (final) => { }
      );
    }
  };

  getBannerList(key) {
    const { bannerCategoryList } = this.state;
    this.setState(
      {
        type: "banner",
        categoryId: bannerCategoryList[key].BannerCategoryId,
        categoryName: bannerCategoryList[key].BannerCategoryName,
      },
      () => {
        this.getBannerCategoryById();
      }
    );
  }

  getLocationList(key) {
    const { bannerCategoryList } = this.state;
    let categoryId = bannerCategoryList[key].BannerCategoryId;
    let categoryName = bannerCategoryList[key].BannerCategoryName;
    this.setState(
      {
        type: "location",
        categoryId,
        categoryName,
      },
      () => {
        this.BannerLocation.getBannerLocation(categoryId, categoryName);
      }
    );
  }

  setStatus(status, type) {
    if (type == "cat") {
      this.setState({
        categoryStatus: status,
      });
    } else if (type == "ban") {
      this.setState({
        bannerStatus: status,
      });
    }
  }

  setLoading(status, type) {
    if (type == "cat") {
      this.setState({
        isCategoryLoading: status,
      });
    } else if (type == "ban") {
      this.setState({
        isBannerLoading: status,
      });
    } else if (type == "arr") {
      this.setState({
        isBannerArrangeLoading: status,
      });
    }
  }

  setDelete(id, type) {
    const { deleteInfo } = this.state;
    if (type == "cat") {
      deleteInfo.categoryId = id;
      this.setState({
        deleteInfo,
      });
    } else if (type == "ban") {
      deleteInfo.bannerId = id;
      this.setState({
        deleteInfo,
      });
    }
  }

  // *** CRUD ***
  // Banner Category
  onCategorySubmit = () => {
    const { fields } = this.state;
    let isValid = true;

    if (fields.categoryName == "") {
      common.snack("E", "Please enter the category name");
      isValid = false;
    }

    if (isValid) {
      this.setLoading(true, "cat");
      var data = {
        url: "BANNER_CATEGORY_PERSIST",
        body: {
          BannerCategoryName: fields.categoryName,
        },
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            common.snack("S", "New category has been added!");
            fields.categoryName = "";
            this.setState({ fields });
            this.getBannerCategory();
          } else {
            common.snack("E", "There was an error while adding category");
          }
        },
        (error) => {
          common.snack("E", "There was an error while adding category");
        },
        (final) => {
          this.setLoading(false, "cat");
        }
      );
    }
  };

  deleteCategory(id) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setDelete(id, "cat");
            var data = {
              url: "BANNER_CATEGORY_DELETE",
              query: "/" + id,
            };
            apiServices.call(
              data,
              (response) => {
                if (this.crudStatus(response.status)) {
                  common.snack("S", "Category has been deleted!");
                  this.getBannerCategory();
                } else {
                  common.snack(
                    "E",
                    "There was an error while delete a category"
                  );
                }
              },
              (error) => {
                common.snack("E", "There was an error while delete a category");
              },
              (final) => {
                this.setDelete("", "cat");
              }
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteBanner(id) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this banner?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setDelete(id, "ban");
            var data = {
              url: "BANNER_DELETE",
              query: "/" + id,
            };
            apiServices.call(
              data,
              (response) => {
                if (this.crudStatus(response.status)) {
                  common.snack("S", "Banner has been deleted!");
                  this.getBannerCategoryById();
                } else {
                  common.snack("E", "There was an error while delete a banner");
                }
              },
              (error) => {
                common.snack("E", "There was an error while delete a banner");
              },
              (final) => {
                this.setDelete("", "ban");
              }
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    fields[name] = value;
    this.setState({
      fields: fields,
    });
  };

  // ImageUploader
  onDrop = (image) => {
    const { fields } = this.state;
    fields.image = image;
    this.setState(
      {
        fields,
      },
      () => this.uploadBannerImage()
    );
  };

  // Drag & Drop
  uploadBannerImage = () => {
    this.setLoading(true, "ban");
    const { categoryId, fields } = this.state;
    // form data
    let formData = new FormData();
    fields.image.map((item) => {
      formData.append("files[]", item);
    });
    formData.append("BannerCategoryId", categoryId);

    var data = {
      url: "BANNER_IMAGE_UPLOAD",
      body: formData,
      contentType: 3,
    };

    apiServices.call(
      data,
      (response) => {
        if (this.crudStatus(response.status)) {
          common.snack("S", "Image has been deleted!");
          this.getBannerCategoryById();
        } else {
          common.snack("E", "There was an error while upload an image");
        }
      },
      (error) => { },
      (final) => {
        fields.image = [];
        this.setState({ fields });
        this.setLoading(false, "ban");
      }
    );
  };

  bannerIndexUpdate = (data) => {
    this.setLoading(true, "arr");
    var data = {
      url: "BANNER_UPDATE_INDEX",
      body: data,
      contentType: 1,
    };
    apiServices.call(
      data,
      (response) => {
        if (this.crudStatus(response.status)) {
          common.snack("S", "Banner position has been changed");
          this.getBannerCategoryById();
        } else {
          common.snack("E", "There was an error while chaning position");
        }
      },
      (error) => {
        common.snack("E", "There was an error while chaning position");
      },
      (final) => {
        this.setLoading(false, "arr");
      }
    );
  };

  // module & page access
  crudAccess() {
    const { crudAccess } = this.state;
    (common.isAccess("BANNERCATEGORY_ADD") && (crudAccess.add = true));
    (common.isAccess("BANNERCATEGORY_DELETE") && (crudAccess.delete = true));
    (common.isAccess("BANNERCATEGORY_ASSIGNSTORE") && (crudAccess.store = true));
    this.setState({
      crudAccess
    })
  }

  render() {
    const {
      type,
      fields,
      categoryId,
      categoryName,
      categoryStatus,
      bannerStatus,
      isCategoryLoading,
      isBannerLoading,
      isBannerArrangeLoading,
      deleteInfo,
      // data
      bannerCategoryList,
      bannerList,
      crudAccess
    } = this.state;
    return (
      <div className="row">
        <div className="col-sm-3">
          <div className="contentBox">
            {/* Banner Category List*/}
            <div className={styles.vBanCatInp}>
              <h2>Banner Category</h2>
              {crudAccess.add && (
                <InputGroup>
                  <FormControl
                    type="text"
                    name="categoryName"
                    value={fields.categoryName}
                    placeholder="Add New Category"
                    onChange={this.handleOnChange}
                  />
                  <InputGroup.Append>
                    <Button
                      type="button"
                      disabled={isCategoryLoading}
                      onClick={() => this.onCategorySubmit()}
                    >
                      {isCategoryLoading ? "Wait..." : "Submit"}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </div>
            <StatusBar status={categoryStatus} />
            {categoryStatus == "success" &&
              bannerCategoryList.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={`${styles.vbCatList} ${deleteInfo.categoryId && "poc-none"
                      }`}
                  >
                    <div className={`${styles.vbCatBox} row`}>
                      <div className="col-sm-3 pr-0">
                        <img
                          src={common.appIcon("folderIcon")}
                          className={`${styles.vbCatIcon}`}
                        />
                      </div>
                      <div
                        className="col-sm-7 pr-0 poc-cp"
                        onClick={() => this.getBannerList(key)}
                      >
                        <h3>{item.BannerCategoryName}</h3>
                        <p>{moment(item.ModifiedOn).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className={`${styles.vbCatActionBox} col-sm-2 p-0`}>
                        <ul className={`${styles.vbCatActionList}`}>
                          <li>
                            {crudAccess.store && (
                              <img
                                src={common.loadImg("administration/store.png")}
                                style={{ width: "77%" }}
                                className="poc-cp"
                                onClick={() => this.getLocationList(key)}
                              />
                            )}
                          </li>
                          <li>
                            {crudAccess.delete && (
                              <img
                                src={common.loadImg(
                                  "administration/delete_icon.png"
                                )}
                                style={{ width: "90%" }}
                                onClick={() =>
                                  this.deleteCategory(item.BannerCategoryId)
                                }
                              />
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="col-sm-9">
          <div className="contentBox">
            {type == "location" ? (
              <BannerLocation
                ref={(instance) => {
                  this.BannerLocation = instance;
                }}
              />
            ) : (
                <div>
                  {categoryId != "" && (
                    <div>
                      <div>
                        <h2 className={styles.vbCatHeader}>{categoryName}</h2>
                        <div className={`${styles.vbFileUploader}`}>
                          <ImageUploader
                            withIcon={true}
                            buttonText={
                              isBannerLoading
                                ? "Uploading image..."
                                : "Choose images"
                            }
                            onChange={this.onDrop}
                            accept="image/*"
                            withIcon={true}
                            maxFileSize={5242880}
                            fileContainerStyle={{
                              background: "#f6f6f6",
                            }}
                            className={isBannerLoading ? "poc-none" : ""}
                            singleImage={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <StatusBar status={bannerStatus} />
                  {bannerStatus == "success" && (
                    <div>
                      <div
                        className={
                          isBannerArrangeLoading || deleteInfo.bannerId != ""
                            ? "poc-none"
                            : ""
                        }
                      >
                        <Sortable
                          id="container"
                          onDrag={(data) => this.bannerIndexUpdate(data)}
                        >
                          {bannerList.map((item, key) => (
                            <div
                              draggable="true"
                              className="col-sm-3"
                              ref={item.BannerId}
                              key={key}
                            >
                              <div className={`${styles.vbGridImage}`}>
                                <img
                                  src={item.FileName}
                                  className="lazyload"
                                  className={styles.vbGridBanner}
                                />
                                <span className={`${styles.vbGridCount}`}>
                                  {key + 1}
                                </span>
                                <span className={`${styles.vbGridDrag}`}>
                                  <img
                                    src={common.loadImg(
                                      "administration/dragGrid.png"
                                    )}
                                  />
                                </span>
                                <span
                                  className={`${styles.vbGridDelete}`}
                                  onClick={() => this.deleteBanner(item.BannerId)}
                                >
                                  <img
                                    src={common.loadImg(
                                      "administration/deleteGrid.png"
                                    )}
                                  />
                                </span>
                              </div>
                            </div>
                          ))}
                        </Sortable>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
