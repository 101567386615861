import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
// _import
import { common, history } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class LocationCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Location",
      parent: "/admin/config/location",
      status: "process",
      formSubmit: false,
      isLoading: false,
      isDataLoading: {
        locationType: true,
        country: true,
        province: false,
        concept: true
      },
      fields: {
        LocationName: "",
        Description: "",
        LocationCode: "",
        LocationTypeId: "",
        CountryId: "",
        ProvinceId: "",
        ConceptId: ""
      },
      errors: {
        LocationName: {
          error: "",
          isReq: "Please enter the location name",
        },
        LocationCode: {
          error: "",
          isReq: "Please enter the location code",
        },
        LocationTypeId: {
          error: "",
          isReq: "Please select the location type",
        },
        CountryId: {
          error: "",
          isReq: "Please select the Country",
        },
        ProvinceId: {
          error: "",
          isReq: "Please select the Province",
        },
        ConceptId: {
          error: "",
          isReq: "Please select the concept",
        }
      },
      // data
      persistData: [],
      locationTypeList: [],
      locationTypeSelected: [],
      countryList: [],
      countrySelected: [],
      provinceList: [],
      provinceSelected: [],
      conceptList: [],
      conceptSelected: [],
    };
  }

  componentDidMount() {
    if(this.crudAccess()){
      this.initCheck();
    }
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.setState({ status: "success" })
      this.fetchRecord();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_CONFIG_LOCATION_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            status: "success",
            fields: {
              LocationName: result.LocationName,
              Description: result.Description,
              LocationCode: result.LocationCode,
            },
            persistData: {
              LocationTypeId: result.LocationTypeId,
              CountryId: result.CountryId,
              ProvinceId: result.ProvinceId,
              ConceptId: result.ConceptId,
            },
          }, () => {
            this.fetchRecord();
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  fetchRecord() {
    this.getAllLocationType();
    this.getAllCountry();
    this.getAllConcept();
  }

  getAllLocationType = () => {
    const { fields, persistData } = this.state;
    this.setDataLoading("locationType", true);
    var data = {
      url: "ADMIN_CONFIG_LOCATION_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let locationTypeList = [];
          let locationTypeSelected = [];

          result.map((item) => {
            let obj = {
              label: item.Value,
              value: item.Id,
            };
            if (persistData.LocationTypeId == item.Id) {
              locationTypeSelected.push(obj);
              fields.LocationTypeId = obj;
            }
            locationTypeList.push(obj);
          });
          this.setState({
            fields,
            locationTypeList,
            locationTypeSelected,
          });
        }
      },
      (error) => {
      },
      (final) => {
        this.setDataLoading("locationType", false);
      }
    );
  };

  getAllCountry = async () => {
    const { params, fields, persistData } = this.state;
    this.setDataLoading("country", true);
    var data = {
      url: "ADMIN_CONFIG_COUNTRY_GET_ALL",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          let countryList = [];
          let countrySelected = [];
          response.map((data) => {
            let obj = {
              label: data.CountryName,
              value: data.CountryId,
            };
            if (persistData.CountryId == data.CountryId) {
              countrySelected.push(obj);
              fields.CountryId = obj;
            }
            countryList.push(obj);
          });
          this.setState(
            {
              fields,
              countryList,
              countrySelected
            },
            () => {
              if (params.id) {
                this.getAllProvince("load");
              }
            }
          );
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("country", false);
      }
    );
  };

  getAllProvince = (type) => {
    const { fields, persistData } = this.state;
    this.setDataLoading("province", true);
    fields.ProvinceId = "";
    this.setState({ fields });

    var data = {
      url: "ADMIN_CONFIG_PROVINCE_GET_ALL",
      body: {
        CountryId: fields.CountryId.value,
      },
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let provinceList = [];
          let provinceSelected = [];
          result.map((item) => {
            let obj = {
              label: item.ProvinceName,
              value: item.ProvinceId,
            };
            if (type == "load") {
              if (persistData.ProvinceId == item.ProvinceId) {
                provinceSelected.push(obj);
                fields.ProvinceId = obj;
              }
            }
            provinceList.push(obj);
          });
          this.setState({
            fields,
            provinceList,
            provinceSelected,
          });
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("province", false);
      }
    );
  };

  //Get All Concept for Dropdown Option
  getAllConcept = () => {
    const { persistData, fields } = this.state;
    this.setDataLoading("concept", true);
    var data = {
      url: "ADMIN_CONFIG_CONCEPT_GET_ALL",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          var result = response.data;
          let conceptList = [];
          let conceptSelected = [];
          result.map((item) => {
            let obj = {
              label: item.ConceptName,
              value: item.ConceptId,
            };
            if (persistData.ConceptId == item.ConceptId) {
              conceptSelected.push(obj);
              fields.ConceptId = obj;
            }
            conceptList.push(obj);
          });
          this.setState({
            fields,
            conceptList,
            conceptSelected
          });
        }
      },
      (error) => { },
      (final) => {
        this.setDataLoading("concept", false);
      }
    );
  };

  setDataLoading(type, status) {
    const { isDataLoading } = this.state;
    isDataLoading[type] = status
    this.setState({
      isDataLoading
    })
  }

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    fields[name] = data;
    this.setState(
      {
        fields,
      },
      () => {
        if (name == "CountryId") {
          this.getAllProvince("change");
        }
        this.validateForm();
      }
    );
  }

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var bodayData = {
        LocationName: fields.LocationName,
        LocationCode: fields.LocationCode,
        Description: fields.Description,
        LocationTypeId: fields.LocationTypeId.value,
        ConceptId: fields.ConceptId.value,
        ProvinceId: fields.ProvinceId.value,
      }

      var data = {};
      if (params.type == "add") {
        data = {
          url: "ADMIN_CONFIG_LOCATION_PERSIST",
          query: "?IsAdd=true",
          body: bodayData,
          method: "POST",
        };
      } else {
        bodayData.LocationId = params.id;
        data = {
          url: "ADMIN_CONFIG_LOCATION_PERSIST",
          query: "?IsAdd=false",
          body: bodayData,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type=="add" && common.isAccess("LOCATIONCONFIGURATION_ADD")) && (allow=true);
    (params.type=="edit" && common.isAccess("LOCATIONCONFIGURATION_EDIT")) && (allow=true);
    if(!allow){
      common.accessDenied();
    }
    return allow;
  }
  
  render() {
    const {
      params,
      status,
      isLoading,
      isDataLoading,
      fields,
      errors,
      formSubmit,
      // data
      locationTypeList,
      locationTypeSelected,
      countryList,
      countrySelected,
      provinceList,
      provinceSelected,
      conceptList,
      conceptSelected,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Location</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add Location" : "Update Location"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add Location" : "Update Location"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Location Name
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="LocationName"
                          placeholder="Location Name"
                          value={fields.LocationName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.LocationName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LocationName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Location Code
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="LocationCode"
                          placeholder="Location Code"
                          value={fields.LocationCode}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.LocationCode.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LocationCode.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Location Type
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.locationType ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading location type please wait..."
                            readOnly={true}
                          />
                        ) :
                          locationTypeList.length > 0 ? (
                            <Select
                              options={locationTypeList}
                              defaultValue={locationTypeSelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "LocationTypeId")
                              }
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.LocationTypeId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LocationTypeId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Select Country
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.country ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading country please wait..."
                            readOnly={true}
                          />
                        ) :
                          countryList.length > 0 ? (
                            <Select
                              options={countryList}
                              defaultValue={countrySelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "CountryId")
                              }
                              value={fields.CountryId}
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.CountryId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.CountryId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="province" className="mb-3">
                      <Form.Label column sm="3">
                        Province Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.province ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading province please wait..."
                            readOnly={true}
                          />
                        ) :
                          <Select
                            options={provinceList}
                            defaultValue={provinceSelected}
                            onChange={(data) =>
                              this.handleSelectChange(data, "ProvinceId")
                            }
                            value={fields.ProvinceId}
                          />
                        }
                        {errors.ProvinceId.error && formSubmit && (
                          <span className="error">{errors.ProvinceId.error}</span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="concept" className="mb-3">
                      <Form.Label column sm="3">
                        Concept Name<span className="poc-color">*</span>
                      </Form.Label>
                      <Col sm="4">
                        {isDataLoading.concept ? (
                          <Form.Control
                            type="text"
                            placeholder="Loading concept please wait..."
                            readOnly={true}
                          />
                        ) :
                          conceptList.length > 0 ? (
                            <Select
                              options={conceptList}
                              defaultValue={conceptSelected}
                              onChange={(data) =>
                                this.handleSelectChange(data, "ConceptId")
                              }
                              value={fields.ConceptId}
                            />
                          ) : (
                              <Form.Control
                                type="text"
                                placeholder="No record found"
                                readOnly={true}
                              />
                            )
                        }
                        {errors.ConceptId.error && formSubmit && (
                          <span className="error">{errors.ConceptId.error}</span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading ? "Processing..." : params.type == "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { LocationCrud };
