import React from 'react';
// Import Component
import { Menu, Header, Footer } from '../../../_components';

//Import CSS
import styles from './AdminLayout.module.css';

class AdminLayout extends React.Component {
    render() {
        return (
            <div className={styles.adminLayout}>
                <Menu/>
                <div className={styles.containerWrapper} id="adminLayout">
                    <Header />
                    {this.props.children}               
                    <Footer />
                </div>
            </div>
        )
    }
}
export { AdminLayout };