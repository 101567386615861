import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import ImageUploader from "react-images-upload";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class AdminBackgroundCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    params.type = "add";
    this.state = {
      params,
      title: "Background Image",
      parent: "/admin/visualize/background",
      status: "process",
      isLoading: false,
      fields: {
        BackgroundTypeId: "",
        BackgroundImageName: "",
        ImageFileName: "",
      },
      errors: {
        BackgroundTypeId: {
          error: "",
          isReq: "Please select background type",
        },
        BackgroundImageName: {
          error: "",
          isReq: "Please enter image name",
        },
        ImageFileName: {
          error: "",
          isReq: "Please select image",
        },
      },
      sData: {
        backgroundTypeList: [],
      },
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getBackgroundTypeList();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState(
            {
              fields: {
                BackgroundImageName: result.BackgroundImageName,
                BackgroundTypeId: result.BackgroundTypeId,
                ImageFileName: result.ImageFileName,
              },
            },
            () => {
              this.getBackgroundTypeList();
            }
          );
        } else {
          this.setState({
            status: "error",
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  getBackgroundTypeList = () => {
    const { sData } = this.state;
    var data = {
      url: "ADMIN_VISUALIZE_BACKGROUND_GET_TYPE",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          sData.backgroundTypeList = response;
          this.setState({
            status: "success",
            sData,
          });
        } else {
          this.setState({
            status: "error",
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // ImageUploader
  onDrop = (image) => {
    const { fields } = this.state;
    fields.ImageFileName = image[0];
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var formdata = new FormData();
      formdata.append("BackgroundTypeId", fields.BackgroundTypeId);
      formdata.append("BackgroundImageName", fields.BackgroundImageName);
      formdata.append("file", fields.ImageFileName);
      var data = {};
      if (params.type == "add") {
        data = {
          url: "ADMIN_VISUALIZE_BACKGROUND_PERSIST",
          query: "?IsAdd=true",
          body: formdata,
          method: "POST",
          contentType: 3,
        };
      } else {
        fields.UserInfoId = params.id;
        data = {
          url: "ADMIN_VISUALIZE_BACKGROUND_PERSIST",
          query: "?IsAdd=false",
          body: formdata,
          method: "POST",
          contentType: 3,
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type == "add" && common.isAccess("BACKGROUNDIMAGE_ADD")) && (allow = true);
    (params.type == "edit" && common.isAccess("BACKGROUNDIMAGE_EDIT")) && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      sData,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Background Image</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add"
                      ? "Add Background Image"
                      : "Update Background Image"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add"
                    ? "Add Background Image"
                    : "Update Background Image"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div
                    className={`${
                      isLoading && "poc-none"
                    } pad-20 bottom-border`}
                  >
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Background Type
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          name="BackgroundTypeId"
                          value={fields.BackgroundTypeId}
                          onChange={(e) => this.handleOnChange(e)}
                        >
                          <option value="">Select Background Type</option>
                          {sData.backgroundTypeList.map((item, key) => {
                            return (
                              <option key={key} value={item.Id}>
                                {item.Value}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {errors.BackgroundTypeId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.BackgroundTypeId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Image Name
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="BackgroundImageName"
                          placeholder="Background Image Name"
                          value={fields.BackgroundImageName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.BackgroundImageName.error !== "" &&
                          formSubmit && (
                            <span className="error">
                              {errors.BackgroundImageName.error}
                            </span>
                          )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Upload Image
                      </Form.Label>
                      <Col sm="4">
                        <ImageUploader
                          withIcon={true}
                          buttonText="Upload Image"
                          onChange={this.onDrop}
                          accept="image/*"
                          withIcon={true}
                          maxFileSize={5242880}
                          fileContainerStyle={{
                            background: "#f6f6f6",
                          }}
                          singleImage={true}
                          withPreview={true}
                        />
                        {errors.ImageFileName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.ImageFileName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading
                            ? "Processing..."
                            : params.type == "add"
                            ? "Save"
                            : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { AdminBackgroundCrud };
