import moment from "moment";
import SnackbarUtils from "_includes/SnackBar";
// Icon
import profileImg from "asset/img/profileImg.jpg";
import profileBanner from "asset/img/profileBanner.jpg";
import FilterIcon from "asset/img/filter.png";
import FilterSelect from "asset/img/filter_select.png";
import RefreshIcon from "asset/img/refresh.png";
import EditIcon from "asset/img/edit.png";
import DelIcon from "asset/img/del.png";
import DelAllActIcon from "asset/img/del_all_active.png";
import DelAllNoneIcon from "asset/img/del_all_none.png";
import duplicateUsergroup from "asset/img/duplicate_usergroup.png";
import lockIcon from "asset/img/lock.png";
import lockSelect from "asset/img/lock_open.png";

import triggerCalender from "asset/img/trigger_calender.png";
import triggerClock from "asset/img/trigger_clock.png";
import socialMarketing from "asset/img/socialmediamarketing.png";
import fbButton from "asset/img/fb_button.png";
import googleButton from "asset/img/google_button.png";
import linkedinButton from "asset/img/linkedin_button.png";
import twitterButton from "asset/img/twitter_button.png";

import fbIcon from "asset/img/dashboard/fb.png";
import formIcon from "asset/img/dashboard/form.png";
import googleIcon from "asset/img/dashboard/google.png";
import instaIcon from "asset/img/dashboard/insta.png";
import linkedinIcon from "asset/img/dashboard/linkedin.png";
import twitterIcon from "asset/img/dashboard/twitter.png";
import newVisitor from "asset/img/dashboard/new-visitor.png";
import returningVisitor from "asset/img/dashboard/returning-visitor.png";
import locationCount from "asset/img/dashboard/location-count.png";
import busiestDay from "asset/img/dashboard/busiest-day.png";
import busiestTime from "asset/img/dashboard/busiest-time.png";
import accessPoint from "asset/img/dashboard/access-point.png";
import triangelUp from "asset/img/dashboard/triangle-up.png";
import triangelDown from "asset/img/dashboard/triangle-down.png";

// Icon:analytics
import registered_connected from "asset/img/analytics/registered_connected.png";
import registered_notconnected from "asset/img/analytics/registered_notconnected.png";
import visual_merchandising from "asset/img/analytics/visual_merchandising.png";
import visual_merchandising_bg from "asset/img/analytics/visual_merchandising_bg.png";
import new_registration from "asset/img/analytics/new_registration.png";
import anonymous from "asset/img/analytics/anonymous.png";
import bounce_customers from "asset/img/analytics/bounce_customers.png";
import averagetimespents from "asset/img/analytics/averagetimespents.png";

// zone:overview
import az_ov_visitor from "asset/img/analytics/zone/visitor.png";
import az_ov_visitors from "asset/img/analytics/zone/visitors.png";
import az_ov_average_vist from "asset/img/analytics/zone/average_vist.png";
import az_ov_bounced_visits from "asset/img/analytics/zone/bounced_visits.png";
import az_ov_engaged_visit from "asset/img/analytics/zone/engaged_visit.png";
import az_ov_indentified_visitor from "asset/img/analytics/zone/indentified_visitor.png";
import az_ov_missed_visits from "asset/img/analytics/zone/missed_visits.png";
import az_ov_new_visitor from "asset/img/analytics/zone/new_visitor.png";
import az_ov_repeat_visitor from "asset/img/analytics/zone/repeat_visitor.png";

// Others
import po_ac_activity_signup from "asset/img/analytics/activity_signup.svg";
import po_ac_activity_wifi from "asset/img/analytics/activity_wifi.svg";
import po_ac_activity_bell from "asset/img/analytics/activity_bell.svg";
import po_ac_activity_email from "asset/img/analytics/activity_email.svg";
import po_ac_activity_visit from "asset/img/analytics/activity_visit.svg";

//Administration: banner list
import folderIcon from "asset/img/administration/folder_icon.png";
import storeIcon from "asset/img/administration/store.png";
import uploadBanner from "asset/img/administration/uploadbanners.png";
import changePassword from "asset/img/administration/change_password.png";

//Administration: background image
import storeBackgroundIcon from "asset/img/administration/store_background.png";
import viewDetailsIcon from "asset/img/administration/viewdetail.png";
import { Redirect } from "react-router-dom";

const iconSet = {
  profileImg,
  profileBanner,
  FilterIcon,
  FilterSelect,
  RefreshIcon,
  EditIcon,
  DelIcon,
  DelAllActIcon,
  DelAllNoneIcon,
  fbIcon,
  formIcon,
  googleIcon,
  instaIcon,
  linkedinIcon,
  twitterIcon,
  newVisitor,
  returningVisitor,
  locationCount,
  busiestDay,
  busiestTime,
  accessPoint,
  triangelUp,
  triangelDown,
  duplicateUsergroup,
  lockIcon,
  lockSelect,
  registered_connected,
  registered_notconnected,
  new_registration,
  anonymous,
  visual_merchandising,
  visual_merchandising_bg,
  bounce_customers,
  averagetimespents,
  az_ov_visitor,
  az_ov_visitors,
  az_ov_average_vist,
  az_ov_bounced_visits,
  az_ov_engaged_visit,
  az_ov_indentified_visitor,
  az_ov_missed_visits,
  az_ov_new_visitor,
  az_ov_repeat_visitor,
  // others
  po_ac_activity_signup,
  po_ac_activity_wifi,
  po_ac_activity_bell,
  po_ac_activity_email,
  po_ac_activity_visit,
  //Administration: banner list
  folderIcon,
  storeIcon,
  uploadBanner,
  changePassword,
  //Administration: background image
  storeBackgroundIcon,
  viewDetailsIcon,
  //Marketing
  triggerCalender,
  triggerClock,
  socialMarketing,
  fbButton,
  googleButton,
  linkedinButton,
  twitterButton,
};

export const common = {
  // basic
  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  // intermediate
  localeDate(date, type) {
    var myDate = moment(date);
    if (!myDate.isValid()) {
      return "invalid date";
    } else {
      if (type === "list") {
        return moment(myDate).format("DD MMMM YYYY H:m A");
      }
    }
  },

  appIcon(icon) {
    return iconSet[icon];
  },

  loadImg(img) {
    return require("../asset/img/" + img);
  },

  loadWeServ(img, w, h) {
    return "https://images.weserv.nl/?url=" + img + "&w=" + w + "&h=" + h;
  },

  snack(type, msg = "") {
    switch (type) {
      case "S":
        SnackbarUtils.success(msg);
        break;
      case "E":
        SnackbarUtils.error(msg);
        break;
      case "W":
        SnackbarUtils.warning(msg);
        break;
      case "I":
        SnackbarUtils.info(msg);
        break;
      case "T":
        SnackbarUtils.toast(msg);
        break;
      case "C":
        SnackbarUtils.closeAll();
        break;
    }
  },

  checkToken(callback) {
    callback(1);
  },

  apiError(response) {
    if (response.status == 400) {
      if (typeof response.data == "object") {
        for (const property in response.data.errors) {
          this.snack("E", response.data.errors[property][0]);
          break;
        }
      } else {
        this.snack("E", response.data);
      }
    } else {
      this.showMessage("unknown");
    }
  },

  showMessage(type) {
    switch (type) {
      case "unknown":
        this.snack("E", "Something went wrong please try again!");
        break;
    }
  },

  // status
  getStatus(status) {
    if (status == "Active") {
      return "Active";
    } else {
      return "Inactive";
    }
  },

  getStatusClass(status, type) {
    if (type == "customer") {
      if (status == "Active") {
        return "poLabelSuccess";
      } else if (status == "Inactive") {
        return "poLabelDanger";
      } else {
        return "poLabelInfo";
      }
    }
  },

  // module permission
  accessDenied() {
    // this.snack("E", "You don't have enough permission to access the page!");
    window.location.href="/dashboard"
  },

  isAccess(name, type="s") {
    let permissionList = JSON.parse(localStorage.getItem("userPermission"));
    let moduleList = {
      logs: [
        "SMSLOG_PAGEACCESS:logs_sms",
        "ACTIVITYLOG_PAGEACCESS:logs_activity",
        "UNSUBSCRIPTIONLOG_PAGEACCESS:logs_unsubscription",
      ],
      report: [
        "CUSTOMERREGISTRATIONREPORT_PAGEACCESS:register_",
        "CUSTOMERVISITLOGREPORT_PAGEACCESS:visit_",
        "AGEGROUPREPORT_PAGEACCESS:insight_1",
        "VISITTIMEREPORT_PAGEACCESS:insight_2",
        "TOPLOCATIONSREPORT_PAGEACCESS:location_1",
        "TOPVISITORSREPORT_PAGEACCESS:location_2",
        "LOCATIONSREPORT_PAGEACCESS:location_3",
        "REPORTSCHEDULING_PAGEACCESS:schedule_",
      ],
      analytics: [
        "ANALYTICSOVERVIEW_PAGEACCESS:analytics_overview",
        "CUSTOMERANALYTICS_PAGEACCESS:analytics_customer",
        "ZONEANALYTICS_PAGEACCESS:analytics_zone",
      ],
      actionAlert: ["ACTIONANDALERT_PAGEACCESS"],
      marketing: [
        "MARKETING_PAGEACCESS:marketing_marketing",
        "SURVEYMARKETING_PAGEACCESS:marketing_survey",
        "CAMPAIGNMANAGEMENT_PAGEACCESS:marketing_campaign",
      ],
      administration: [
        "COUNTRYCONFIGURATION_PAGEACCESS:config_country",
        "PROVINCECONFIGURATION_PAGEACCESS:config_province",
        "CONCEPTCONFIGURATION_PAGEACCESS:config_concept",
        "LOCATIONCONFIGURATION_PAGEACCESS:config_location",
        "ZONECONFIGURATION_PAGEACCESS:config_zone",
        "DEVICECONFIGURATION_PAGEACCESS:config_device",
        "MANAGECUSTOMERACCOUNT_PAGEACCESS:customer_account",
        "BLACKLISTACCOUNT_PAGEACCESS:customer_block",
        "CUSTOMERCRITERIAACCOUNT_PAGEACCESS:customer_criteria",
        "LICENSEMANAGEMENT_PAGEACCESS:license_",
        "USERACCOUNT_PAGEACCESS:user_account",
        "USERGROUP_PAGEACCESS:user_group",
        "BANNERCATEGORY_PAGEACCESS:visualize_banner",
        "BACKGROUNDIMAGE_PAGEACCESS:visualize_background",
        "TEMPLATE_PAGEACCESS:visualize_template",
      ],
    };
    if (type == "s") {
      return permissionList.includes(name);
    } else if (type == "m") {
      let access = false;
      moduleList[name].map((item, key) => {
        let result = item.split(":");
        if (permissionList.includes(result[0])) {
          access = true;
        }
      });
      return access;
    } else if (type == "ms") {
      let res = "";
      let moduleInfo = name.split("_");
      moduleList[moduleInfo[0]].map((item, key) => {
        let result = item.split(":");
        let resultKey = result[1].split("_");
        if (
          permissionList.includes(result[0]) &&
          moduleInfo[1] == resultKey[0]
        ) {
          if (res == "") {
            res = resultKey[1];
          }
        }
      });
      return res;
    }
  },
};
