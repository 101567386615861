import React from "react";
import { Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-js-pagination";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";

export default class VisitTimeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 8,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        visitTimeId: 0,
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      sData: {
        visitTimeList: []
      }
    };
  }

  componentDidMount = async () => {
    this.getVisitTimeList();
  };

  loadData = () => {
    const { paging, filter } = this.state;
    var data = {
      url: "REPORT_CUSTOMER_VISIT_TIME_GET_TABLE",
      query: "/1/" + filter.visitTimeId + "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: filter,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  getVisitTimeList = () => {
    const { sData } = this.state;
    var data = {
      url: "REPORT_CUSTOMER_VISIT_TIME_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          sData.visitTimeList = response;
          this.setState({
            sData
          }, () => {
            this.loadData();
          });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    const { filter } = this.state;
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          visitTimeId: 0,
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  render() {
    const {
      status,
      columns,
      data,
      sData,
      paging,
      filter,
      isLoading,
      isFilter,
    } = this.state;

    return (
      <div>
        <div className="contentBox">
          <div className="poTable">
            <div className="d-flex">
              <div className="mr-auto p-2">
                <span className="potableHeadTitle"> Visit Time Report</span>
              </div>
              <div className="p-2">
                <div className="d-flex">
                  <div className="poTableIcon">
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      rootClose={true}
                      overlay={
                        <Popover id="account-filter">
                          <Popover.Title as="h3">Filter List</Popover.Title>
                          <Popover.Content>
                            <Form className="poTableFilter">
                              <Form.Control
                                as="select"
                                name="visitTimeId"
                                value={filter.visitTimeId}
                                onChange={this.filterData}
                                className="poc-br-0"
                              >
                                <option value={0}>Select Visit Time</option>
                                {sData.visitTimeList.map((item, key) => {
                                  return (
                                    <option key={key} value={item.Id}>
                                      {item.Value}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                            </Form>
                          </Popover.Content>
                        </Popover>
                      }
                      onExit={() => this.handleFilter(false)}
                    >
                      {isFilter ? (
                        <img
                          src={common.appIcon("FilterSelect")}
                          className="poc-cp"
                          alt="Filter"
                          onClick={() => this.handleFilter(false)}
                        />
                      ) : (
                          <img
                            src={common.appIcon("FilterIcon")}
                            className="poc-cp"
                            alt="Filter"
                            onClick={() => this.handleFilter(true)}
                          />
                        )}
                    </OverlayTrigger>
                    <img
                      src={common.appIcon("RefreshIcon")}
                      className="poc-cp"
                      alt="Refresh"
                      onClick={() => this.handleRefresh()}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isLoading && (
              <div className="d-flex justify-content-center">
                <span className="poTableFetcher">
                  fetching data please wait...
            </span>
              </div>
            )}

            <div className={isLoading ? "poTableOnLoad" : ""}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer Name</th>
                    <th>Mobile Number</th>
                    <th>Email</th>
                    <th>Gender</th>
                    <th>Date Of Birth</th>
                    <th>Visit Date</th>
                    <th>Visit Time</th>
                  </tr>
                </thead>
                <tbody>
                  {status == "process" ? (
                    <tr className="poTableMsg">
                      <td colSpan={columns}>Loading data please wait...</td>
                    </tr>
                  ) : status == "error" ? (
                    <tr className="poTableMsg">
                      <td colSpan={columns}>
                        There was an error while fetching record.
                  </td>
                    </tr>
                  ) : data.RecordsCount == 0 ? (
                    <tr className="poTableMsg">
                      <td colSpan={columns}>Sorry no record found.</td>
                    </tr>
                  ) : data.result.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.serial}</td>
                        <td>{item.CustomerName}</td>
                        <td>{item.MobileNumber}</td>
                        <td>{item.Email}</td>
                        <td>{item.Gender}</td>
                        <td>{item.DateOfBirth}</td>
                        <td>{item.VisitDate}</td>
                        <td>{item.VisitTime}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data.RecordsCount > 0 && (
                <div className="d-flex">
                  <div className="mr-auto p-2">
                    Total Records: {data.RecordsCount}
                  </div>
                  <div className="p-2">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={paging.pageIndex}
                      itemsCountPerPage={paging.pageSize}
                      totalItemsCount={data.RecordsCount}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
