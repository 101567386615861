import React from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import moment from 'moment';
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";
import FilterActivity from "../includes/FilterActivity";

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      column: 6,
      data: {
        RecordsCount: 0,
        result: [],
      },
    };
  }

  componentDidMount = async () => {
    this.loadData();
  };

  //Load Table Data
  loadData = () => {
    const { paging } = this.state;
    const { filter } = this.filter.state;

    var data = {
      url: "LOGS_ACTIVITY_GET_TABLE",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        UserInfoId: filter.UserInfoId,
        ModuleId: filter.ModuleId,
        ProcessId: filter.ProcessId
      }
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        }
      },
      () => {
        this.filter.resetForm();
      }
    );
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  filterReset() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  render() {
    const {
      status,
      column,
      data,
      paging,
      isLoading,
    } = this.state;

    return (
      <div className="contentBox">
        <div className="poTable">
          <div className="d-flex">
            <div className="mr-auto p-2">
              <span className="potableHeadTitle"> Activity Log </span>
            </div>
            <div className="p-2">
              <div className="d-flex">
                <div className="poTableIcon">
                  <img
                    src={common.appIcon('FilterIcon')}
                    className="poc-cp"
                    alt="Filter"
                    onClick={() => this.filterModal(true)}
                  />

                  <img
                    src={common.appIcon("RefreshIcon")}
                    className="poc-cp"
                    alt="Refresh"
                    onClick={() => this.handleRefresh()}
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex justify-content-center">
              <span className="poTableFetcher">fetching data please wait...</span>
            </div>
          )}

          <div className={isLoading ? "poTableOnLoad" : ""}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User name</th>
                  <th>Module name</th>
                  <th>Process</th>
                  <th>Target Description</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {status == "process" ? (
                  <tr className="poTableMsg">
                    <td colSpan={column}>Loading data please wait...</td>
                  </tr>
                ) : status == "error" ? (
                  <tr className="poTableMsg">
                    <td colSpan={column}>
                      There was an error while fetching record.
                    </td>
                  </tr>
                ) : data.RecordsCount == 0 ? (
                  <tr className="poTableMsg">
                    <td colSpan={column}>Sorry no record found.</td>
                  </tr>
                ) : data.result.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.serial}</td>
                      <td>{item.UserFullName}</td>
                      <td>{item.ModuleName}</td>
                      <td>{item.Process}</td>
                      <td>{item.TargetDescription}</td>
                      <td>{moment(item.TimeStamp).format("DD-MM-YYYY hh:mm A")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data.RecordsCount > 0 && (
              <div className="d-flex">
                <div className="mr-auto p-2">
                  Total Records: {data.RecordsCount}
                </div>
                <div className="p-2">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paging.pageIndex}
                    itemsCountPerPage={paging.pageSize}
                    totalItemsCount={data.RecordsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <FilterActivity
          type={"activity"}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export { Activity };
