import React from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
// _includes
import { StatusBar } from "_includes";
import { common } from "_helpers";
import { apiServices } from "_api";

export default class BlockListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Blocklist",
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        TypeId: "",
        Value: ""
      },
      errors: {
        TypeId: {
          error: "",
          isReq: "Please select Type",
        },
        Value: {
          error: "",
          isReq: "Please enter the value",
        }
      },
      // data
      blockListType: [],
    };
  }

  parentCall(movieId, id) {
    this.setState(
      {
        isModal: true,
      },
      () => {
        this.initCheck();
      }
    );
  }

  initCheck() {
    this.getTypesList();
  }

  getTypesList = () => {
    var data = {
      url: "ADMIN_CUSTOMER_BLOCK_LIST_TYPE_LIST",
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({
            status: "success",
            blockListType: response.data,
          });
        } else {
          this.apiError();
        }
      },
      (error) => {
        this.apiError();
      },
      (final) => { }
    );
  };

  apiError() {
    this.setState({
      status: "error",
      isLoading: false,
    });
  }

  // Validation and Handler
  validateForm() {
    const { fields, errors, actorId } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({ formSubmit: true });
    let isValid = this.validateAll();
    if (isValid) {
      this.setState({ isLoading: true });
      const { fields } = this.state;
      var data = {
        url: "ADMIN_CUSTOMER_BLOCK_LIST_PERSIST",
        body: fields,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { id, title } = this.state;
    if (id) {
      common.snack("S", title + " has been updated");
    } else {
      common.snack("S", "New " + title + " has been added");
    }
    this.onComplete("success");
  }

  crudError() {
    const { title } = this.state;
    common.snack("S", "There was an error while add / update a " + title);
    this.setState({
      isLoading: false,
    });
  }

  // Modal
  onComplete(status) {
    this.setState({
      status: "process",
      isLoading: false,
      isModal: false,
      formSubmit: false,
      fields: {
        TypeId: "",
        Value: ""
      }
    })
    this.props.onComplete(status);
  }

  render() {
    const {
      id,
      status,
      isLoading,
      isModal,
      fields,
      errors,
      formSubmit,
      blockListType
    } = this.state;
    return (
      <Modal show={isModal} onHide={() => this.onComplete("cancel")}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Add"} Blocklist</Modal.Title>
        </Modal.Header>
        <Modal.Body className="cropApp">
          <StatusBar status={status} />
          {status == "success" && (
            <div>
              <Form className="poTableFilter">
                <label className="poLabel">Select Type {errors.TypeId.error !== "" && formSubmit && (<> - <span className="error">{errors.TypeId.error}</span></>)}</label>
                <Form.Control
                  as="select"
                  name="TypeId"
                  value={fields.TypeId}
                  onChange={(e) => this.handleOnChange(e)}
                  className="poc-br-0"
                >
                  <option value="">Select Type</option>
                  {blockListType.map((item, key) => {
                    return (
                      <option key={key} value={item.Id}>
                        {item.Value}
                      </option>
                    );
                  })}
                </Form.Control>
                <label className="poLabel">Enter Value {errors.Value.error !== "" && formSubmit && (<> - <span className="error">{errors.Value.error}</span></>)}</label>
                <Form.Control
                  type="text"
                  name="Value"
                  placeholder="Value"
                  value={fields.Value}
                  onChange={(e) => this.handleOnChange(e)}
                  className="poc-br-0"
                />
              </Form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="poModalFooter">
          <Button
            className="config-btn"
            onClick={() => this.onSubmit()}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Submit"}
          </Button>
          <Button
            className="config-cancel"
            disabled={isLoading}
            onClick={() => this.onComplete("cancel")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
