import moment from "moment";
const dateFormat = "MMMM DD, YYYY hh:mm:ss A";
const utcFormat = "DD-MM-YYYY HH:mm:ss";

export const tools = {
  // date
  localNow() {
    return moment().format(dateFormat);
  },

  utcNow(days = 0) {
    return moment().utc().add(days, 'days').format(utcFormat);
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, utcFormat).local().format(format);
  },

  localToUtc(date) {
    return moment(date).utc().format(utcFormat);
  },

  // input
  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // dom
  scrollEnd(cls = "") {
    if (cls == "") {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      let ele = document.getElementsByClassName(cls);
      ele.scrollTo(0, ele.scrollHeight);
    }
  },

  // encode decode
  crypt(type = "E", str) {
    if (type == "E") {
      return window.btoa(str);
    } else {
      return window.atob(str);
    }
  },

  isNumber(value, type = false) {
    if (type) {
      var pattern = new RegExp(/[^0-9]+/g)
      return pattern.test(value);
    } else {
      return value.replace(/[^0-9]+/g, "")
    }
  },

  setVal(val) {
    if (val == null || val == "" || val == "null") {
      return "-";
    } else {
      return val;
    }
  },

  setEmpty(val) {
    if (val == null || val == "" || val == "null") {
      return "";
    } else {
      return val;
    }
  },

  capsToSpace(val) {
    return val.replace(/([a-z])([A-Z])/g, "$1 $2");
  },

};