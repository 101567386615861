import * as Services from "./services";
import { common } from "_helpers";
import moment from "moment";
let refreshCall = "stop";

const apiServices = {
  // fetch
  call(data = {}, response, error, final) {
    getData(data);
    // console.log(data); console.log("refresh: " + refreshCall + " - repeat: " + data.repeat + " - " + Services[data.url]);
    let apiURL = "";
    if (data.type == "third") {
      apiURL = data.url + data.query;
    } else {
      apiURL = Services[data.url] + data.query;
    }
    let reqOptions = {
      method: data.method,
      headers: getHeaders(data),
    };
    if (data.body) {
      reqOptions.body = data.body;
    }
    fetch(apiURL, reqOptions)
      .then((response) =>
        response.text().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((result) => {
        try {
          result.data = JSON.parse(result.data);
        } catch (e) {}
        if (result.status === 400) {
          common.apiError(result);
        }
        if (data.type == "standard") {
          if (result.status === 401 && data.authorization == "token") {
            if (refreshCall == "stop") {
              // refresh token
              var refData = {
                url: "ADMIN_LOGIN",
                body: {
                  refresh_token: localStorage.getItem("refreshToken"),
                  grant_type: "refresh_token",
                },
                authorization: "none",
                method: "POST",
                type: "refresh",
              };
              this.call(
                refData,
                (result) => {
                  if (result.status === 200) {
                    localStorage.setItem("userToken", result.data.access_token);
                    localStorage.setItem(
                      "refreshToken",
                      result.data.refresh_token
                    );
                    localStorage.setItem("userName", result.data.userName);
                    localStorage.setItem(
                      "tokenExpires",
                      result.data.expires_in
                    );
                    localStorage.setItem("tokenStamp", moment());
                    setTimeout(() => {
                      refreshCall = "stop";
                    }, 500);
                  } else {
                    this.callRedirect();
                  }
                },
                (error) => {
                  this.callRedirect();
                },
                (final) => {}
              );
              refreshCall = "start";
            }
            // call again 3s
            setTimeout(() => {
              if (refreshCall != "error") {
                data.repeat = true;
                this.call(data, response, error, final);
              }
            }, 3000);
          } else {
            response(result);
          }
        } else {
          response(result);
        }
      })
      .catch((err) => {
        common.showMessage("unknown");
        error(err);
      })
      .finally(() => final());
  },

  callRedirect() {
    refreshCall = "error";
    common.snack("I", "Your token has been expired please login again");
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  },
};

function getHeaders(data) {
  let headers = {};
  if (data.contentType === 1) {
    headers = {
      "Content-Type": "application/json",
    };
  } else if (data.contentType === 2) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  if (data.authorization == "token") {
    headers.Authorization = "Bearer " + localStorage.getItem("userToken");
  } else if (data.authorization == "temp") {
    headers.Authorization = "Bearer " + localStorage.getItem("tempToken");
  } else if (data.authorization == "instant_bearer") {
    headers.Authorization = "Bearer " + data.bearerToken;
  } else if (data.authorization == "basic") {
    headers.Authorization = "Basic " + btoa(data.credentials.username + ":" + data.credentials.password);
  }
  return headers;
}

function getData(data) {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.authorization = data.authorization || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.contentType = data.contentType || 2;
    data.directEncode = data.directEncode || false;
    data.query = data.query || "";
    data.body = data.body || "";
    if (data.body) {
      if (data.contentType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.contentType == 2) {
        if (!data.directEncode) {
          let bodyParam = [];
          for (var property in data.body) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data.body[property]);
            bodyParam.push(encodedKey + "=" + encodedValue);
          }
          data.body = bodyParam.join("&");
        }
      }
    }
  }
}

export { apiServices };
