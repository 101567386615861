import React from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
// _includes
import { tools } from "_helpers";
import { apiServices } from "_api";

class ExportCSV extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isExport: false,
      csv: {
        set: false,
        headers: this.getInfo("csv_header"),
        data: [],
      },
    };
  }

  exportCSV() {
    this.setState({ isExport: true });
    const { csv } = this.state;
    var data = { url: this.getInfo("url") };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          if (response.length > 0) {
            // set headers
            let csvData = [];
            let header = this.getInfo("header");
            response.map((data, key) => {
              let csvItems = {};
              header.map((hData, hKey) => {
                csvItems = Object.assign({ [hData]: data[hData] }, csvItems)
              })
              csvData.push(csvItems)
            });

            csv.set = true;
            csv.data = csvData;
            this.setState(
              {
                csv,
              },
              () => {
                setTimeout(() => {
                  let csv_export = this.refs.csv_export;
                  csv_export.link.click();
                }, 100);
              }
            );
          } else {
            alert("No record found!");
          }
        }
      },
      (error) => { },
      (final) => {
        this.setState({ isExport: false });
      }
    );
  }

  getInfo(key) {
    let url = "";
    let headers = [];
    let type = this.props.type;

    if (type == "logs_sms") {
      url = "LOGS_SMS_GET_ALL";
      headers = [
        "SmsType",
        "SmsStatus",
        "MobileNumber",
        "OneTimePassword",
        "SentTimeStamp",
        "CountryName",
        "ProvinceName",
        "ConceptName",
        "LocationName",
        "LocationCode",
        "LocationType",
      ];
    } else if (type == "logs_unsubscription") {
      url = "LOGS_UNSUBSCRIPTION_GET_ALL";
      headers = [
        "CustomerId",
        "CustomerName",
        "DeviceMacAddress",
        "MobileNumber",
        "Email",
        "Gender",
        "DateOfBirth",
        "SubscriptionStatus",
        "TimeStamp",
      ];
    } else if (type == "report_register" || type=="admin_customer_account") {
      url = "REPORT_CUSTOMER_REGISTRATION_GET_ALL";
      headers = [
        "CustomerName",
        "MobileNumber",
        "Email",
        "DeviceMacAddress",
        "DateOfBirth",
        "Gender",
        "RegisteredOn",
        "AccountStatusValue",
      ];
    } else if (type == "report_visit") {
      url = "REPORT_CUSTOMER_VISIT_GET_ALL";
      headers = [
        "CustomerName",
        "DeviceMacAddress",
        "Email",
        "MobileNumber",
        "DateOfBirth",
        "Gender",
        "Location",
        "RegisteredOn",
        "VisitedOn",
        "VisitType",
      ];
    } else if (type == "config_country") {
      url = "ADMIN_CONFIG_COUNTRY_GET_ALL";
      headers = [
        "CountryName",
        "Description",
        "CountryCode",
      ];
    } else if (type == "config_province") {
      url = "ADMIN_CONFIG_PROVINCE_GET_ALL";
      headers = [
        "ProvinceName",
        "Description",
        "CountryName",
        "CountryDescription",
        "CountryCode",
      ];
    } else if (type == "config_concept") {
      url = "ADMIN_CONFIG_CONCEPT_GET_ALL";
      headers = [
        "ConceptName",
        "Description",
      ];
    } else if (type == "config_location") {
      url = "ADMIN_CONFIG_LOCATION_GET_ALL";
      headers = [
        "LocationName",
        "LocationCode",
        "Description",
        "LocationType",
        "ConceptName",
        "ConceptDescription",
        "ProvinceName",
        "ProvinceDescription",
        "CountryName",
        "CountryDescription",
        "CountryCode",
      ];
    } else if (type == "config_zone") {
      url = "ADMIN_CONFIG_ZONE_GET_ALL";
      headers = [
        "ZoneName",
        "Description",
        "LocationName",
        "LocationCode",
        "LocationDescription",
        "LocationType",
        "ConceptName",
        "ConceptDescription",
        "ProvinceName",
        "ProvinceDescription",
        "CountryName",
        "CountryDescription",
        "CountryCode",
      ];
    } else if (type == "config_device") {
      url = "ADMIN_CONFIG_DEVICE_GET_ALL";
      headers = [
        "DeviceName",
        "Description",
        "DeviceType",
        "AccessPointModel",
        "MacAddress",
        "ZoneName",
        "ZoneDescription",
        "LocationName",
        "LocationCode",
        "LocationDescription",
        "LocationType",
        "ConceptName",
        "ConceptDescription",
        "ProvinceName",
        "ProvinceDescription",
        "CountryName",
        "CountryDescription",
        "CountryCode",
      ];
    }

    if (key == "url") {
      return url;
    } else if (key == "header") {
      return headers.reverse();
    } else if (key == "csv_header") {
      let csv_header = [];
      headers.map((item, key) => {
        csv_header.push({ label: tools.capsToSpace(item), key: item },)
      })
      return csv_header;
    }
  }

  render() {
    const { csv, isExport } = this.state;
    return (
      <>
        <Button
          variant="primary config-btn"
          onClick={() => this.exportCSV()}
          disabled={isExport}
        >
          {isExport ? "Exporting..." : "Export CSV"}
        </Button>
        {csv.set && (
          <CSVLink
            ref="csv_export"
            data={csv.data}
            headers={csv.headers}
            filename={this.props.name + ".csv"}
            className="csvData"
          />
        )}
      </>
    );
  }
}

export { ExportCSV };