import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
// _import
import { common, history } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class ZoneCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "Zone",
      parent: "/admin/config/zone",
      status: "process",
      isLoading: false,
      fields: {
        ZoneName: "",
        Description: "",
        LocationId: "",
      },
      errors: {
        ZoneName: {
          error: "",
          isReq: "Please enter the zone name",
        },
        LocationId: {
          error: "",
          isReq: "Please select the location",
        }
      },
      locationList: [],
      locationSelectList: [],
    };
  }

  componentDidMount() {
    if(this.crudAccess()){
      this.initCheck();
    }
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getAllLocation();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_CONFIG_ZONE_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState({
            fields: {
              ZoneName: result.ZoneName,
              Description: result.Description,
              LocationId: result.LocationId,
            },
          },()=>{
            this.getAllLocation();
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  getAllLocation() {
    const { fields } = this.state;
    var data = {
      url: "ADMIN_CONFIG_LOCATION_GET_ALL",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          response = response.data;
          let locationList = [];
          let locationSelectList = [];
          response.map((data, key) => {
            let obj = {
              label: data.LocationName,
              value: data.LocationId,
            };
            if (fields.LocationId == data.LocationId) {
              locationSelectList.push(obj);
            }
            locationList.push(obj);
          });
          this.setState({
            status: "success",
            locationList,
            locationSelectList,
          });
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => {}
    );
  }

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleSelectChange(data, name) {
    const { fields } = this.state;
    fields[name] = data.value;
    this.setState(
      {
        fields,
      },
      () => {
        this.validateForm();
      }
    );
  }

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var data = {};
      if (params.type == "add") {
        data = {
          url: "ADMIN_CONFIG_ZONE_PERSIST",
          query: "?IsAdd=true",
          body: fields,
          method: "POST",
        };
      } else {
        fields.ZoneId = params.id;
        data = {
          url: "ADMIN_CONFIG_ZONE_PERSIST",
          query: "?IsAdd=false",
          body: fields,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type=="add" && common.isAccess("ZONECONFIGURATION_ADD")) && (allow=true);
    (params.type=="edit" && common.isAccess("ZONECONFIGURATION_EDIT")) && (allow=true);
    if(!allow){
      common.accessDenied();
    }
    return allow;
  }
  
  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      locationList,
      locationSelectList,
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>Zone</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add Zone" : "Update Zone"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add Zone" : "Update Zone"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Zone Name
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="ZoneName"
                          placeholder="Zone Name"
                          value={fields.ZoneName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.ZoneName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.ZoneName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Select Location
                      </Form.Label>
                      <Col sm="4">
                        {locationList.length > 0 ? (
                          <Select
                            options={locationList}
                            defaultValue={locationSelectList}
                            onChange={(data) =>
                              this.handleSelectChange(data, "LocationId")
                            }
                          />
                        ) : (
                            <Form.Control
                              type="text"
                              placeholder="Loading location please wait..."
                              readOnly={true}
                            />
                          )}
                        {errors.LocationId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LocationId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading ? "Processing..." : params.type == "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { ZoneCrud };
