import React from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
// API Services
import { apiServices } from "_api";
import { common } from "_helpers";
// css
import "react-dates/lib/css/_datepicker.css";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilter: "none",
      filter: {
        customDate: {
          startDate: null,
          endDate: null,
          DateFrom: "",
          DateTo: "",
        },
        CountryId: "",
        ProvinceId: "",
        ConceptId: "",
        LocationId: "",
      },
      filterDate: {
        Date: 0,
        DefaultDate: 0,
        NumberOfDays: 0,
        DefaultDays: 0,
        SelectedDays: 0,
      },
      filterData: {
        dateWise: [],
        countryList: [],
        provinceList: [],
        conceptList: [],
        conceptPersistList: [],
        locationList: [],
      },
      // common
      isModal: false,
      focusedInput: null,
    };
  }

  componentDidMount() {
    this.defaultAPI();
  }

  defaultAPI() {
    this.getAllDateFilter();
    this.getAllCountry();
    this.getAllConcept();
  }

  // ### filter functions start here ###
  getAllDateFilter = () => {
    const { filterDate, filterData } = this.state;
    var data = {
      url: "ANALYTICS_GET_ANALYTICS_DASHBOARD_DATE_FILTER_ITEM_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          let result = response.data;
          filterData.dateWise = result;
          // date filter
          filterDate.Date = result[0].DashboardDateFilterItemId;
          filterDate.DefaultDate = result[0].DashboardDateFilterItemId;
          filterDate.NumberOfDays = result[0].NumberOfDays;
          filterDate.DefaultDays = result[0].NumberOfDays;
          filterDate.SelectedDays = result[0].NumberOfDays;

          this.setState(
            {
              filterDate,
              filterData,
            },
            () => {
              this.props.filterInit();
            }
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAllCountry = async () => {
    const { filterData } = this.state;
    var data = {
      url: "ADMIN_CONFIG_COUNTRY_GET_ALL",
    };
    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          filterData.countryList = response;
          this.setState({ filterData });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  getAllProvince = () => {
    const { filter, filterData } = this.state;
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];
    if (filter.CountryId) {
      this.setState({
        filter,
        filterData,
        isFilter: "province",
      });
      var data = {
        url: "GET_ALL_PROVINCE",
        body: {
          CountryId: filter.CountryId,
        },
      };
      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            filterData.provinceList = response;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {
          this.setState({ isFilter: "none" });
        }
      );
    } else {
      this.setState({ filter, filterData });
    }
  };

  getAllConcept = (type = "load") => {
    const { filter, filterData } = this.state;
    if (type == "filter") {
      if (filter.ProvinceId == "") {
        filter.ConceptId = "";
        filterData.conceptList = [];
        filterData.locationList = [];
      } else {
        filterData.conceptList = filterData.conceptPersistList;
      }
      this.setState({ filter, filterData });
    } else {
      var data = {
        url: "GET_ALL_CONCEPT",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            filterData.conceptPersistList = response;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  getAllLocation = async () => {
    const { filter, filterData } = this.state;
    filterData.locationList = [];
    if (filter.ConceptId == "") {
      this.setState({ filterData });
    } else {
      this.setState({ filterData, isFilter: "location" });
      var data = {
        url: "GET_ALL_LOCATION",
        body: {
          // CountryId:filter.CountryId,
          ProvinceId: filter.ProvinceId,
          ConceptId: filter.ConceptId,
        },
      };

      await apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            response = response.data;
            filterData.locationList = response;
            this.setState({ filterData });
          }
        },
        (error) => {},
        (final) => {
          this.setState({
            isFilter: "none",
          });
        }
      );
    }
  };

  // filter select change
  selectChange = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        if (name == "CountryId") {
          this.getAllProvince();
        } else if (name == "ProvinceId") {
          this.getAllConcept("filter");
        } else if (name == "ConceptId") {
          this.getAllLocation();
        }
      }
    );
  };

  selectDateChange = (event) => {
    const { value } = event.target;
    const { filterDate } = this.state;
    filterDate.Date = value;
    filterDate.SelectedDays = this.selectChild(event, "data");
    this.setState({
      filterDate,
    });
  };

  selectChild(e, attr) {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    return optionElement.getAttribute(attr);
  }

  setCustomDate(startDate, endDate) {
    const { filter } = this.state;
    filter.customDate = {
      startDate,
      endDate,
      DateFrom: moment(startDate).format("DD-MM-YYYY"),
      DateTo: moment(endDate).format("DD-MM-YYYY"),
    };

    this.setState({
      filter,
    });
  }

  filterForm() {
    const { filter, filterDate } = this.state;
    let allow = true;

    if (filterDate.SelectedDays === "-1") {
      let startDate = filter.customDate.startDate;
      let endDate = filter.customDate.endDate;

      if (startDate == null || endDate == null) {
        allow = false;
        common.snack("E", "Please select start and end date");
      } else {
        filterDate.NumberOfDays = moment(endDate).diff(startDate, "days");
      }
    } else {
      filter.customDate = {
        startDate: null,
        endDate: null,
        DateFrom: "",
        DateTo: "",
      };
      filterDate.NumberOfDays = filterDate.SelectedDays;
    }
    if (allow) {
      this.setState(
        {
          filter,
          isModal: false,
        },
        () => {
          this.props.filterSubmit();
        }
      );
    }
  }

  resetForm() {
    const { filter, filterDate, filterData } = this.state;

    filter.customDate = {
      startDate: null,
      endDate: null,
      DateFrom: "",
      DateTo: "",
    };

    filter.CountryId = "";
    filter.ProvinceId = "";
    filter.ConceptId = "";
    filter.LocationId = "";

    // date
    filterDate.Date = filterDate.DefaultDate;
    filterDate.NumberOfDays = filterDate.DefaultDays;
    filterDate.SelectedDays = filterDate.DefaultDays;

    filterData.provinceList = [];
    filterData.conceptList = [];
    filterData.locationList = [];

    this.setState(
      {
        filter,
        filterData,
        isModal: false,
      },
      () => {
        this.props.filterReset();
      }
    );
  }

  filterModal(status) {
    this.setState({
      isModal: status,
    });
  }

  render() {
    const {
      isFilter,
      isModal,
      focusedInput,
      filter,
      filterDate,
      filterData,
    } = this.state;
    return (
      <Modal show={isModal}>
        <Modal.Header>
          <Modal.Title>Filter List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form
              className="poModalFilter"
              onSubmit={(event) => event.preventDefault}
            >
              <label className="poLabel">Select Date</label>
              <Form.Control
                as="select"
                name="Date"
                value={filterDate.Date}
                onChange={this.selectDateChange}
              >
                {filterData.dateWise.map((item, key) => (
                  <option
                    key={key}
                    value={item.DashboardDateFilterItemId}
                    data={item.NumberOfDays}
                  >
                    {item.Caption}
                  </option>
                ))}
              </Form.Control>
              {filterDate.SelectedDays === "-1" && (
                <div>
                  <label className="poLabel">Start Date - End Date</label>
                  <div className="poModalDateInput">
                    <DateRangePicker
                      startDate={filter.customDate.startDate}
                      startDateId="aoDateFrom"
                      endDate={filter.customDate.endDate}
                      endDateId="aoDateTo"
                      onDatesChange={({ startDate, endDate }) =>
                        this.setCustomDate(startDate, endDate)
                      }
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      isOutsideRange={() => false}
                    />
                  </div>
                </div>
              )}
              <label className="poLabel">Country</label>
              <Form.Control
                as="select"
                name="CountryId"
                value={filter.CountryId}
                onChange={this.selectChange}
              >
                <option value="">Select Country</option>
                {filterData.countryList.map((item, key) => {
                  return (
                    <option key={key} value={item.CountryId}>
                      {item.CountryName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Province</label>
              <Form.Control
                as="select"
                name="ProvinceId"
                value={filter.ProvinceId}
                onChange={this.selectChange}
              >
                <option value="">
                  {isFilter == "province"
                    ? "Province Loading..."
                    : "Select Province"}
                </option>
                {filterData.provinceList.map((item, key) => {
                  return (
                    <option key={key} value={item.ProvinceId}>
                      {item.ProvinceName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Concept</label>
              <Form.Control
                as="select"
                name="ConceptId"
                value={filter.ConceptId}
                onChange={this.selectChange}
              >
                <option value="">Select Concept</option>
                {filterData.conceptList.map((item, key) => {
                  return (
                    <option key={key} value={item.ConceptId}>
                      {item.ConceptName}
                    </option>
                  );
                })}
              </Form.Control>

              <label className="poLabel">Location</label>
              <Form.Control
                as="select"
                name="LocationId"
                value={filter.LocationId}
                onChange={this.selectChange}
              >
                <option value="">
                  {isFilter == "location"
                    ? "Location Loading..."
                    : "Select Location"}
                </option>
                {filterData.locationList.map((item, key) => {
                  return (
                    <option key={key} value={item.ConceptId}>
                      {item.ConceptName}
                    </option>
                  );
                })}
              </Form.Control>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterForm()}
          >
            Filter
          </Button>
          <Button variant="primary config-btn" onClick={() => this.resetForm()}>
            Reset
          </Button>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Filter;
