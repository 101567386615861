import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
// pages
import BannerList from "./BannerList";
import BackgroundList from "./BackgroundList";

class Visualize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: history.location.pathname.split("/")[3],
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
    history.push(tab);
  };

  // page access
  checkAccess() {
    const { activeTab } = this.state;
    let pageList = {
      banner: "BANNERCATEGORY_PAGEACCESS",
      background: "BACKGROUNDIMAGE_PAGEACCESS",
      template: "TEMPLATE_PAGEACCESS",
    };
    if (!common.isAccess(pageList[activeTab])) {
      common.accessDenied();
    } else {
      return true;
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="pageContent">
        <Tab.Container activeKey={activeTab}>
          <div className="contentHead">
            <div className="row">
              <div className="col-sm-5 poFlex">
                <h1>Visualize</h1>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">Administration</a>
                  </li>
                  <li>
                    <a href="#" className="noPipe">
                      Visualize
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-7">
                <Form className="poTabs poFlex poDefault">
                  {common.isAccess("BANNERCATEGORY_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "banner" ? "inActive" : ""}
                      onClick={() => this.setTab("banner")}
                    >
                      Banner Catergory
                    </Button>
                  )}
                  {common.isAccess("BACKGROUNDIMAGE_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "background" ? "inActive" : ""}
                      onClick={() => this.setTab("background")}
                    >
                      Background Images
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
          <div className="contentBody">
            <div className="content">
              <Tab.Content>
                {common.isAccess("BANNERCATEGORY_PAGEACCESS") && (
                  <Tab.Pane eventKey="banner" title="Banner Category">
                    <BannerList
                      ref={(instance) => {
                        this.bannerList = instance;
                      }}
                    />
                  </Tab.Pane>
                )}
                {common.isAccess("BACKGROUNDIMAGE_PAGEACCESS") && (
                  <Tab.Pane eventKey="background" title="Background Images">
                    <BackgroundList
                      ref={(instance) => {
                        this.backgroundList = instance;
                      }}
                    />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
export { Visualize };
