import React from "react";
import { Button, Form, Table, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
// _includes
import { common } from "_helpers";
import { ExportCSV } from "_includes";
import { apiServices } from "_api";
import FilterRegister from "../includes/FilterRegister";

export default class CustomerAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      columns: 10,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      isModal: false,
      crudAccess: {
        export: false,
        statusUpdate: false,
        delete: false
      }
    };
  }

  componentDidMount() {
    this.crudAccess();
    this.loadData();
  }

  loadData = () => {
    const { paging } = this.state;
    const { filter } = this.filter.state;

    var data = {
      url: "REPORT_CUSTOMER_REGISTRATION",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: filter
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
      }, () => {
        this.filter.resetForm();
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(id, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(id, type) {
    var data = {
      url: "ADMIN_CUSTOMER_DELETE",
    };

    if (type == "single") {
      data.query = "/" + id;
    } else {
      const { deleteIds } = this.state;
      data.query = "?customerId=" + deleteIds.join("&customerId=");
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  filterReset() {
    this.setState({
      isLoading: true
    }, () => {
      this.loadData();
    })
  }

  // page access
  crudAccess() {
    const { crudAccess } = this.state;
    let columns = this.state.columns;
    (common.isAccess("MANAGECUSTOMERACCOUNT_EXPORT") && (crudAccess.export = true));
    (common.isAccess("MANAGECUSTOMERACCOUNT_STATUSUPDATE") && (crudAccess.statusUpdate = true));
    (common.isAccess("MANAGECUSTOMERACCOUNT_DELETE") && (crudAccess.delete = true));
    (crudAccess.delete) ? (crudAccess.action = true) : --columns;
    this.setState({
      columns,
      crudAccess
    })
  }

  render() {
    const { status, data, paging, isLoading, columns, crudAccess } = this.state;
    return (
      <div className="contentBox">
        <div className="poTable">
          <div className="d-flex">
            <div className="mr-auto p-2">
              <span className="potableHeadTitle">Customer Account List</span>
              {crudAccess.export && (
                <> - <ExportCSV type="admin_customer_account" name="customer_account_list" /></>
              )}
            </div>
            <div className="p-2">
              <div className="d-flex">
                <div className="poTableIcon">
                  <img
                    src={common.appIcon("FilterIcon")}
                    className="poc-cp"
                    alt="Filter"
                    onClick={() => this.filterModal(true)}
                  />

                  <img
                    src={common.appIcon("RefreshIcon")}
                    className="poc-cp"
                    alt="Refresh"
                    onClick={() => this.handleRefresh()}
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex justify-content-center">
              <span className="poTableFetcher">
                fetching data please wait...
            </span>
            </div>
          )}

          <div className={isLoading ? "poTableOnLoad" : ""}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Mac Address</th>
                  <th>Registered Date</th>
                  <th>Status</th>
                  {crudAccess.action && (<th>Action</th>)}
                </tr>
              </thead>
              <tbody>
                {status == "process" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Loading data please wait...</td>
                  </tr>
                ) : status == "error" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>
                      There was an error while fetching record.
                  </td>
                  </tr>
                ) : data.RecordsCount == 0 ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Sorry no record found.</td>
                  </tr>
                ) : data.result.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.serial}</td>
                      <td>{item.CustomerName}</td>
                      <td>{item.Email}</td>
                      <td>{item.MobileNumber}</td>
                      <td>{item.DateOfBirth}</td>
                      <td>{item.Gender}</td>
                      <td>{item.DeviceMacAddress}</td>
                      <td>{item.RegisteredOn}</td>
                      <td>
                        <span
                          className={`poStatus ${common.getStatusClass(
                            item.AccountStatusValue,
                            "customer"
                          )}`}
                        >
                          {item.AccountStatusValue}
                        </span>
                      </td>
                      {crudAccess.delete && (
                      <td>
                        <span className="poTableAction">
                          <img
                            className="poc-cp"
                            src={common.appIcon("DelIcon")}
                            alt="Delete"
                            onClick={() => this.deleteData(1, "single")}
                          />
                        </span>
                      </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data.RecordsCount > 0 && (
              <div className="d-flex">
                <div className="mr-auto p-2">
                  Total Records: {data.RecordsCount}
                </div>
                <div className="p-2">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paging.pageIndex}
                    itemsCountPerPage={paging.pageSize}
                    totalItemsCount={data.RecordsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <FilterRegister
          type="register"
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}