import React from "react";
import { Button, Form, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";
import ImportBlockList from "../includes/ImportBlockList";
// pages
import BlockListModal from "../includes/BlockListModal";

export default class BlockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      isFilter: false,
      isSelectAll: false,
      isBulkDelete: false,
      isExport: false,
      deleteIds: [],
      columns: 4,
      paging: {
        pageSize: 10,
        pageIndex: 1,
        recordCount: 0,
      },
      filter: {
        TypeId: 0,
        Value: ""
      },
      data: {
        RecordsCount: 0,
        result: [],
      },
      sData: {
        blockListTypeList: []
      }
    };
  }

  componentDidMount = async () => {
    this.loadData();
    this.getBlockListTypeList();
  };

  loadData = () => {
    const { paging, filter } = this.state;
    var data = {
      url: "ADMIN_CUSTOMER_BLOCK_LIST_GET_TABLE",
      query:
        "?PageSize=" + paging.pageSize + "&PageIndex=" + (paging.pageIndex - 1),
      body: filter,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // add index
          response.Data.map((item, key) => {
            item.serial =
              paging.pageIndex > 1
                ? (paging.pageIndex - 1) * paging.pageSize + ++key
                : ++key;
          });
          // set data
          this.setState({
            status: "success",
            isLoading: false,
            data: {
              RecordsCount: response.RecordsCount,
              result: response.Data,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          isLoading: false,
          data: {
            RecordsCount: 0,
            result: [],
          },
        });
      },
      (final) => { }
    );
  };

  getBlockListTypeList = () => {
    const { sData } = this.state;
    var data = {
      url: "ADMIN_CUSTOMER_BLOCK_LIST_TYPE_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          sData.blockListTypeList = response;
          this.setState({ sData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // events
  handlePageChange(pageNumber) {
    const { paging } = this.state;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          isLoading: true,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  handleFilter(isFilter) {
    this.setState({
      isFilter,
    });
  }

  filterData = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState(
      {
        filter,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.loadData();
            }
          );
        });
      }
    );
  };

  handleRefresh() {
    this.setState(
      {
        isLoading: true,
        isBulkDelete: false,
        isFilter: false,
        isBulkDelete: false,
        isSelectAll: false,
        deleteIds: [],
        paging: {
          pageSize: 10,
          pageIndex: 1,
          recordCount: 0,
        },
        filter: {
          TypeId: 0,
          Value: ""
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteRecord(id, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(id, type) {
    var data = {
      url: "ADMIN_CUSTOMER_BLOCK_LIST_DELETE",
    };

    if (type == "single") {
      data.query = "/" + id;
    } else {
      const { deleteIds } = this.state;
      data.query = "?CustomerBlackListId=" + deleteIds.join("&CustomerBlackListId=");
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          this.setState(
            {
              isLoading: true,
              isSelectAll: false,
              isBulkDelete: false,
            },
            () => {
              this.loadData();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  importCSV() {
    this.ImportBlockList.setModal(true);
  }

  // props
  onComplete(type) {
    if (type == "success") {
      this.handleRefresh();
    }
  }

  render() {
    const {
      status,
      columns,
      data,
      sData,
      paging,
      filter,
      isLoading,
      isFilter,
    } = this.state;

    return (
      <div className="contentBox">
        <div className="poTable">
          <div className="d-flex">
            <div className="mr-auto p-2">
              <span className="potableHeadTitle"> Blocklist </span> -{" "}
              <Button
                variant="primary config-btn"
                onClick={() =>
                  this.BlockListModal.parentCall()
                }
              >
                Add Blocklist
              </Button>
              <Button
                variant="primary config-btn"
                onClick={() => this.importCSV()}
              >
                Import CSV
              </Button>
            </div>
            <div className="p-2">
              <div className="d-flex">
                <div className="poTableIcon">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    rootClose={true}
                    overlay={
                      <Popover id="account-filter">
                        <Popover.Title as="h3">Filter List</Popover.Title>
                        <Popover.Content>
                          <Form className="poTableFilter">
                            <Form.Control
                              as="select"
                              name="TypeId"
                              value={filter.TypeId}
                              onChange={this.filterData}
                            >
                              <option value="0">Select Type</option>
                              {sData.blockListTypeList.map((item, key) => {
                                return (
                                  <option key={key} value={item.Id}>
                                    {item.Value}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <Form.Control
                              type="text"
                              name="Value"
                              placeholder="Value"
                              value={filter.Value}
                              onChange={this.filterData}
                            />
                          </Form>
                        </Popover.Content>
                      </Popover>
                    }
                    onExit={() => this.handleFilter(false)}
                  >
                    {isFilter ? (
                      <img
                        src={common.appIcon("FilterSelect")}
                        className="poc-cp"
                        alt="Filter"
                        onClick={() => this.handleFilter(false)}
                      />
                    ) : (
                        <img
                          src={common.appIcon("FilterIcon")}
                          className="poc-cp"
                          alt="Filter"
                          onClick={() => this.handleFilter(true)}
                        />
                      )}
                  </OverlayTrigger>
                  <img
                    src={common.appIcon("RefreshIcon")}
                    className="poc-cp"
                    alt="Refresh"
                    onClick={() => this.handleRefresh()}
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex justify-content-center">
              <span className="poTableFetcher">
                fetching data please wait...
            </span>
            </div>
          )}

          <div className={isLoading ? "poTableOnLoad" : ""}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status == "process" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Loading data please wait...</td>
                  </tr>
                ) : status == "error" ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>
                      There was an error while fetching record.
                  </td>
                  </tr>
                ) : data.RecordsCount == 0 ? (
                  <tr className="poTableMsg">
                    <td colSpan={columns}>Sorry no record found.</td>
                  </tr>
                ) : data.result.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.serial}</td>
                      <td>{item.Type}</td>
                      <td>{item.Value}</td>
                      <td>
                        <span className="poTableAction">
                          <img
                            className="poc-cp"
                            src={common.appIcon("DelIcon")}
                            alt="Delete"
                            onClick={() => this.deleteData(item.CustomerBlackListId, "single")}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data.RecordsCount > 0 && (
              <div className="d-flex">
                <div className="mr-auto p-2">
                  Total Records: {data.RecordsCount}
                </div>
                <div className="p-2">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paging.pageIndex}
                    itemsCountPerPage={paging.pageSize}
                    totalItemsCount={data.RecordsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <ImportBlockList
          ref={(instance) => {
            this.ImportBlockList = instance;
          }}
        />
        <BlockListModal
          ref={(instance) => {
            this.BlockListModal = instance;
          }}
          onComplete={(type) => this.onComplete(type)}
        />
      </div>
    );
  }
}
