import React from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
// API Services
import { apiServices } from "_api";
// css
import "react-dates/lib/css/_datepicker.css";

export default class FilterRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilter: "none",
      filter: {
        CustomerName: "",
        MobileNumber: "",
        Email: "",
        DeviceMacAddress: "",
        RegistrationDateFrom: "",
        RegistrationDateTo: "",
        DateOfBirthFrom: "",
        DateOfBirthTo: "",
        AccountStatusId: "",
        GenderId: ""
      },
      filterData: {
        accountStatusList: [],
        genderList: [],
      },
      dateInfo: {
        RegistrationDateFrom: null,
        RegistrationDateTo: null,
        DateOfBirthFrom: null,
        DateOfBirthTo: null,
      },
      // common
      isModal: false,
      focusedInputReg: null,
      focusedInputDob: null,
    };
  }

  componentDidMount() {
    this.defaultAPI();
  }

  defaultAPI() {
    // this.getAllAccountStatus();
  }

  // ### filter functions start here ###
  getAllAccountStatus = () => {
    const { filterData } = this.state;
    var data = {
      url: "LOGS_SMS_TYPE_LIST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data
          filterData.smsTypeList = response;
          this.setState({ filterData });
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  // filter change
  handleOnChange = (event) => {
    const { name, value } = event.target;
    const { filter } = this.state;
    filter[name] = value;
    this.setState({
      filter
    });
  };

  setCustomDate(startDate, endDate, type) {
    const { filter, dateInfo } = this.state;
    if (type == "register") {
      dateInfo.RegistrationDateFrom = startDate;
      dateInfo.RegistrationDateTo = endDate;
      filter.RegistrationDateFrom = moment(startDate).format("DD-MM-YYYY");
      filter.RegistrationDateTo = moment(endDate).format("DD-MM-YYYY");
    } else if (type == "dob") {
      dateInfo.DateOfBirthFrom = startDate;
      dateInfo.DateOfBirthTo = endDate;
      filter.DateOfBirthFrom = moment(startDate).format("DD-MM-YYYY");
      filter.DateOfBirthTo = moment(endDate).format("DD-MM-YYYY");
    }

    this.setState({
      dateInfo,
      filter
    });
  }

  // filter
  filterForm() {
    const { filter } = this.state;
    this.setState(
      {
        filter,
        isModal: false,
      },
      () => {
        this.props.filterSubmit()
      }
    );
  }

  resetForm() {
    const { filter, filterData, dateInfo } = this.state;
    filter.CustomerName = "";
    filter.MobileNumber = "";
    filter.Email = "";
    filter.DeviceMacAddress = "";
    filter.RegistrationDateFrom = "";
    filter.RegistrationDateTo = "";
    filter.DateOfBirthFrom = "";
    filter.DateOfBirthTo = "";
    filter.AccountStatusId = "";
    filter.GenderId = "";

    filterData.accountStatusList = [];
    filterData.genderList = [];

    dateInfo.RegistrationDateFrom = null;
    dateInfo.RegistrationDateTo = null;
    dateInfo.DateOfBirthFrom = null;
    dateInfo.DateOfBirthTo = null;

    this.setState(
      {
        filter,
        filterData,
        dateInfo,
        isModal: false,
      },
      () => {
        this.props.filterReset();
      }
    );
  }

  filterModal(status) {
    this.setState({
      isModal: status,
    });
  }

  resetDate(type) {
    const { dateInfo } = this.state;
    if (type == "register") {
      dateInfo.RegistrationDateFrom = null;
      dateInfo.RegistrationDateTo = null;
    } else if (type == "dob") {
      dateInfo.DateOfBirthFrom = null;
      dateInfo.DateOfBirthTo = null;
    }
    this.setState({ dateInfo })
  }

  render() {
    const { isFilter, isModal, focusedInputReg, focusedInputDob, filter, filterData, dateInfo } = this.state;
    return (
      <Modal show={isModal}>
        <Modal.Header>
          <Modal.Title>Filter List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form
              className="poModalFilter"
              onSubmit={(event) => event.preventDefault}
            >
              <div>
                <label className="poModalDateLabel">
                  <span>Registration</span>
                  <span className="poc-link poc-cp" onClick={() => this.resetDate("register")}>Reset Date</span>
                </label>
                <div className="poModalDateInput">
                  <DateRangePicker
                    startDate={dateInfo.RegistrationDateFrom}
                    startDateId="RegFrom"
                    endDate={dateInfo.RegistrationDateTo}
                    endDateId="RegTo"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setCustomDate(startDate, endDate, "register")
                    }
                    focusedInput={focusedInputReg}
                    onFocusChange={(focusedInputReg) =>
                      this.setState({ focusedInputReg })
                    }
                    isOutsideRange={() => false}
                  />
                </div>
              </div>
              <div>
                <label className="poModalDateLabel">
                  <span>Date Of Birth</span>
                  <span className="poc-link poc-cp" onClick={() => this.resetDate("dob")}>Reset Date</span>
                </label>
                <div className="poModalDateInput">
                  <DateRangePicker
                    startDate={dateInfo.DateOfBirthFrom}
                    startDateId="DateFrom"
                    endDate={dateInfo.DateOfBirthTo}
                    endDateId="DateTo"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setCustomDate(startDate, endDate, "dob")
                    }
                    focusedInput={focusedInputDob}
                    onFocusChange={(focusedInputDob) =>
                      this.setState({ focusedInputDob })
                    }
                    isOutsideRange={() => false}
                  />
                </div>
              </div>
              <label className="poLabel">Customer Name</label>
              <Form.Control
                type="text"
                name="CustomerName"
                placeholder="Customer Name"
                value={filter.CustomerName}
                onChange={this.handleOnChange}
              />
              <label className="poLabel">Mobile Number</label>
              <Form.Control
                type="text"
                name="MobileNumber"
                placeholder="Mobile Number"
                value={filter.MobileNumber}
                onChange={this.handleOnChange}
              />
              <label className="poLabel">Email</label>
              <Form.Control
                type="text"
                name="Email"
                placeholder="Email"
                value={filter.Email}
                onChange={this.handleOnChange}
              />
              <label className="poLabel">Mac Address</label>
              <Form.Control
                type="text"
                name="DeviceMacAddress"
                placeholder="Mac Address"
                value={filter.DeviceMacAddress}
                onChange={this.handleOnChange}
              />
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterForm()}
          >
            Filter
          </Button>
          <Button variant="primary config-btn" onClick={() => this.resetForm()}>
            Reset
          </Button>
          <Button
            variant="primary config-btn"
            onClick={() => this.filterModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}