import React from "react";
import { common } from "_helpers";
// css
import styles from "../css/Marketing.module.css";

class Marketing extends React.Component {
  componentDidMount() {
    if (!common.isAccess("MARKETING_PAGEACCESS")) {
      common.accessDenied();
    }
  }

  render() {
    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-12 poFlex">
              <h1>Marketing</h1>
              <ul>
                <li>
                  <a href="/marketing">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    Marketing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox">
              <div className="poTable">
                <div className="mr-auto p-2">
                  <span className="potableHeadTitle">
                    {" "}
                    Social Media Marketing{" "}
                  </span>
                </div>
                <div className={`${styles.auSocialContent} text-center`}>
                  <img
                    src={common.appIcon("socialMarketing")}
                    className="img-fluid"
                  />
                  <p>Please active your license</p>
                  <div className="row">
                    <div className="col-md-3">
                      <p>
                        <a href="javascript:void(0);">
                          <img
                            src={common.appIcon("fbButton")}
                            className="img-fluid"
                          />
                        </a>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        <a href="javascript:void(0);">
                          <img
                            src={common.appIcon("googleButton")}
                            className="img-fluid"
                          />
                        </a>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        <a href="javascript:void(0);">
                          <img
                            src={common.appIcon("linkedinButton")}
                            className="img-fluid"
                          />
                        </a>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        <a href="javascript:void(0);">
                          <img
                            src={common.appIcon("twitterButton")}
                            className="img-fluid"
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { Marketing };
