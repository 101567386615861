import React from "react";
import { Form, Button } from "react-bootstrap";
// _includes
import { common } from "_helpers";
import { apiServices } from "_api";
import { StatusBar } from "_includes";
// css
import styles from "pages/Administration/css/Admin.module.css";

export default class CustomCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      fields: {
        CustomerCriteriaStatus: false,
        VisitDays: 0,
        AutoBlock: false,
        EmailNotification: false,
        EmailList: []
      }
    };
  }

  componentDidMount() {
    this.getAutoBlock();
  }

  getAutoBlock = () => {
    var data = {
      url: "ADMIN_CUSTOMER_CUSTOM_CRITERIA_GET_AUTO_BLOCK",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({
            status: "success",
            fields: response.data
          })
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    )
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    if (type == "checkbox") {
      fields[name] = checked;
    } else {
      fields[name] = value;
    }
    this.setState({
      fields: fields,
    });
  };

  onSubmit = () => {
    this.setState({ formSubmit: true, isLoading: true });
    const { fields } = this.state;
    var data = {
      url: "ADMIN_CUSTOMER_CUSTOM_CRITERIA_SET_AUTO_BLOCK",
      body: fields,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (this.crudStatus(response.status)) {
          this.crudSuccess();
        } else {
          this.crudError();
        }
      },
      (error) => {
        this.crudError();
      },
      (final) => {
        this.setState({ isLoading: false });
      }
    );
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    common.snack("S", "Custom criteria has been updated");
  }

  crudError() {
    common.snack("S", "There was an error while update record");
  }

  render() {
    const {
      status,
      isLoading,
      fields
    } = this.state;

    return (
      <div className="contentBox">
        <StatusBar status={status} />
        {status == "success" && (
          <div className={styles.acc}>
            <h3>Create customer criteria to autoblock users</h3>
            <div className={styles.accInfo}>
              <Form.Check
                custom
                inline
                label="Enable Custom Criteria"
                type={"checkbox"}
                id={"CustomerCriteriaStatus"}
                name="CustomerCriteriaStatus"
                checked={fields.CustomerCriteriaStatus}
                onChange={(e) => this.handleOnChange(e)}
              />
              <div className={styles.accVisit}>
                <h4>Criteria</h4>
                <div className={styles.accSelect}>
                  <span>User Visiting</span>
                  <Form.Control
                    type="text"
                    name="VisitDays"
                    value={fields.VisitDays}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  <span>days in a week</span>
                </div>
              </div>
              <div className={styles.accAction}>
                <h4>Action</h4>
                <Form.Check
                  custom
                  inline
                  label="Auto Block"
                  type={"checkbox"}
                  id={"AutoBlock"}
                  name="AutoBlock"
                  checked={fields.AutoBlock}
                  onChange={(e) => this.handleOnChange(e)}
                />
                <Form.Check
                  custom
                  inline
                  label="Email Notification"
                  type={"checkbox"}
                  id={"EmailNotification"}
                  name="EmailNotification"
                  checked={fields.EmailNotification}
                  onChange={(e) => this.handleOnChange(e)}
                />
              </div>
            </div>
            <div className={styles.accBottom}>
              <Button
                className="config-btn"
                onClick={() => this.onSubmit()}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Submit"}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}