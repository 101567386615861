import React from "react";
import { Row, Col, Tab, Tabs, Button } from "react-bootstrap";
import moment from "moment";
// _includes
import { common } from "_helpers";
//API Services
import { apiServices } from "_api";
// css
import styles from "../css/Analytics.module.css";

class CustomerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "unload",
      isLoading: [true, true, true],
      macAddress: "",
      dType: 1,
      data: {
        userInfo: [],
        activity: {
          activityDate: [],
          activityList: [],
        },
        notification: {
          notifyDate: [],
          notifyList: [],
        },
      },
    };
  }

  loadData = (macAddress) => {
    this.setState(
      {
        status: "load",
        isLoading: [true, true, true],
        macAddress,
      },
      () => {
        this.userInfo();
        this.activityInfo();
        this.notificationInfo();
      }
    );
  };

  userInfo() {
    const { isLoading, dType, macAddress } = this.state;
    isLoading[0] = false;
    var data = {
      url: "AC_GET_CUSTOMER_DETAIL_BY_DEVICE_MAC_ADDRESS",
      query: macAddress + "/" + dType,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          const { data } = this.state;
          // set data
          data.userInfo = response;
          this.setState({
            data,
            isLoading,
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          data: {
            userInfo: [],
            activity: [],
            notification: [],
          },
          isLoading,
        });
      },
      (final) => {
        this.setState({ isLoading });
      }
    );
  }

  activityInfo() {
    const { isLoading, dType, macAddress } = this.state;
    isLoading[1] = false;
    var data = {
      url: "AC_GET_CUSTOMER_ACTIVITY_BY_DEVICE_MAC_ADDRESS",
      query: macAddress + "/" + dType,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          const { data } = this.state;
          // set data
          response.map((item, key) => {
            let actDate = moment(item.ActivityTimeStamp).format("MM-DD-YYYY");
            if (!data.activity.activityDate.includes(actDate)) {
              data.activity.activityDate.push(actDate);
            }
            let index = data.activity.activityDate.indexOf(actDate);
            if (typeof data.activity.activityList[index] == "undefined") {
              data.activity.activityList[index] = [];
            }
            if (item.ActivityTypeId === 1) {
              item.ClassName = "tlSignUp";
              item.Image = "po_ac_activity_signup";
            } else if (item.ActivityTypeId === 2) {
              item.ClassName = "tlConnect";
              item.Image = "po_ac_activity_wifi";
            } else if (item.ActivityTypeId === 3) {
              item.ClassName = "tlVisit";
              item.Image = "po_ac_activity_visit";
            }
            data.activity.activityList[index].push(item);
          });

          this.setState({
            data,
            isLoading,
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          data: {
            userInfo: [],
            activity: [],
            notification: [],
          },
          isLoading,
        });
      },
      (final) => {
        this.setState({ isLoading });
      }
    );
  }

  notificationInfo() {
    const { isLoading, dType, macAddress } = this.state;
    isLoading[2] = false;
    var data = {
      url: "AC_GET_CUSTOMER_NOTIFICATION_BY_DEVICE_MAC_ADDRESS",
      query: macAddress + "/" + dType,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          const { data } = this.state;
          // set data
          response.map((item, key) => {
            let notDate = moment(item.NotificationTimeStamp).format(
              "MM-DD-YYYY"
            );
            if (!data.notification.notifyDate.includes(notDate)) {
              data.notification.notifyDate.push(notDate);
            }
            let index = data.notification.notifyDate.indexOf(notDate);
            if (typeof data.notification.notifyList[index] == "undefined") {
              data.notification.notifyList[index] = [];
            }
            if (item.NotificationTypeId === 1) {
              item.ClassName = "tlOtp";
              item.Image = "po_ac_activity_bell";
            } else if (item.NotificationTypeId === 2) {
              item.ClassName = "tlEmail";
              item.Image = "po_ac_activity_email";
            }
            data.notification.notifyList[index].push(item);
          });

          this.setState({
            data,
            isLoading,
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          data: {
            userInfo: [],
            activity: [],
            notification: [],
          },
          isLoading,
        });
      },
      (final) => {
        this.setState({ isLoading });
      }
    );
  }

  render() {
    const { isLoading, status, data } = this.state;
    return (
      status === "load" && (
        <Row>
          <Col sm={5}>
            <div className={`${styles.acPageBody} poc-mb-20`}>
              <div className={`${styles.acProfileBox}`}>
                {isLoading[0] ? (
                  <p className={`${styles.acLoading}`}>
                    Loading details please wait...
                  </p>
                ) : (
                  <div>
                    <div className={`${styles.acProInfo} poc-mt-15`}>
                      <div>
                        <h2>{data.userInfo.CustomerName}</h2>
                        <span>
                          Registered On : {data.userInfo.RegistrationTimeStamp}
                        </span>
                      </div>
                      <Button
                        variant="primary config-btn"
                        onClick={() => this.props.isPage()}
                      >
                        &lt; Back
                      </Button>
                    </div>
                    <div className={`${styles.acProDetails} poc-mt-15`}>
                      <Row>
                        <Col sm={4}>
                          <h3>Email</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.Email}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Phone Number</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.MobileNumber}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Age</h3>
                        </Col>
                        <Col sm={6}>
                          <p>
                            {moment().diff(data.userInfo.DateOfBirth, "years")}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Gender</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.Gender}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>OS</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.DeviceOs}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Identified by</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.AuthenticationMode}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Last Seen Date</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.FinalVisitTimeStamp}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <h3>Mac Address</h3>
                        </Col>
                        <Col sm={6}>
                          <p>{data.userInfo.DeviceMacAddress}</p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col sm={7}>
            <div className={`${styles.acPageBody} ${styles.acTabs} poc-mb-20`}>
              <Tabs id="acCustomerDetails">
                <Tab eventKey="Activity" title="Activity">
                  {isLoading[1] ? (
                    <p className={`${styles.acLoading}`}>
                      Loading details please wait...
                    </p>
                  ) : (
                    <ul className="poTimeline">
                      {data.activity.activityDate.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            <li className="tlDate" key={key}>
                              <i>
                                {moment(item).format("MMM")}
                                <br />
                                <span>{moment(item).format("DD")}</span>
                              </i>
                              <div className="poTimelineItem tlOne">
                                <h3>Activities</h3>
                              </div>
                            </li>

                            {data.activity.activityList[key].map(
                              (item, key) => {
                                return (
                                  <li
                                    key={key}
                                    className={`tlActivity ${item.ClassName}`}
                                  >
                                    <i>
                                      <img
                                        src={common.appIcon(item.Image)}
                                        alt="Activity"
                                      />
                                    </i>
                                    <div className="poTimelineItem tlThree">
                                      <h3>{item.ActivityType}</h3>
                                      <h4>{item.ActivityDescription}</h4>
                                      <h5>
                                        {moment(item.ActivityTimeStamp).format(
                                          "hh:mm A"
                                        )}
                                      </h5>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  )}
                </Tab>
                <Tab eventKey="notification" title="Notification">
                  {isLoading[2] ? (
                    <p className={`${styles.acLoading}`}>
                      Loading details please wait...
                    </p>
                  ) : (
                    <ul className="poTimeline">
                      {data.notification.notifyDate.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            <li className="tlDate" key={key}>
                              <i>
                                {moment(item).format("MMM")}
                                <br />
                                <span>{moment(item).format("DD")}</span>
                              </i>
                              <div className="poTimelineItem tlOne">
                                <h3>Notifications</h3>
                              </div>
                            </li>
                            {data.notification.notifyList[key].map(
                              (item, key) => {
                                return (
                                  <li
                                    key={key}
                                    className={`tlActivity ${item.ClassName}`}
                                  >
                                    <i>
                                      <img
                                        src={common.appIcon(item.Image)}
                                        alt="notification"
                                      />
                                    </i>
                                    <div className="poTimelineItem tlThree">
                                      <h3>{item.NotificationType}</h3>
                                      <h4>{item.NotificationDescription}</h4>
                                      <h5>
                                        {moment(
                                          item.NotificationTimeStamp
                                        ).format("hh:mm A")}
                                      </h5>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  )}
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      )
    );
  }
}
export { CustomerDetails };
