import React from "react";
import { Form, Button, Tab } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
import { CountryList, ProvinceList, ConceptList, LocationList, ZoneList, DeviceList } from "../index";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "exist",
      userToken: "",
      activeTab: history.location.pathname.split("/")[3],
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
    history.push(tab);
  };

  // page access
  checkAccess() {
    const { activeTab } = this.state;
    let pageList = {
      country: "COUNTRYCONFIGURATION_PAGEACCESS",
      province: "PROVINCECONFIGURATION_PAGEACCESS",
      concept: "CONCEPTCONFIGURATION_PAGEACCESS",
      location: "LOCATIONCONFIGURATION_PAGEACCESS",
      zone: "ZONECONFIGURATION_PAGEACCESS",
      device: "DEVICECONFIGURATION_PAGEACCESS",
    };
    if (!common.isAccess(pageList[activeTab])) {
      common.accessDenied();
    } else {
      return true;
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="pageContent">
        <Tab.Container activeKey={activeTab}>
          <div className="contentHead">
            <div className="row">
              <div className="col-sm-5 poFlex">
                <h1>Configuration</h1>
                <ul>
                  <li>
                    <a href="/admin/config/country">Home</a>
                  </li>
                  <li>
                    <a href="#" className="noPipe">
                      Configuration
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-7">
                <Form className={`poTabs poFlex poDefault`}>
                  {common.isAccess("COUNTRYCONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "country" ? "inActive" : ""}
                      onClick={() => this.setTab("country")}
                    >
                      Country
                    </Button>
                  )}
                  {common.isAccess("PROVINCECONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "province" ? "inActive" : ""}
                      onClick={() => this.setTab("province")}
                    >
                      Province
                    </Button>
                  )}
                  {common.isAccess("CONCEPTCONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "concept" ? "inActive" : ""}
                      onClick={() => this.setTab("concept")}
                    >
                      Concept
                    </Button>
                  )}
                  {common.isAccess("LOCATIONCONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "location" ? "inActive" : ""}
                      onClick={() => this.setTab("location")}
                    >
                      Location
                    </Button>
                  )}
                  {common.isAccess("ZONECONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "zone" ? "inActive" : ""}
                      onClick={() => this.setTab("zone")}
                    >
                      Zone
                    </Button>
                  )}
                  {common.isAccess("DEVICECONFIGURATION_PAGEACCESS") && (
                    <Button
                      variant="primary"
                      className={activeTab != "device" ? "inActive" : ""}
                      onClick={() => this.setTab("device")}
                    >
                      Device
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
          <div className="contentBody">
            <div className="content">
              <Tab.Content>
                {common.isAccess("COUNTRYCONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="country" title="Country">
                    <CountryList />
                  </Tab.Pane>
                )}
                {common.isAccess("PROVINCECONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="province" title="Province">
                    <ProvinceList />
                  </Tab.Pane>
                )}
                {common.isAccess("CONCEPTCONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="concept" title="Concept">
                    <ConceptList />
                  </Tab.Pane>
                )}
                {common.isAccess("LOCATIONCONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="location" title="Location">
                    <LocationList />
                  </Tab.Pane>
                )}
                {common.isAccess("ZONECONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="zone" title="Zone">
                    <ZoneList />
                  </Tab.Pane>
                )}
                {common.isAccess("DEVICECONFIGURATION_PAGEACCESS") && (
                  <Tab.Pane eventKey="device" title="device">
                    <DeviceList />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
export { Configuration };
