import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
// _import
import { common, history, tools } from "_helpers";
import { StatusBar } from "_includes";
import { apiServices } from "_api";

class AdminAccountCrud extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      title: "User",
      parent: "/admin/user/account",
      status: "process",
      isLoading: false,
      fields: {
        UserGroupId: "",
        AuthenticationTypeId: "",
        UserFullName: "",
        Designation: "",
        Description: "",
        EmployeeId: "",
        MobileNumber: "",
        Email: "",
        LoginPassword: ""
      },
      errors: {
        UserGroupId: {
          error: "",
          isReq: "Please select User Group",
        },
        AuthenticationTypeId: {
          error: "",
          isReq: "Please select Authentication type",
        },
        UserFullName: {
          error: "",
          isReq: "Please enter full name",
        },
        Email: {
          error: "",
          isReq: "Please enter the email",
          isValidEmail: "Please enter the valid email address",
        },
        LoginPassword: {
          error: "",
          isReq: "Please enter the password",
        },
      },
      sData: {
        userGroupList: [],
        authenticationTypeList: []
      }
    };
    // api process
    this.apiCount = 0;
    this.apiTotal = 2;
  }

  componentDidMount() {
    this.crudAccess();
    this.initCheck();
  }

  initCheck() {
    const { params, parent } = this.state;
    if (params.type == "edit") {
      if (!params.id) {
        history.push(parent);
      } else {
        this.loadData();
      }
    } else {
      this.getUserGroupList();
      this.getAuthenticationTypeList();
    }
  }

  loadData = () => {
    const { params } = this.state;
    var data = {
      url: "ADMIN_USER_ACCOUNT_BY_ID",
      query: "/" + params.id,
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status == 200) {
          let result = response.data;
          this.setState(
            {
              fields: {
                UserGroupId: result.UserGroupId,
                AuthenticationTypeId: result.AuthenticationTypeId,
                UserFullName: result.UserFullName,
                Designation: result.Designation,
                Description: result.Description,
                EmployeeId: result.EmployeeId,
                MobileNumber: result.MobileNumber,
                Email: result.Email,
                LoginPassword: result.LoginPassword
              },
            },
            () => {
              this.getUserGroupList();
              this.getAuthenticationTypeList();
            }
          );
        } else {
          this.setState({
            status: "error"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error"
        })
      },
      (final) => { }
    );
  };

  getUserGroupList = () => {
    const { sData } = this.state;
    var data = {
      url: "ADMIN_USER_GROUP_GET_ALL",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          sData.userGroupList = response;
          this.setState({ sData });
        }
      },
      (error) => { },
      (final) => {
        this.setApiStatus();
      }
    );
  };

  getAuthenticationTypeList = () => {
    const { sData } = this.state;
    var data = {
      url: "ADMIN_USER_ACCOUNT_AUTH_TYPE_LIST",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          sData.authenticationTypeList = response;
          this.setState({
            sData
          });
        }
      },
      (error) => { },
      (final) => {
        this.setApiStatus();
      }
    );
  };

  setApiStatus() {
    this.apiCount += 1;
    if (this.apiCount == this.apiTotal) {
      this.setState({
        status: "success"
      })
    }
  }

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isValidEmail) {
        if (!tools.ValidateEmail(fields[key])) {
          errors[key].error = errors[key].isValidEmail;
          isValid = false;
        }
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (typeof fields[key] == "object") {
        if (fields[key].length == 0) {
          errors[key].error = errors[key].isReq;
          isValid = false;
        }
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].isValidEmail) {
        if (!tools.ValidateEmail(fields[key])) {
          errors[key].error = errors[key].isValidEmail;
          isValid = false;
        }
      }
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  // handler
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // CRUD Operations
  onSubmit = () => {
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      this.setState({ isLoading: true });
      const { params, fields } = this.state;
      var data = {};
      if (params.type == "add") {
        data = {
          url: "ADMIN_USER_ACCOUNT_PERSIST",
          query: "?IsAdd=true",
          body: fields,
          method: "POST",
        };
      } else {
        fields.UserInfoId = params.id;
        data = {
          url: "ADMIN_USER_ACCOUNT_PERSIST",
          query: "?IsAdd=false",
          body: fields,
          method: "POST",
        };
      }

      apiServices.call(
        data,
        (response) => {
          if (this.crudStatus(response.status)) {
            this.crudSuccess();
          } else {
            this.crudError();
          }
        },
        (error) => {
          this.crudError();
        },
        (final) => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  crudStatus(status) {
    if (status === 200 || status === 201 || status === 202) {
      return true;
    } else {
      return false;
    }
  }

  crudSuccess() {
    const { params, title, parent } = this.state;
    if (params.type == "add") {
      common.snack("S", "New " + title + " has been created");
    } else {
      common.snack("S", title + " has been updated successfully");
    }
    setTimeout(() => {
      history.push(parent);
    }, 500);
  }

  crudError() {
    const { params, title } = this.state;
    if (params.type == "add") {
      common.snack("S", "There was an error while adding a " + title);
    } else {
      common.snack("S", "There was an error while updating a " + title);
    }
  }

  redirect() {
    const { parent } = this.state;
    history.push(parent);
  }

  // module & page access
  crudAccess() {
    const { params } = this.state;
    let allow = false;
    (params.type == "add" && common.isAccess("USERACCOUNT_ADD")) && (allow = true);
    (params.type == "edit" && common.isAccess("USERACCOUNT_EDIT")) && (allow = true);
    if (!allow) {
      common.accessDenied();
    }
  }

  render() {
    const {
      params,
      status,
      isLoading,
      fields,
      errors,
      formSubmit,
      sData
    } = this.state;

    return (
      <div className="pageContent">
        <div className="contentHead">
          <div className="row">
            <div className="col-sm-6 poFlex">
              <h1>User Account</h1>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" className="noPipe">
                    {params.type == "add" ? "Add User" : "Update User"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contentBody">
          <div className="content">
            <div className="contentBox p-0">
              <div className="form_header">
                <h2 className="poc-fs-18">
                  {params.type == "add" ? "Add User" : "Update User"}
                </h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
                <Form onSubmit={this.handleSubmit}>
                  <div className="pad-20 bottom-border poTabs">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        User Group
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          name="UserGroupId"
                          value={fields.UserGroupId}
                          onChange={(e) => this.handleOnChange(e)}
                        >
                          <option value="">Select Group</option>
                          {sData.userGroupList.map((item, key) => {
                            return (
                              <option key={key} value={item.UserGroupId}>
                                {item.UserGroupName}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {errors.UserGroupId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.UserGroupId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Authentication Type
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="select"
                          name="AuthenticationTypeId"
                          value={fields.AuthenticationTypeId}
                          onChange={(e) => this.handleOnChange(e)}
                        >
                          <option value="">Select Auth Type</option>
                          {sData.authenticationTypeList.map((item, key) => {
                            return (
                              <option key={key} value={item.Id}>
                                {item.Value}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {errors.AuthenticationTypeId.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.AuthenticationTypeId.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Employee Id
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="EmployeeId"
                          placeholder="Employee Id"
                          value={fields.EmployeeId}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        User Full name
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="UserFullName"
                          placeholder="User Full Name"
                          value={fields.UserFullName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.UserFullName.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.UserFullName.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Designation
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="Designation"
                          placeholder="Designation"
                          value={fields.Designation}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="Description"
                          placeholder="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Mobile Number
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="MobileNumber"
                          placeholder="Mobile Number"
                          value={fields.MobileNumber}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Email
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="Email"
                          placeholder="Email"
                          value={fields.Email}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.Email.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.Email.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Login Password
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="LoginPassword"
                          placeholder="Login Password"
                          value={fields.LoginPassword}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.LoginPassword.error !== "" && formSubmit && (
                          <span className="error">
                            {errors.LoginPassword.error}
                          </span>
                        )}
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                  <div className="pad-20">
                    <Form.Group as={Row} className="btn-row" controlId="code">
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          variant="primary config-btn"
                          type="button"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                        >
                          {isLoading ? "Processing..." : params.type == "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                          onClick={() => this.redirect()}
                          variant="primary config-cancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm="5"></Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { AdminAccountCrud };
