import React from "react";
import { Row, Col, Button, Tab, Tabs, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
// _includes
import Filter from "../includes/Filter";
// API Services
import { apiServices } from "_api";
// css
import styles from "../css/Analytics.module.css";

class ZoneAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      data: {
        result: {
          zoneCount: 0,
          zoneTotal: 0,
          zoneData: [],
        },
      },
      foodColor: ["#6b2dec", "#8943f2", "#ae6bff", "#d2acff"],
      // csv
      isExport: false,
      csv: {
        set: false,
        headers: [
          { label: "Zone Name", key: "ZoneName" },
          { label: "Traffic Percentage", key: "TrafficPercentage" },
          { label: "Location Name", key: "LocationName" },
        ],
        data: [],
      },
    };
  }
  
  analysisData = () => {
    const { foodColor } = this.state;
    const { filter, filterDate } = this.filter.state;
    var data = {
      url: "AZ_GET_ANALYTICS_DATA",
      query: filterDate.Date,
      body: {
        DateFrom: filter.customDate.DateFrom,
        DateTo: filter.customDate.DateTo,
        CountryId: filter.CountryId,
        ConceptId: filter.ConceptId,
        LocationId: filter.LocationId,
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          // set traffic
          let result = { zoneCount: 0, zoneTotal: 0, zoneData: [] };
          response.map((item, key) => {
            result.zoneCount += 1;
            result.zoneTotal += item.TrafficPercentage;
          });
          response.map((item, key) => {
            result.zoneData.push({
              info: item,
              percent: (
                (item.TrafficPercentage / result.zoneTotal) *
                100
              ).toFixed(2),
              colorCode: foodColor[key],
            });
          });
          // set data
          this.setState({
            status: "success",
            data: {
              result: result,
            },
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
          data: {
            result: [],
          },
        });
      },
      (final) => {}
    );
  };

  // filter
  filterModal(status) {
    this.filter.filterModal(status);
  }

  filterSubmit() {
    this.setState(
      {
        status: "process",
      },
      () => {
        this.analysisData();
      }
    );
  }

  filterReset() {
    this.setState(
      {
        status: "process",
      },
      () => {
        this.analysisData();
      }
    );
  }

  // csv
  // Export
  exportCSV() {
    this.setState({ isExport: true });
    const { csv } = this.state;
    const { filter, filterDate } = this.filter.state;
    var data = {
      url: "AZ_GET_ANALYTICS_DATA",
      query: filterDate.Date,
    };
    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          response = response.data;
          if (response.length > 0) {
            let csvData = response.map((data) => {
              return {
                ZoneName: data.ZoneName,
                TrafficPercentage: data.TrafficPercentage,
                LocationName: data.LocationName,
              };
            });
            csv.set = true;
            csv.data = csvData;
            this.setState(
              {
                csv,
              },
              () => {
                setTimeout(() => {
                  let az_analysis_csv = this.refs.az_analysis_csv;
                  az_analysis_csv.link.click();
                }, 100);
              }
            );
          } else {
            alert("No record found!");
          }
        }
      },
      (error) => {},
      (final) => {
        this.setState({ isExport: false });
      }
    );
  }

  render() {
    const { status, isExport, data, csv } = this.state;
    return (
      <div className={`${styles.azAnalysis}`}>
        {status == "process" ? (
          <p className={`${styles.azLoading}`}>Loading data please wait...</p>
        ) : (
          <div>
            <div className="poTable">
              <div className="d-flex">
                <div className="mr-auto poc-p-15">
                  <div className="poTableIcon">
                    <span className="poMidTitle"> Analysis </span>
                    <Button
                      variant="primary config-btn poc-ml-10"
                      onClick={() => this.exportCSV()}
                      disabled={isExport}
                    >
                      {isExport ? "Exporting..." : "Export CSV"}
                    </Button>
                    {csv.set && (
                      <CSVLink
                        ref="az_analysis_csv"
                        data={csv.data}
                        headers={csv.headers}
                        filename={"analysis.csv"}
                        className="csvData"
                      />
                    )}
                  </div>
                </div>
                <div className="p-2">
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
            {/* analysis body */}
            <div className={`${styles.azAnaBody}`}>
              <div className={`${styles.azAnaDist}`}>
                <p>Distribution of foot traffic by Zone</p>
                <Row>
                  {data.result.zoneData.map((item, key) => {
                    return (
                      <Col className="poc-mp-none" key={key}>
                        <div
                          className={`${styles.azAnaHw}`}
                          style={{ backgroundColor: item.colorCode }}
                        >
                          <h1>{item.info.ZoneName}</h1>
                          <h2>{item.info.TrafficPercentage}%</h2>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className={`${styles.azAnaTable}`}>
                <Table responsive hover responsive>
                  <thead>
                    <tr>
                      <th>Zone</th>
                      <th>Zone Traffic(%)</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.result.zoneData.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.info.ZoneName}</td>
                          <td>{item.info.TrafficPercentage}%</td>
                          <td>{item.info.LocationName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
        <Filter
          type="zoneAnalysis"
          filterInit={() => {
            this.analysisData();
          }}
          filterSubmit={() => this.filterSubmit()}
          filterReset={() => this.filterReset()}
          ref={(instance) => {
            this.filter = instance;
          }}
        />
      </div>
    );
  }
}
export default ZoneAnalysis;
