import React from "react";
import { Nav } from "react-bootstrap";
// _includes
import { common, history } from "_helpers";
//Import CSS
import styles from "./Menu.module.css";
//Import Icons
import ToggleIcon from "asset/img/toggle.png";

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      pathname: history.location.pathname.split("/")[1],
    };
  }

  togFunction = () => {
    document.getElementById("leftMenu").classList.toggle(styles.leftHalfMenu);
    document.getElementById("adminLayout").classList.toggle(styles.layoutRight);
  };

  render() {
    const { pathname } = this.state;
    return (
      <div className={styles.leftFullMenu} id="leftMenu">
        <div className={styles.menuIcon}>
          <img
            src={ToggleIcon}
            alt="Toggle Icon"
            className={styles.menuIconImg}
            onClick={() => this.togFunction()}
          />
        </div>
        <ul className={styles.navMenu}>
          <li>
            <a
              href="/dashboard"
              className={`${styles.navLink} ${
                pathname == "dashboard" ? styles.activeMenu : ""
              }`}
            >
              <div className={styles.dashboardIcon}></div>
              <p>Dashboard</p>
            </a>
          </li>
          {common.isAccess("logs","m") && (
            <li>
              <a
                href={"/logs/"+common.isAccess("logs_logs","ms") }
                className={`${styles.navLink} ${
                  pathname == "logs" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.logsIcon}></div>
                <p>Logs</p>
              </a>
            </li>
          )}
          {common.isAccess("report","m") && (
            <li className={styles.hasSubMenu}>
              <a
                href="#"
                className={`${styles.navLink} ${
                  pathname == "report" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.reportIcon}></div>
                <p>Reports</p>
              </a>
              <ul className={styles.subMenuList}>
                {common.isAccess("CUSTOMERREGISTRATIONREPORT_PAGEACCESS","s") && (
                  <li>
                    <a href="/report/register">Customer Registration</a>
                  </li>
                )}
                {common.isAccess("CUSTOMERVISITLOGREPORT_PAGEACCESS","s") && (
                  <li>
                    <a href="/report/visit">Customer Visit Log</a>
                  </li>
                )}
                {common.isAccess("report_insight","ms") && (
                  <li>
                    <a href={"/report/insight/"+common.isAccess("report_insight","ms")}>Customer Insight</a>
                  </li>
                )}
                {common.isAccess("report_location","ms") && (
                  <li>
                    <a href={"/report/location/"+common.isAccess("report_location","ms")}>Location Report</a>
                  </li>
                )}
                {common.isAccess("REPORTSCHEDULING_PAGEACCESS","s") && (
                  <li>
                    <a href="/report/schedule">Report Scheduling</a>
                  </li>
                )}
              </ul>
            </li>
          )}
          {common.isAccess("analytics","m") && (
            <li>
              <a
                href={"/analytics/"+common.isAccess("analytics_analytics","ms")}
                className={`${styles.navLink} ${
                  pathname == "analytics" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.analyticsIcon}></div>
                <p>Analytics</p>
              </a>
            </li>
          )}
          {common.isAccess("actionAlert","m") && (
            <li>
              <a
                href="/actionalert"
                className={`${styles.navLink} ${
                  pathname == "actionalert" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.actionAlertIcon}></div>
                <p>Action & Alerts</p>
              </a>
            </li>
          )}
          {common.isAccess("marketing","m") && (
            <li className={styles.hasSubMenu}>
              <a
                href="#"
                className={`${styles.navLink} ${
                  pathname == "marketing" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.marketingIcon}></div>
                <p>Marketing</p>
              </a>
              <ul className={styles.subMenuList}>
                {common.isAccess("MARKETING_PAGEACCESS","s") && (
                  <li>
                    <a href="/marketing">Marketing</a>
                  </li>
                )}
                {common.isAccess("SURVEYMARKETING_PAGEACCESS","s") && (
                  <li>
                    <a href="/marketing/survey">Survey</a>
                  </li>
                )}
                {common.isAccess("CAMPAIGNMANAGEMENT_PAGEACCESS","s") && (
                  <li>
                    <a href="/marketing/campaign">Campaign Management</a>
                  </li>
                )}
              </ul>
            </li>
          )}
          {common.isAccess("administration","m") && (
            <li className={styles.hasSubMenu}>
              <a
                href="#"
                className={`${styles.navLink} ${
                  pathname == "admin" ? styles.activeMenu : ""
                }`}
              >
                <div className={styles.admininstrationIcon}></div>
                <p>Administration</p>
              </a>
              <ul className={styles.subMenuList}>
                {common.isAccess("administration_config","ms") && (
                  <li>
                    <a href={"/admin/config/"+common.isAccess("administration_config","ms")}>Configuration</a>
                  </li>
                )}
                {common.isAccess("administration_customer","ms") && (
                  <li>
                    <a href={"/admin/customer/"+common.isAccess("administration_customer","ms")}>Manage Account</a>
                  </li>
                )}
                {common.isAccess("LICENSEMANAGEMENT_PAGEACCESS") && (
                  <li>
                    <a href="/admin/license">License Management</a>
                  </li>
                )}
                {common.isAccess("administration_user","ms") && (
                  <li>
                    <a href={"/admin/user/"+common.isAccess("administration_user","ms")}>User Account Management</a>
                  </li>
                )}
                {common.isAccess("administration_visualize","ms") && (
                  <li>
                    <a href={"/admin/visualize/"+common.isAccess("administration_visualize","ms")}>Visualize</a>
                  </li>
                )}
              </ul>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
export { Menu };
