import React from "react";
import {
  Form,
  Col,
  InputGroup,
  Button,
  Spinner,
  Row,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { common } from "_helpers";
//Import CSS
import styles from "./Login.module.css";

//Import Images
import FormLogo from "asset/img/loginPage/loginlogo.png";
import EmailIcon from "asset/img/loginPage/emailicon.png";
import PasswordLogo from "asset/img/loginPage/password.png";
import LoginImage from "asset/img/loginPage/login_img.jpg";

//API SERVICES
import { apiServices } from "_api";
import { history } from "_helpers";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      formSubmit: false,
      checked: localStorage.getItem("rememberMe") === "true",
      username: localStorage.getItem("lgUserName") || "",
      password: localStorage.getItem("lgPassWord") || "",
      errors: {
        username: "",
        password: "",
      },
      loginError: "",
      isLoading: false,
      userInfo: [],
    };
  }

  //On Handle Change
  handleChange = (event) => {
    const { name, value } = event.target;
    const { errors, formSubmit } = this.state;
    errors.username = "";
    errors.password = "";

    this.setState({
      [name]: value,
    });
    if (value === "" && formSubmit === true) {
      errors[name] = "Please Enter " + name;
      this.setState({
        errors,
      });
    }
  };

  //Form Submission with Validation
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loginError: "",
      formSubmit: true,
    });
    if (
      this.state.username !== "" &&
      this.state.password !== "" &&
      this.state.errors.username === "" &&
      this.state.errors.password === ""
    ) {
      this.submitForm(event);
    } else {
      let errors = this.state.errors;
      if (this.state.username === "") {
        errors.username = "Please enter username";
      }
      if (this.state.password === "") {
        errors.password = "Please enter password";
      }
      this.setState({ errors: errors });
    }
  };

  submitForm = (event) => {
    event.preventDefault();
    const { username, password, checked } = this.state;
    this.setState({
      loginError: "",
      isLoading: true,
    });

    var data = {
      url: "ADMIN_LOGIN",
      authorization: "none",
      body: {
        username: username,
        password: password,
        grant_type: "password",
      },
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          this.setState(
            {
              userInfo: response.data,
            },
            () => {
              this.getModulePermission();
            }
          );
        } else {
          if (response.status == 401) {
            common.snack("E", response.data.title);
            this.setState({
              username: "",
              password: "",
              loginError: response.data.title,
            });
          }
        }
      },
      (error) => {
        common.showMessage("unknown");
        this.setState({
          username: "",
          password: "",
          loginError: "",
        });
      },
      (final) => {}
    );
  };

  getModulePermission() {
    const { userInfo, password, checked } = this.state;
    var data = {
      url: "COMMON_USER_PROFILE_GET_USER_GROUP_MODULE_PERMISSIONS",
      authorization: "instant_bearer",
      bearerToken: userInfo.access_token,
    };

    apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          localStorage.setItem("userName", userInfo.userName);
          localStorage.setItem("userToken", userInfo.access_token);
          localStorage.setItem("refreshToken", userInfo.refresh_token);
          localStorage.setItem("tokenExpires", userInfo.expires_in);
          localStorage.setItem("tokenStamp", moment());
          localStorage.setItem("userPermission", this.getPermissionData(response.data));
          if (checked) {
            localStorage.setItem("rememberMe", true);
            localStorage.setItem("lgUserName", userInfo.userName);
            localStorage.setItem("lgPassWord", password);
          } else {
            localStorage.setItem("rememberMe", false);
            localStorage.setItem("lgUserName", "");
            localStorage.setItem("lgPassWord", "");
          }
          setTimeout(() => {
            common.snack("S", "Login successfull!");
            history.push("/dashboard");
          }, 500);
        } else {
          common.showMessage("unknown");
        }
      },
      (error) => {
        common.showMessage("unknown");
      },
      (final) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  getPermissionData(data){
    let permissonList = []
    data.map((item,key)=>{
      permissonList.push(item.ModuleCode+"_"+item.PermissionCode)
    })
    return JSON.stringify(permissonList);
  }

  rememberCheck() {
    const { checked } = this.state;
    let isChecked = true;
    if (checked === true) {
      isChecked = false;
    }
    this.setState({
      checked: isChecked,
    });
  }

  render() {
    const {
      username,
      password,
      errors,
      formSubmit,
      loginError,
      isLoading,
      checked,
    } = this.state;

    return (
      <div className={styles.loginFormSection}>
        <Row className={styles.formImag}>
          <Col sm={8}>
            <img
              src={LoginImage}
              alt="PortalOne Logo"
              style={{
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                width: "100%",
                height: "100%",
              }}
              className="img-fluid"
            />
          </Col>
          <Col sm={4} className={styles.LFormSection}>
            <img
              src={FormLogo}
              alt="PortalOne Logo"
              className={styles.loginLogo}
            />
            <div className={styles.formText}>
              <h3 className={styles.formTexth3}>Sign In</h3>
              <p className={styles.formTextp}>
                Access the PortalOne panel using your user email and password.{" "}
              </p>
            </div>

            <Form className={styles.loginForm} onSubmit={this.handleSubmit}>
              <Form.Group as={Col}>
                {loginError != "" && (
                  <Alert
                    key={1}
                    variant={"danger"}
                    style={{ textAlign: "center" }}
                  >
                    {loginError}
                  </Alert>
                )}
                <InputGroup>
                  <InputGroup.Prepend>
                    <img
                      src={EmailIcon}
                      alt="Email Icon"
                      className={styles.emailIcon}
                    />
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Email address"
                    aria-describedby="inputGroupPrepend"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                  />
                </InputGroup>
                {errors.username !== "" && formSubmit === true && (
                  <span className={styles.loginError}>{errors.username}</span>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <img
                      src={PasswordLogo}
                      alt="Password Icon"
                      className={styles.passwordIcon}
                    />
                  </InputGroup.Prepend>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    aria-describedby="inputGroupPrepend"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                </InputGroup>
                {errors.password !== "" && formSubmit === true && (
                  <span className={styles.loginError}>{errors.password}</span>
                )}
              </Form.Group>

              <Link to="/">Forgot Password?</Link>

              <Form.Group as={Col}>
                <Button
                  variant="primary"
                  className={styles.loginBtn}
                  type="submit"
                  disabled={isLoading == true}
                >
                  {isLoading ? (
                    <div>
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                      />
                      Processing...
                    </div>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form.Group>

              <Form.Group as={Col} className={styles.rememberMe}>
                <Form.Check
                  type="checkbox"
                  label="Remember Me"
                  defaultChecked={checked === true}
                  onChange={() => this.rememberCheck()}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>

      // <div className={styles.loginFormSection}>
      //   <img src={FormLogo} alt="PortalOne Logo" className={styles.loginLogo} />
      //   <Form className={styles.loginForm} onSubmit={this.handleSubmit}>
      //     <Form.Group as={Col}>
      //       {loginError!='' && (
      //         <Alert key={1} variant={"danger"} style={{textAlign:'center'}}>{loginError}</Alert>
      //       )}
      //       <InputGroup>
      //         <InputGroup.Prepend>
      //           <img src={EmailIcon} alt="Email Icon" className={styles.emailIcon}/>
      //         </InputGroup.Prepend>
      //         <Form.Control
      //           type="text"
      //           placeholder="Email address"
      //           aria-describedby="inputGroupPrepend"
      //           name="username"
      //           value={username}
      //           onChange={this.handleChange}
      //         />
      //       </InputGroup>
      //       {errors.username !== "" && formSubmit === true && (
      //         <span className={styles.loginError}>{errors.username}</span>
      //       )}
      //     </Form.Group>

      //     <Form.Group as={Col}>
      //       <InputGroup>
      //         <InputGroup.Prepend>
      //           <img
      //             src={PasswordLogo}
      //             alt="Password Icon"
      //             className={styles.passwordIcon}
      //           />
      //         </InputGroup.Prepend>
      //         <Form.Control
      //           type="password"
      //           placeholder="Password"
      //           aria-describedby="inputGroupPrepend"
      //           name="password"
      //           value={password}
      //           onChange={this.handleChange}
      //         />
      //       </InputGroup>
      //       {errors.password !== "" && formSubmit === true && (
      //         <span className={styles.loginError}>{errors.password}</span>
      //       )}
      //     </Form.Group>

      //     <Form.Group as={Col} className={styles.rememberMe}>
      //       <Form.Check type="checkbox" label="Remember Me" defaultChecked={(checked===true)} onChange={() => this.rememberCheck() } />
      //     </Form.Group>

      //     <Form.Group as={Col}>
      //       <Button variant="primary" className={styles.loginBtn} type="submit" disabled={(isLoading==true)}>
      //       {isLoading ? (
      //         <div><Spinner as="span" animation="grow" role="status" aria-hidden="true"/>Processing...</div>
      //       ): (
      //         'Login'
      //       )}
      //       </Button>
      //     </Form.Group>

      //     <Link to="/">Forgot Password?</Link>
      //   </Form>
      // </div>
    );
  }
}
export { Login };
