import React from 'react';
// css
import styles from './Footer.module.css';

class Footer extends React.Component {
    render() {
        return(
            <div className={styles.footer}>
                <p>Copyright <span>PortalOne</span> © 2019-2020</p>
            </div>
        )
    }
}
export { Footer };